import {
  ADD_NARRATION,
  ADD_NARRATION_ERROR,
  EDIT_NARRATION,
  EDIT_NARRATION_ERROR,
  GET_ALL_NARRATIONS,
  GET_ALL_NARRATIONS_ERROR,
  GET_A_NARRATION,
  GET_A_NARRATION_ERROR,
  CLEAR_NARRATION_RESPONSE,
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_NARRATIONS:
      return { ...state, narrations: action.payload, narrationsError: false };
    case GET_ALL_NARRATIONS_ERROR:
      return {
        ...state,
        narrationsError: action.payload.data,
        narrations: false,
      };
    case ADD_NARRATION:
      return { ...state, add_narration_response: action.payload };
    case ADD_NARRATION_ERROR:
      return { ...state, add_narration_error: action.payload };
    case EDIT_NARRATION:
      return {
        ...state,
        narrations: {
          ...state.narrations,
          content: state.narrations.content.map((item) => {
            if (
              item.narrations[0].key ===
              action.payload.oldNarration.narrations[0].key
            ) {
              return action.payload.updatedNarration;
            } else {
              return item;
            }
          }),
        },
      };

    case EDIT_NARRATION_ERROR:
      return { ...state, error: action.payload };
    case GET_A_NARRATION:
      return { ...state, narration: action.payload };
    case GET_A_NARRATION_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_NARRATION_RESPONSE:
      return { ...state, response: "", error: "" };
    default:
      return state;
  }
}
