import axios from "axios";
import { BASE_URL } from "../../actions/types";
import { interceptor } from "../../actions/interceptor";
interceptor();

const getTokenValidity = async (token) => {
	// Used the Promised based pattern of axios
	return (
		axios
			.get(`${BASE_URL}/admin/app-settings`, {
				headers : {
					Authorization : `${token}`,
				},
			})
			.then((res) => res)
			// this check was added to make sure the user is not logged once an error occurs, be it un-authorized or any other error
			.catch((error) => (error.response ? error.response.status : 401))
	);
};

export default getTokenValidity;
