export const getUserPermission = () => {
	const decodedUserToken = localStorage.getItem("decodedToken");
	return decodedUserToken ? JSON.parse(decodedUserToken).permissions : [];
};

export const usePermissions = (permissionToViewPage, userPermissions) => {
	if (permissionToViewPage === null) return true; // allows pages like dashboard which are not authroized to be viewed.
	// handle single permission
	if (permissionToViewPage && typeof permissionToViewPage === "string")
		return userPermissions.includes(permissionToViewPage);
	// handle of multiple permissions
	if (permissionToViewPage && permissionToViewPage.length > 1)
		return userPermissions.some((element) => permissionToViewPage && permissionToViewPage.indexOf(element) > -1);
};

export const generateTableHeadersByPermissions = (
	viewPermission,
	userPermission,
	baseTableHeader,
	extraTableHeaderIfAuthorized,
) => {
	if (usePermissions(viewPermission, userPermission)) return baseTableHeader.concat(extraTableHeaderIfAuthorized);
	return baseTableHeader;
};
