// Styles
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./GenericPostingRecord.css";

import React, { useState, useEffect } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Loader from "react-loader-spinner";
import moment from "moment";
import Pagination2 from "react-js-pagination";
import Select, { components } from "react-select";
import { AppSwitch } from "@coreui/react";

// Redux
import { connect } from "react-redux";
import {
  fetchInterPostingLog,
  // downloadInterPostingLog,
  downloadQueryDetail,
  fetchPostingEntryMetaFn,
  approvePostingFn,
} from "../../../actions/filesets";
import { closeAlert } from "../../../actions/utils";

// Components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table as Table2,
  Label,
} from "reactstrap";
import PostingTableHeader from "../../../components/PostingTableHeader";
import PostingTable from "../../../components/PostingTable";
import ErrorMessage from "../../../components/ErrorMessage";

// util function
import {
  getUserPermission,
  usePermissions,
} from "../../../Utils/Auth/usePermissions";
import { makeTagList } from "../../../Utils/utilityFunctions";
import { makeReactSelectOptions } from "../../../Utils/utilityFunctions";

// query string
const queryString = require("query-string");

const GenericPostingRecords = ({
  viewPermission,
  // function props
  fetchPostingEntryMetaFn,
  approvePostingFn,
  fetchInterPostingLog,
  //downloadInterPostingLog,
  downloadQueryDetail,
  closeAlert,
  // query string props
  location: { search: queryParams, state: prevState }, // gets the query string from props.search and save it as queryParams
  // state variables props
  alertError,
  genericPostingRecords,
  genericPostingRecordsError,
  postingEntryMeta,
  // postingEntryMetaError,
}) => {
  // QUERY STRING VARIABLES
  const LogID = queryString.parse(queryParams)["posting-log-id"];
  let { page, scheme } = queryString.parse(queryParams);

  // constants
  const { settlementDate } = useParams();
  let dateOnDownloadedFileName = moment(settlementDate).format("DDMMYY");
  const tokenInlocalStrage = localStorage.getItem("token");
  const auto_posting = JSON.parse(localStorage.getItem("manualPostingStatus"));
  const auto_posting_error = JSON.parse(
    localStorage.getItem("manualPostingStatusError")
  );

  // initialize
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState([]);
  const [manualPostingStatus, setManualPostingStatus] = useState(
    auto_posting && auto_posting.enableManualPosting
  );
  const [activePage, setActivePage] = useState(parseInt(page));
  useEffect(() => {
    const pageQuery = page - 1;
    fetchInterPostingLog(LogID, pageQuery);
    fetchPostingEntryMetaFn(LogID);
  }, [queryParams]);

  const onDismiss = () => closeAlert();

  // applies the custom red styles to the tag selector
  const IndicatorsContainer = (props) => (
    <div className={"bg-danger"}>
      <components.IndicatorsContainer {...props} />
    </div>
  );

  const handleChangeManualPostingStatus = (status, logID) => {
    approvePostingFn(!status, logID);
    setManualPostingStatus(!status);
  };

  const handlePageChange = (pageNumber) => {
    history.push(
      `/admin/${settlementDate}/query-posting-records?posting-log-id=${LogID}&page=${pageNumber}`
    );
    setActivePage(pageNumber);
  };

  const handleGoBack = (prevLocation) => {
    if (prevLocation) {
      history.push(`${prevLocation}`);
    } else {
      history.push(`${localStorage.getItem("goBackUrl")}`);
    }
    return;
  };

  const handleSubmitDate = (tagsArr) => {
    const tagListStr = makeTagList(tagsArr).join(",");
    history.push(
      `/admin/${settlementDate}/query-posting-records?posting-log-id=${LogID}&page=${activePage}&tags=${tagListStr}`
    );
  };

  const GenericPostingRecordTable = [
    "Branch",
    "Home Branch",
    "Constant",
    "Constant1",
    "Fcy Amount",
    "GL Account No",
    "Dr Cr",
    "GL Account Branch",
    "Fcy Currency",
    "Lcy Amount",
    "Tx Code",
    "Date",
    "Rate",
    "Constant2",
    "Narration",
    "Summary Order",
  ];

  const metaAffiliateCode =
    postingEntryMeta &&
    postingEntryMeta.data &&
    postingEntryMeta.data.meta &&
    postingEntryMeta.data.meta.Affiliate;
  const Tags =
    postingEntryMeta && postingEntryMeta.data && postingEntryMeta.data.Tags;
  const AffCode =
    postingEntryMeta &&
    postingEntryMeta.data &&
    postingEntryMeta.data.meta &&
    postingEntryMeta.data.meta.AFFILIATE;

  const getAffiliate = (val1, val2) => {
    if (val1) {
      return val1;
    }
    return val2;
  };
  if (!tokenInlocalStrage) return <Redirect to={"/"} />; // Authentication check

  const userPermission = getUserPermission();
  if (!usePermissions(viewPermission, userPermission))
    return <Redirect to={"/401"} />; // Authorization check

  return (
    <div className="animated fadeIn px-5">
      {alertError && (
        <Alert color="danger" toggle={onDismiss}>
          {alertError
            ? alertError
            : "Error unable to download. Please Check you entries!"}
        </Alert>
      )}
      <Row>
        <Col xs={12} className={"my-4"}>
          <Card className={"mx-2"}>
            <CardHeader
              className={
                "d-flex w-100 justify-content-between align-items-center"
              }
            >
              <Button
                color={"primary"}
                size={"sm"}
                className={"px-4"}
                onClick={() => handleGoBack(prevState && prevState.from)}
              >
                <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                <FormattedMessage id=" Go Back" defaultMessage=" Go Back" />
              </Button>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={"4"}>
                  <article
                    className={
                      "d-flex justify-content-center align-items-center flex-column py-2"
                    }
                  >
                    <div className={"d-flex flex-column text-center"}>
                      <span
                        style={{ height: "100px", width: "100px" }}
                        className={
                          getAffiliate(metaAffiliateCode, AffCode) &&
                          "flag-icon flag-icon-" +
                            getAffiliate(
                              metaAffiliateCode,
                              AffCode
                            ).toLowerCase()
                        }
                        title="al"
                        id="al"
                      ></span>
                      <small>
                        <strong>
                          Affiliate {getAffiliate(metaAffiliateCode, AffCode)}
                        </strong>
                      </small>
                    </div>
                  </article>
                </Col>
                <Col xs={"4"}>
                  <article
                    className={
                      "w-100 d-flex justify-content-center flex-column py-4"
                    }
                  >
                    <div
                      className={
                        "d-flex align-items-center justify-content-between mb-3"
                      }
                    >
                      <strong className="w-50">Ref </strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                          postingEntryMeta.data &&
                          postingEntryMeta.data.id}
                      </p>
                    </div>
                    <div
                      className={
                        "d-flex align-items-center justify-content-between mb-3"
                      }
                    >
                      <strong className="w-50">Settlement Date</strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                          postingEntryMeta.data &&
                          postingEntryMeta.data.settlementDate}
                      </p>
                    </div>
                    <div
                      className={
                        "d-flex align-items-center justify-content-between mb-3"
                      }
                    >
                      <strong className="w-50">Processing Date</strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                          postingEntryMeta.data &&
                          postingEntryMeta.data.processDate}
                      </p>
                    </div>
                    <div
                      className={
                        "d-flex align-items-center justify-content-between mb-3"
                      }
                    >
                      <strong className="w-50">Scheme Type</strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                          postingEntryMeta.data &&
                          postingEntryMeta.data.schemeType}
                      </p>
                    </div>
                    <div
                      className={
                        "d-flex align-items-center justify-content-between mb-3"
                      }
                    >
                      <strong className="w-50">Batch No</strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                        postingEntryMeta.data &&
                        postingEntryMeta.data.batchNo
                          ? postingEntryMeta.data.batchNo
                          : "N/A"}
                      </p>
                    </div>
                  </article>
                </Col>
                <Col xs={"4"}>
                  <article
                    className={
                      "w-100 d-flex justify-content-center align-items-center flex-column py-4"
                    }
                  >
                    <div className={"w-50 d-flex justify-content-between mb-3"}>
                      <strong className="w-50">Approved</strong>
                      <p>
                        <AppSwitch
                          className={"mx-1"}
                          variant={""}
                          color={"success"}
                          size={"lg"}
                          defaultChecked={
                            postingEntryMeta &&
                            postingEntryMeta.data &&
                            postingEntryMeta.data.approved
                          }
                          checked={
                            postingEntryMeta &&
                            postingEntryMeta.data &&
                            postingEntryMeta.data.approved
                          }
                          label
                          dataOn={"on"}
                          dataOff={"off"}
                          onChange={() =>
                            handleChangeManualPostingStatus(
                              manualPostingStatus,
                              LogID
                            )
                          }
                        />
                      </p>
                    </div>
                    <div className={"w-50 d-flex justify-content-between mb-3"}>
                      <strong className="w-50">Submitted</strong>
                      <p className="w-50 mb-0">
                        {postingEntryMeta &&
                        postingEntryMeta.data &&
                        postingEntryMeta.data.submitted ? (
                          <i
                            className="fa fa-check text-success"
                            aria-hidden="true"
                          />
                        ) : (
                          <i
                            className="fa fa-times text-danger"
                            aria-hidden="true"
                          />
                        )}
                      </p>
                    </div>
                    <div className={"w-50 d-flex justify-content-between mb-3"}>
                      <strong className="w-50">Affiliate Code</strong>
                      <p className="w-50 mb-0">
                        {getAffiliate(metaAffiliateCode, AffCode)}
                      </p>
                    </div>
                  </article>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card className={"mx-2"}>
            <CardHeader
              className={
                "d-flex w-100 justify-content-between align-items-center"
              }
            >
              <section className={"d-flex"}>
                <strong className={"mr-1"}>
                  <FormattedMessage
                    id=" Admin Posting Log"
                    defaultMessage=" Admin Posting Log"
                  />
                </strong>
              </section>
              <div
                className={"d-flex justify-content-end align-items-center w-75"}
              >
                <div style={{ minWidth: "50%" }}>
                  <Select
                    className={"mx-2"}
                    onChange={(selectedOption) =>
                      setSelectedTags(selectedOption)
                    }
                    components={{ IndicatorsContainer }}
                    value={selectedTags}
                    isMulti
                    options={makeReactSelectOptions(Tags && Tags)}
                  />
                </div>
                <Button
                  className={"btn-sm mx-1 px-4 py-2"}
                  onClick={() => handleSubmitDate(selectedTags)}
                  color="primary"
                >
                  {" "}
                  <FormattedMessage id="Submit" defaultMessage="Submit" />
                </Button>
                <Button
                  className={"btn-sm mx-1 px-4 py-2"}
                  onClick={() =>
                    downloadQueryDetail(
                      LogID,
                      scheme,
                      dateOnDownloadedFileName,
                      getAffiliate(metaAffiliateCode, AffCode)
                    )
                  }
                  color="success"
                >
                  <FormattedMessage id="Download" defaultMessage="Download" />
                </Button>
              </div>
            </CardHeader>

            <CardBody>
              {/* Handle Loading */}
              {!genericPostingRecordsError && !genericPostingRecords && (
                <Loader
                  type="ThreeDots"
                  color="#337AB7"
                  height={100}
                  width={100}
                  style={{ textAlign: "center" }}
                />
              )}

              {/* Handle Errors */}
              {!genericPostingRecords &&
                genericPostingRecordsError &&
                genericPostingRecordsError.data === "" && (
                  <ErrorMessage message={"Error unable to display entries!"} />
                )}

              {/* Handle 404 */}
              {!genericPostingRecordsError &&
                genericPostingRecords &&
                genericPostingRecords.data &&
                genericPostingRecords.data.content.length === 0 && (
                  <ErrorMessage
                    message={"There are no posting log entries for this item  "}
                  />
                )}

              {/* Handle display content */}
              {genericPostingRecords &&
                genericPostingRecords.data &&
                genericPostingRecords.data.content.length > 0 && (
                  <Table2
                    hover
                    bordered
                    striped
                    responsive
                    size="sm"
                    style={{ minWidth: "180%" }}
                  >
                    <thead>
                      <PostingTableHeader
                        headerType={"array"}
                        headerPayload={GenericPostingRecordTable}
                      />
                    </thead>
                    <tbody>
                      <PostingTable
                        tableName={"generic-posting-record"}
                        dataRecords={
                          genericPostingRecords && genericPostingRecords.data
                            ? genericPostingRecords.data.content
                            : []
                        }
                      />
                    </tbody>
                  </Table2>
                )}

              {/* Handle Pagination */}
              {genericPostingRecords &&
                genericPostingRecords.data &&
                genericPostingRecords.data &&
                genericPostingRecords.data.content.length > 0 && (
                  <nav>
                    <Pagination2
                      activePage={activePage}
                      itemsCountPerPage={genericPostingRecords.data.size}
                      totalItemsCount={
                        (genericPostingRecords &&
                          genericPostingRecords.data.totalElements) ||
                        null
                      }
                      pageRangeDisplayed={20}
                      onChange={handlePageChange}
                    />
                  </nav>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    alertError: state.utils.alertError,
    genericPostingRecords: state.fileSets.genericPostingRecords,
    genericPostingRecordsError: state.fileSets.genericPostingRecordsError,
    postingEntryMeta: state.fileSets.postingEntryMeta,
    postingEntryMetaError: state.fileSets.postingEntryMetaError,
  };
};

export default connect(mapStateToProps, {
  fetchInterPostingLog,
  fetchPostingEntryMetaFn,
  downloadQueryDetail,
  // downloadInterPostingLog,
  approvePostingFn,
  closeAlert,
})(GenericPostingRecords);
