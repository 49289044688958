// React
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Row,
  Alert,
} from "reactstrap";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";

// Redux
import { getLoggedInUser } from "../../../actions/utils";
import { connect } from "react-redux";
import { setLocale, updateLocale } from "../../../actions/locale";
import { getUserMgtUrl, getUserMgtUrlBackEnd } from "../../../actions/utils";

import activedge_logo from "../../../assets/img/brand/settlement_logo.png";

const Login = ({
  ext_user_mgt_url,
  user_mgt_url,
  user_mgt_url_Error,
  invalidToken,
}) => {
  const [language, setLanguage] = useState("en");
  const [loginErrorAlert, setLoginErrorAlert] = useState("true");
  const baseName = window.location.href.split("#");
  // console.log("baseName", baseName[0]);

  const handleRegister = () => {
    window.location.href =
      `${localStorage.getItem(
        "USER_MGT_URL"
      )}/applogin?lang=${language}&module=SETTLEMENT&redirectUrl=` +
      `${baseName[0]}#/dashboard`;
  };

  const d = () => window.location.reload();

  const handleSelectLanguage = (event) => {
    event.preventDefault();
    localStorage.setItem("currentUserLang", event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        data-test="loginComponent"
      >
        <a className="navbar-brand" href="#/">
          <img
            src={activedge_logo}
            alt=""
            className="img-reponsive"
            style={{ width: "90px" }}
          />
        </a>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto" />
          <span className="form-inline my-2 my-lg-0">
            <select
              type="select"
              className="form-control"
              name="select"
              id="language"
              value={language}
              onChange={handleSelectLanguage}
            >
              <option value="en">English</option>
              <option value="fr">Français</option>
              <option value="pt">Português</option>
              <option value="es">Español</option>
            </select>
          </span>
        </div>
      </nav>
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {localStorage.getItem("error401Se8mentMessage") && (
                <Alert color="danger" isOpen={loginErrorAlert} toggle={false}>
                  Error: {localStorage.getItem("error401Se8mentMessage")}
                </Alert>
              )}
              {user_mgt_url_Error && (
                <div
                  className={"d-flex justify-content-center pb-4 text-danger"}
                >
                  <strong>
                    <FormattedMessage
                      id="Failed to Load Settings Try Again!"
                      defaultMessage="Failed to Load Settings Try Again!"
                    />
                  </strong>
                </div>
              )}

              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    {!user_mgt_url_Error &&
                      !user_mgt_url &&
                      !ext_user_mgt_url && (
                        <article
                          className={
                            "d-flex flex-column justify-content-center align-items-center h-100"
                          }
                        >
                          <Loader
                            type="ThreeDots"
                            color="#20A8D8"
                            height={50}
                            width={50}
                            style={{ textAlign: "center" }}
                          />

                          <span style={{ color: "#20A8D8" }}>
                            Loading Settings...
                          </span>
                        </article>
                      )}

                    {user_mgt_url_Error &&
                      !user_mgt_url &&
                      !ext_user_mgt_url && (
                        <article
                          className={
                            "d-flex flex-column justify-content-center align-items-center h-100"
                          }
                        >
                          <span className={"text-danger"}>
                            Error Falied To Loading Settings...
                          </span>
                          <Button
                            color={"danger"}
                            className="mt-3 px-4 py-1"
                            active
                            onClick={d}
                          >
                            <FormattedMessage
                              id="Refresh"
                              defaultMessage="Refresh"
                            />
                          </Button>
                        </article>
                      )}

                    {!user_mgt_url_Error &&
                      user_mgt_url &&
                      ext_user_mgt_url && (
                        <Form>
                          <h1 className="text-center">
                            <FormattedMessage
                              id="Login"
                              defaultMessage="Login"
                            />
                          </h1>
                          {invalidToken && (
                            <p className="text-danger text-center">
                              <FormattedMessage
                                id={invalidToken}
                                defaultMessage={invalidToken}
                              />
                            </p>
                          )}
                          <p className="text-muted text-center">
                            <FormattedMessage
                              id="Sign In to your account"
                              defaultMessage="Sign In to your account"
                            />
                          </p>

                          <Button
                            style={{
                              display: "block",
                            }}
                            color="primary"
                            className="mt-auto mb-auto px-4 py-1 mr-auto ml-auto"
                            active
                            onClick={handleRegister}
                          >
                            <FormattedMessage
                              id="Login"
                              defaultMessage="Login"
                            />
                          </Button>
                        </Form>
                      )}
                  </CardBody>
                </Card>
                <Card
                  className="py-5 d-md-down-none"
                  color="primary"
                  style={{ width: 44 + "%" }}
                >
                  <CardBody className="text-center">
                    <i
                      style={{ fontSize: "100px", color: "#fff" }}
                      className="fa fa-files-o"
                    />
                    <h2>Settlement App</h2>
                    <p className="text-center">
                      <FormattedMessage
                        id="The Settlement App processes a number of account files for reconcilliation purposes."
                        defaultMessage="The Settlement App   processes a number of account files for reconcilliation purposes."
                      />
                    </p>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    invalidToken: state.utils.invalidToken,
    lang: state.locale.lang,
    ext_user_mgt_url: state.utils.ext_user_mgt_url,
    user_mgt_url: state.utils.user_mgt_url,
    user_mgt_url_Error: state.utils.user_mgt_url_Error,
  };
};

export default connect(mapStateToProps, {
  setLocale,
  updateLocale,
  getLoggedInUser,
  getUserMgtUrl,
  getUserMgtUrlBackEnd,
})(Login);
