import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { confirmDelete } from "../actions/filesets";

const BtnDropdown = ({ settlementDate, settlementPostingLogId, scheme }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  let location = useLocation();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      size={"sm"}
      color="success"
      className={"ml-1"}
    >
      <DropdownToggle caret>Settlement Posting Entries</DropdownToggle>
      <DropdownMenu>
        <Link
          style={{ textDecoration: "none" }}
          className={"text-white"}
          to={{
            pathname: `/admin/${settlementDate}/query-posting-records`,
            search: `?posting-log-id=${
              settlementPostingLogId && settlementPostingLogId
            }&page=1&scheme=${scheme}`,
            state: { from: `${location.pathname}${location.search}` },
          }}
          onClick={() =>
            localStorage.setItem(
              "goBackUrl",
              `${location.pathname}${location.search}`
            )
          }
        >
          <DropdownItem>View Entries</DropdownItem>
        </Link>
        {/* <DropdownItem divider /> */}
        {/* <DropdownItem onClick={()=> confirmDelete({key: "logId", value: settlementPostingLogId})}>Delete</DropdownItem> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default connect(null, { confirmDelete })(BtnDropdown);
