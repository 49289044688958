import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorMessage = ({ message }) => {
	return (
		<article className='d-flex justify-content-center align-items-center'>
			<p className='text-center text-danger'>
				<FormattedMessage id={message} defaultMessage={message} />
			</p>
		</article>
	);
};

export default ErrorMessage;
