import { 
    FETCH_APACHE_CAMEL_DIRECTORIES,
    FETCH_APACHE_CAMEL_DIRECTORIES_ERROR,
    DELETE_APACHE_CAMEL_DIRECTORY,
    DELETE_APACHE_CAMEL_DIRECTORY_ERROR,
    CLEAR_CAMEL_DIRECTORY_RESPONSE
} from '../actions/types';

export default function(state={apacheDirectoryListing:[],deleteResponse:null},action){
    switch(action.type){
        case FETCH_APACHE_CAMEL_DIRECTORIES:
            return {...state, apacheDirectoryListing:action.payload}
        case FETCH_APACHE_CAMEL_DIRECTORIES_ERROR:
            return {...state, fetchError:action.payload}
        case DELETE_APACHE_CAMEL_DIRECTORY:
            return {...state, deleteResponse:action.payload}
        case DELETE_APACHE_CAMEL_DIRECTORY_ERROR:
            return {...state, deleteError:action.payload}
        case  CLEAR_CAMEL_DIRECTORY_RESPONSE:
            return {...state, fetchError:'',deleteResponse:'',deleteError:''}   
        default:
            return state;        

    }
}