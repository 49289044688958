// React
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import * as router from "react-router-dom";

// Redux
import { connect } from "react-redux";
import LoadingBar from "react-redux-loading-bar";

// Container
import { Container } from "reactstrap";

import {
	AppAside,
	AppBreadcrumb,
	AppFooter,
	AppHeader,
	AppSidebar,
	AppSidebarFooter,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
	AppSidebarNav,
} from "@coreui/react";

// sidebar nav config
import { SettlementSidebar } from "../../navigation/_adminNav";

// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";

// utility Fn
import { itExists } from "../../Utils/utilityFunctions";
import { getUserPermission, usePermissions } from "../../Utils/Auth/usePermissions";
import { generateSideBarByUserPermission } from "../../Utils/Auth/generateSideBarByUserPermission";
import ErrorBoundary from "../../components/ErrorBoundary";

// generate settlement sidebar based on the user permissions
const userPermissions = getUserPermission();
const dynamicSettlementSidebar = generateSideBarByUserPermission(SettlementSidebar.items, userPermissions);
const userToken = localStorage.getItem("token");

const DefaultLayout = ({ ...props }) => {
	const history = useHistory();
	const [signoutTime, setSignoutTime] = useState(300000);
	const [warningTime, setWarningTime] = useState(150000);
	let warnTimeout;
	let logoutTimeout;

	const warn = () => {
		console.log("Warning");
	};
	const logout = () => {
		localStorage.clear();
		console.log("You have been loged out");
		history.push("/login");
		window.location.reload();
	};

	const destroy = () => {
		console.log("Session destroyed");
	};

	const setTimeouts = () => {
		warnTimeout = setTimeout(warn, warningTime);
		logoutTimeout = setTimeout(logout, signoutTime);
	};

	const clearTimeouts = () => {
		if (warnTimeout) clearTimeout(warnTimeout);
		if (logoutTimeout) clearTimeout(logoutTimeout);
	};

	useEffect(() => {
		const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

		const resetTimeout = () => {
			clearTimeouts();
			setTimeouts();
		};

		for (let i in events) {
			window.addEventListener(events[i], resetTimeout);
		}

		setTimeouts();
		return () => {
			for (let i in events) {
				window.removeEventListener(events[i], resetTimeout);
				clearTimeouts();
			}
		};
	}, []);

	if (!itExists(userToken)) return <Redirect to={"/login"} />; // Authentication is handled here.

	return (
		<div className='app'>
			<AppHeader fixed>
				<DefaultHeader />
			</AppHeader>
			<div className='app-body'>
				<AppSidebar fixed display='lg'>
					<AppSidebarHeader />
					<AppSidebarForm />
					<AppSidebarNav navConfig={dynamicSettlementSidebar} {...props} router={router} />
					<AppSidebarFooter />
					<AppSidebarMinimizer />
				</AppSidebar>
				<main className='main'>
					<LoadingBar style={{ height: "15px", marginTop: "35px" }} maxProgress={95} progressIncrease={5} />
					<AppBreadcrumb appRoutes={routes} router={router} />
					<Container fluid>
						<Switch>
							{routes.map((route, idx) => {
								return route.component ? (
									<Route
										key={idx}
										path={route.path}
										exact={route.exact}
										name={route.name}
										render={(props) => (
											<ErrorBoundary {...props}>
												<route.component viewPermission={route.viewPermission} {...props} />
											</ErrorBoundary>
										)}
									/>
								) : null;
							})}
							<Redirect from='/' to='/dashboard' />
						</Switch>
					</Container>
				</main>
				<AppAside fixed hidden>
					<DefaultAside />
				</AppAside>
			</div>
			<AppFooter>
				<DefaultFooter />
			</AppFooter>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		token_details: state.utils,
	};
};

export default connect(mapStateToProps, {})(DefaultLayout);
