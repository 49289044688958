import * as TYPES from '../actions/types';

export default function(state={},action){
    switch(action.type){
        case TYPES.GET_ALL_POS_DETAILS:
            return {...state,posDetailsList:action.payload}
        case TYPES.GET_POS_DETAILS_BY_MERCHANT_ID:
            return {...state,posDetailsByMerchantId:action.payload}
        case TYPES.GET_POS_DETAILS_BY_MERCHANT_ID_ERROR:
            return {...state,posDetailsByMerchantIdError:action.payload}
        default:
            return state;
    }
}