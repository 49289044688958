import React from "react";

// redux
import { connect } from "react-redux";
import { handleException } from "../actions/utils";

// css
import "./PostingTable.css";

const PostingTable = ({ handleException, dataRecords, tableName }) => {

  if (tableName === "e-details") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.name}</td>
          <td>{tableData.serNo}</td>
          <td>{tableData.psName}</td>
          <td>{tableData.i018MerchType}</td>
          <td>{tableData.i031Arn}</td>
          <td>{tableData.i037RetRefNum}</td>
          <td>{tableData.i038AuthId}</td>
          <td>{tableData.i039RespCd}</td>
          <td>{tableData.i041PosId}</td>
          <td>{tableData.i043aMerchName}</td>
          <td>{tableData.i043bMerchCity}</td>
          <td>{tableData.i043cMerchCnt}</td>
          <td>{tableData.i000MsgType}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.issuingAffiliate}</td>
          <td>{tableData.procCode}</td>
          <td>{tableData.i004AmtTrxn}</td>
          <td>{tableData.i005AmtSettle}</td>
          <td>{tableData.i006AmtBill}</td>
          <td>{tableData.i007LoadDate}</td>
          <td>{tableData.i013TrxnDate}</td>
          <td>{tableData.i042MerchId}</td>
          <td>{tableData.i048TextData}</td>
          <td>{tableData.i049CurTrxn}</td>
          <td>{tableData.i050CurSettle}</td>
          <td>{tableData.i051CurBill}</td>
          <td>{tableData.centerAmount}</td>
          <td>{tableData.instalmentType}</td>
          <td>{tableData.amount}</td>
          <td>{tableData.valueUsd}</td>
          <td>{tableData.tipAmount}</td>
          <td>{tableData.billTipAmount}</td>
          <td>{tableData.comAmount}</td>
          <td>{tableData.origComAmount}</td>
          <td>{tableData.postDate}</td>
          <td>{tableData.postTimeStamp}</td>
          <td>{tableData.stGeneral}</td>
          <td>{tableData.issuingCode}</td>
          <td>{tableData.acquiringCode}</td>
          <td>{tableData.cashAmount}</td>
          <td>{tableData.fee}</td>
          <td>{tableData.netAmount}</td>
          <td>{tableData.flexCubeAmount}</td>
          <td>{tableData.flexCubeString}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.issuer}</td>
          <td>{tableData.acquirer}</td>
          <td>{tableData.stan}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.withdrawalChannel}</td>
          <td>{tableData.cashOutDate}</td>
          <td>{tableData.requestId}</td>
          <td>{tableData.bin}</td>
        </tr>
      );
    });
  }

  if (tableName === "ecommerce-details") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.summaryId}</td>
          <td>{tableData.name}</td>
          <td>{tableData.serNo}</td>
          <td>{tableData.psName}</td>
          <td>{tableData.i018MerchType}</td>
          <td>{tableData.i031Arn}</td>
          <td>{tableData.i037RetRefNum}</td>
          <td>{tableData.i038AuthId}</td>
          <td>{tableData.i039RespCd}</td>
          <td>{tableData.i041PosId}</td>
          <td>{tableData.i043aMerchName}</td>
          <td>{tableData.i043bMerchCity}</td>
          <td>{tableData.i043cMerchCnt}</td>
          <td>{tableData.i000MsgType}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.issuingAffiliate}</td>
          <td>{tableData.procCode}</td>
          <td>{tableData.i004AmtTrxn}</td>
          <td>{tableData.i005AmtSettle}</td>
          <td>{tableData.i006AmtBill}</td>
          <td>{tableData.i007LoadDate}</td>
          <td>{tableData.i013TrxnDate}</td>
          <td>{tableData.i042MerchId}</td>
          <td>{tableData.i048TextData}</td>
          <td>{tableData.i049CurTrxn}</td>
          <td>{tableData.i050CurSettle}</td>
          <td>{tableData.i051CurBill}</td>
          <td>{tableData.centerAmount}</td>
          <td>{tableData.instalmentType}</td>
          <td>{tableData.amount}</td>
          <td>{tableData.valueUsd}</td>
          <td>{tableData.tipAmount}</td>
          <td>{tableData.billTipAmount}</td>
          <td>{tableData.comAmount}</td>
          <td>{tableData.origComAmount}</td>
          <td>{tableData.postDate}</td>
          <td>{tableData.postTimeStamp}</td>
          <td>{tableData.stGeneral}</td>
          <td>{tableData.issuingCode}</td>
          <td>{tableData.acquiringCode}</td>
          <td>{tableData.cashAmount}</td>
          <td>{tableData.fee}</td>
          <td>{tableData.netAmount}</td>
          <td>{tableData.flexCubeAmount}</td>
          <td>{tableData.flexCubeString}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.withdrawalChannel}</td>
          <td>{tableData.cashOutDate}</td>
          <td>{tableData.requestId}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.issuer}</td>
          <td>{tableData.acquirer}</td>
          <td>{tableData.bin}</td>
          <td>{tableData.stan}</td>
        </tr>
      );
    });
  }

  if (tableName === "interaff-query-details") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.acquiringAffiliate}</td>
          <td>{tableData.authIdRsp}</td>
          <td>{tableData.bin}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.cashAmount}</td>
          <td>{tableData.dateTimeTranGmt}</td>
          <td>{tableData.dateTimeTranLocal}</td>
          <td>{tableData.flexCubeAmount}</td>
          <td>{tableData.flexCubeString}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.ifee}</td>
          <td>{tableData.interAffType}</td>
          <td>{tableData.issuingAffiliate}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.netAmount}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.rspCodeRsp}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.summaryId}</td>
          <td>{tableData.switchKey}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.tranNr}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.transactionAmount}</td>
        </tr>
      );
    });
  }

  if (tableName === "generic-posting-record") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.branch}</td>
          <td>{tableData.homeBranch}</td>
          <td>{tableData.constant}</td>
          <td>{tableData.constant1}</td>
          <td>{tableData.fcyAmount}</td>
          <td>{tableData.glAccountNo}</td>
          <td>{tableData.drCr}</td>
          <td>{tableData.glAccountBranch}</td>
          <td>{tableData.fcyCurrency}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.txCode}</td>
          <td>{tableData.date}</td>
          <td>{tableData.rate}</td>
          <td>{tableData.constant2}</td>
          <td>{tableData.narration}</td>
          <td>{tableData.summaryOrder}</td>
        </tr>
      );
    });
  }

  if (tableName === "interAff-Posting-detail-summary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.affiliate}</td>
          <td>{tableData.acquiringAmount}</td>
          <td>{tableData.acquiringFee}</td>
          <td>{tableData.issuingAmount}</td>
          <td>{tableData.issuingFee}</td>
          <td>{tableData.net}</td>
        </tr>
      );
    });
  }
  if (tableName === "audit-report") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.termId}</td>
          <td>{tableData.dateTime}</td>
          <td>{tableData.trn}</td>
          <td>{tableData.cat}</td>
          <td>{tableData.audNo}</td>
          <td>{tableData.cardNumber}</td>
          <td>{tableData.err}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.tranAmt}</td>
          <td>{tableData.cashAmt}</td>
          <td>{tableData.netAmt}</td>
          <td>{tableData.accountNumber}</td>
          <td>{tableData.rate}</td>
          <td>{tableData.ifee}</td>
        </tr>
      );
    });
  }
  if (tableName === "sum-settlement-fees") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.left}</td>
          <td>{tableData.middle}</td>
          <td>{tableData.right}</td>
        </tr>
      );
    });
  }

  if (tableName === "affiliate-posting-summary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.left}</td>
          <td>{tableData.right === "D" ? tableData.middle : null}</td>
          <td>{tableData.right === "D" ? null : tableData.middle}</td>
        </tr>
      );
    });
  }

  if (tableName === "acquirer-source-item") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.summaryId}</td>
          <td>{tableData.tranNr}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.authIdRsp}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.rspCodeRsp}</td>
          <td>{tableData.dateTimeTranLocal}</td>
          <td>{tableData.dateTimeTranGmt}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.transactionAmount}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.switchKey}</td>
          <td>{tableData.interAffType}</td>
          <td>{tableData.cashAmount}</td>
          <td>{tableData.netAmount}</td>
          <td>{tableData.issuingAffiliate}</td>
          <td>{tableData.acquiringAffiliate}</td>
          <td>{tableData.flexCubeAmount}</td>
          <td>{tableData.flexCubeString}</td>
          <td>{tableData.ifee}</td>
        </tr>
      );
    });
  }

  if (tableName === "issuer-source-item") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.summaryId}</td>
          <td>{tableData.tranNr}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.authIdRsp}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.rspCodeRsp}</td>
          <td>{tableData.dateTimeTranLocal}</td>
          <td>{tableData.dateTimeTranGmt}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.transactionAmount}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.switchKey}</td>
          <td>{tableData.interAffType}</td>
          <td>{tableData.cashAmount}</td>
          <td>{tableData.netAmount}</td>
          <td>{tableData.issuingAffiliate}</td>
          <td>{tableData.acquiringAffiliate}</td>
          <td>{tableData.flexCubeAmount}</td>
          <td>{tableData.flexCubeString}</td>
          <td>{tableData.ifee}</td>
        </tr>
      );
    });
  }

  if (tableName === "settlement-report") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.branch}</td>
          <td>{tableData.homeBranch}</td>
          <td>{tableData.constant}</td>
          <td>{tableData.constant1}</td>
          <td>{tableData.fcyAmount}</td>
          <td>{tableData.glAccountNo}</td>
          <td>{tableData.drCr}</td>
          <td>{tableData.glAccountBranch}</td>
          <td>{tableData.fcyCurrency}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.txCode}</td>
          <td>{tableData.date}</td>
          <td>{tableData.rate}</td>
          <td>{tableData.constant2}</td>
          <td>{tableData.narration}</td>
        </tr>
      );
    });
  }
  if (tableName === "mastercard-query-details") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.branch}</td>
          <td>{tableData.homeBranch}</td>
          <td>{tableData.constant}</td>
          <td>{tableData.constant1}</td>
          <td>{tableData.fcyAmount}</td>
          <td>{tableData.glAccountNo}</td>
          <td>{tableData.drCr}</td>
          <td>{tableData.glAccountBranch}</td>
          <td>{tableData.fcyCurrency}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.txCode}</td>
          <td>{tableData.date.toString()}</td>
          <td>{tableData.rate}</td>
          <td>{tableData.constant2}</td>
          <td>{tableData.narration}</td>
          <td>{tableData.fcyEntry.amount}</td>
          <td>{tableData.fcyEntry.sign}</td>
          <td>{tableData.lcyEntry.amount}</td>
          <td>{tableData.lcyEntry.amount}</td>
        </tr>
      );
    });
  }

  if (tableName === "settlement-breakdown") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.affiliate}</td>
          <td>{tableData.acquiringAmount}</td>
          <td>{tableData.acquiringFee}</td>
          <td>{tableData.issuingAmount}</td>
          <td>{tableData.issuingFee}</td>
          <td>{tableData.net}</td>
        </tr>
      );
    });
  }

  if (tableName === "register-settlement") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.key ? tableData.key : "N/A"}</td>
          <td>{tableData.value ? tableData.value : "N/A"}</td>
        </tr>
      );
    });
  }

  if (tableName === "interAffilate-item") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.acquirer}</td>
          <td>{tableData.issuer}</td>
          <td>{tableData.tranNr}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.authIdRsp}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.rspCodeRsp}</td>
          <td>{tableData.dateTimeTranLocal}</td>
          <td>{tableData.dateTimeTranGmt}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.transactionAmount}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.switchKey}</td>
        </tr>
      );
    });
  }

  if (tableName === "interaffiliatePostOfficeSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.tran_nr}</td>
          <td>{tableData.terminal}</td>
          <td>{tableData.date}</td>
          <td>{tableData.time}</td>
          <td>{tableData.location}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.retrievalReferenceNr}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.authIdRsp}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.tranAmountRsp}</td>
          <td>{tableData.tranCashRsp}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settleAmountReq}</td>
          <td>{tableData.settleAmountRsp}</td>
          <td>{tableData.settleCashRsp}</td>
          <td>{tableData.settleAmountImpact}</td>
          <td>{tableData.switchKey}</td>
        </tr>
      );
    });
  }

  if (tableName === "advisementSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.account}</td>
          <td>{tableData.unit}</td>
          <td>{tableData.crDr}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.narration}</td>
          <td>{tableData.txnDate}</td>
          <td>{tableData.txnCode}</td>
          <td>{tableData.branch}</td>
          <td>{tableData.ccy}</td>
          <td>{tableData.mid}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.calcLcyAmount}</td>
          <td>{tableData.homeBranch}</td>
          <td>{tableData.constant1}</td>
          <td>{tableData.description}</td>
          <td>{tableData.accountBranch}</td>
          <td>{tableData.mis}</td>
          <td>{tableData.branchCode}</td>
          <td>{tableData.transactionCode}</td>
          <td>{tableData.chkAmount}</td>
          <td>{tableData.xrate}</td>
        </tr>
      );
    });
  }

  if (tableName === "postingReportSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.account}</td>
          <td>{tableData.description}</td>
          <td>{tableData.credit}</td>
          <td>{tableData.debit}</td>
        </tr>
      );
    });
  }

  if (tableName === "visa-acquirer-transactions") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>
            {tableData.exception && (
              <span
                className={"d-flex justify-content-center align-items-center"}
              >
                <span>
                  <i
                    className="text-warning px-1 fa fa-exclamation-triangle"
                    aria-hidden="true"
                  />
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i
                    onClick={() => handleException(tableData)}
                    className="text-primary px-1 fa fa-pencil-square-o"
                    aria-hidden="true"
                  />
                </span>
              </span>
            )}
            {!tableData.exception && (
              <span
                className={"d-flex justify-content-center align-items-center"}
              >
                <i className="text-success fa fa-check" aria-hidden="true" />
              </span>
            )}
          </td>
          <td>{tableData.sre}</td>
          <td>{tableData.batNumber}</td>
          <td>{tableData.xMITLocalDate}</td>
          <td>{tableData.time}</td>
          <td>{tableData.cardNumber}</td>
          <td>{tableData.retrievalRefNumber}</td>
          <td>{tableData.traceNumber}</td>
          <td>{tableData.issuerId}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.procssCode}</td>
          <td>{tableData.entMod}</td>
          <td>{tableData.reasCode}</td>
          <td>{tableData.cnStp}</td>
          <td>{tableData.rspCd}</td>
          <td>{tableData.transactionAmount}</td>
          <td>{tableData.transactionCurrency}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.settlementAmountSign}</td>
          <td>{tableData.settlementCurrency}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.cardAcceptorId}</td>
          <td>{tableData.cardAcceptorName}</td>
          <td>{tableData.feeLevel}</td>
          <td>{tableData.feeLevelSign}</td>
          <td>{tableData.feeLevelDesc}</td>
          <td>{tableData.feeLevelExt}</td>
          <td>{tableData.maskedPan}</td>
          <td>{tableData.txnDateLocal}</td>
          <td>{tableData.sreId}</td>
          <td>{tableData.affiliateCode}</td>
          <td>{tableData.crDr}</td>
          <td>{tableData.postingSettlementAmount}</td>
          <td>{tableData.narration}</td>
          <td>{tableData.txnDate}</td>
          <td>{tableData.txnCode}</td>
          <td>{tableData.branch}</td>
          <td>{tableData.ccy}</td>
          <td>{tableData.mid}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.subgroup}</td>
          <td>{tableData.product}</td>
          <td>{tableData.postilionRrn}</td>
          <td>{tableData.postilionRrnResolver}</td>
          <td>{tableData.postilionRrnResolvedDate}</td>
          <td>{tableData.batchDate}</td>
          <td>{tableData.xrate}</td>
        </tr>
      );
    });
  }

  if (tableName === "visa-issuer-transactions") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>
            {tableData.exception && (
              <span
                className={"d-flex justify-content-center align-items-center"}
              >
                <span>
                  <i
                    className="text-warning px-1 fa fa-exclamation-triangle"
                    aria-hidden="true"
                  />
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i
                    onClick={() => handleException(tableData)}
                    className="text-primary px-1 fa fa-pencil-square-o"
                    aria-hidden="true"
                  />
                </span>
              </span>
            )}
            {!tableData.exception && (
              <span
                className={"d-flex justify-content-center align-items-center"}
              >
                <i className="text-success fa fa-check" aria-hidden="true" />
              </span>
            )}
          </td>
          <td>{tableData.bin}</td>
          <td>{tableData.reconciliationCode}</td>
          <td>{tableData.reconciliationPurpose}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.acquirerReferenceNumber}</td>
          <td>{tableData.transactionDate}</td>
          <td>{tableData.settlementAmount}</td>
          <td>{tableData.settlementCurrencyCode}</td>
          <td>{tableData.transactionAmount}</td>
          <td>{tableData.transactionCurrencyCode}</td>
          <td>{tableData.cardAcceptorName}</td>
          <td>{tableData.cardAcceptorLocation}</td>
          <td>{tableData.acquirerCountryCode}</td>
          <td>{tableData.authId}</td>
          <td>{tableData.binId}</td>
          <td>{tableData.affiliateCode}</td>
          <td>{tableData.cardId}</td>
          <td>{tableData.cardName}</td>
          <td>{tableData.cardType}</td>
          <td>{tableData.maskedPan}</td>
          <td>{tableData.crDr}</td>
          <td>{tableData.postingSettlementAmount}</td>
          <td>{tableData.narration}</td>
          <td>{tableData.txnDate}</td>
          <td>{tableData.txnDateLocal}</td>
          <td>{tableData.txnCode}</td>
          <td>{tableData.branch}</td>
          <td>{tableData.ccy}</td>
          <td>{tableData.mid}</td>
          <td>{tableData.lcyAmount}</td>
          <td>{tableData.product}</td>
          <td>{tableData.subgroup}</td>
          <td>{tableData.postilionRrn}</td>
          <td>{tableData.flexCubeLcyAmt}</td>
          <td>{tableData.postilionRrnResolver}</td>
          <td>{tableData.flexCubeLcyAmtResolver}</td>
          <td>{tableData.postilionRrnResolvedDate}</td>
          <td>{tableData.xrate}</td>
          <td>{tableData.batchDate}</td>
          <td>{tableData.xrate}</td>
        </tr>
      );
    });
  }

  if (tableName === "postilionLogSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.fileDate}</td>
          <td>{tableData.tranNr}</td>
          <td>{tableData.messageType}</td>
          <td>{tableData.tranType}</td>
          <td>{tableData.sourceNodeName}</td>
          <td>{tableData.sinkNodeName}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.fromAccountId}</td>
          <td>{tableData.terminalId}</td>
          <td>{tableData.cardAcceptorIdCode}</td>
          <td>{tableData.cardAcceptorNameLoc}</td>
          <td>{tableData.rspCodeRsp}</td>
          <td>{tableData.dateTime}</td>
          <td>{tableData.date}</td>
          <td>{tableData.time}</td>
          <td>{tableData.dateTimeTranGmt}</td>
          <td>{tableData.systemTraceAuditNr}</td>
          <td>{tableData.tranAmountRsp}</td>
          <td>{tableData.tranCashRsp}</td>
          <td>{tableData.tranCurrencyCode}</td>
          <td>{tableData.settleCurrencyCode}</td>
          <td>{tableData.settleAmountReq}</td>
          <td>{tableData.settleAmountRsp}</td>
          <td>{tableData.settleCashRsp}</td>
          <td>{tableData.settleAmountImpact}</td>
          <td>{tableData.switchKey}</td>
          <td>{tableData.type}</td>
          <td>{tableData.retrieval_reference_nr}</td>
          <td>{tableData.auth_id_rsp}</td>
        </tr>
      );
    });
  }

  if (tableName === "flexcubeLogSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.fileDate}</td>
          <td>{tableData.p_key}</td>
          <td>{tableData.msg_type}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.proc_code}</td>
          <td>{tableData.txn_amount}</td>
          <td>{tableData.setl_amt}</td>
          <td>{tableData.bill_amt}</td>
          <td>{tableData.trans_dt_time}</td>
          <td>{tableData.stan}</td>
          <td>{tableData.acq_ins_id}</td>
          <td>{tableData.fwd_ins_id}</td>
          <td>{tableData.rrn}</td>
          <td>{tableData.resp_code}</td>
          <td>{tableData.term_id}</td>
          <td>{tableData.txn_ccy_code}</td>
          <td>{tableData.setl_ccy_code}</td>
          <td>{tableData.bill_ccy_code}</td>
          <td>{tableData.from_acc}</td>
          <td>{tableData.to_acc}</td>
          <td>{tableData.txn_desc}</td>
          <td>{tableData.exp_date}</td>
          <td>{tableData.setl_date}</td>
          <td>{tableData.conv_date}</td>
          <td>{tableData.capt_date}</td>
          <td>{tableData.msg_flow}</td>
          <td>{tableData.work_progress}</td>
          <td>{tableData.err_param}</td>
          <td>{tableData.trn_ref_no}</td>
          <td>{tableData.amount_block_no}</td>
          <td>{tableData.purge_date}</td>
          <td>{tableData.xref}</td>
          <td>{tableData.add_amt}</td>
          <td>{tableData.mini_stmt_data}</td>
          <td>{tableData.file_process}</td>
          <td>{tableData.fin_imp}</td>
          <td>{tableData.db_in_time}</td>
          <td>{tableData.db_out_time}</td>
          <td>{tableData.error_code}</td>
          <td>{tableData.pre_auth_seq_no}</td>
          <td>{tableData.reconsiled}</td>
          <td>{tableData.dcn}</td>
          <td>{tableData.offus_onus}</td>
          <td>{tableData.term_addr}</td>
          <td>{tableData.event}</td>
          <td>{tableData.ac_entry_sr_entry_no}</td>
          <td>{tableData.ac_branch}</td>
          <td>{tableData.ac_no}</td>
          <td>{tableData.ac_ccy}</td>
          <td>{tableData.category}</td>
          <td>{tableData.drcr_ind}</td>
          <td>{tableData.trn_code}</td>
          <td>{tableData.exch_rate}</td>
          <td>{tableData.trn_dt}</td>
          <td>{tableData.value_dt}</td>
          <td>{tableData.amount_tag}</td>
          <td>{tableData.related_account}</td>
          <td>{tableData.related_customer}</td>
          <td>{tableData.batch_no}</td>
          <td>{tableData.user_id}</td>
          <td>{tableData.external_ref_no}</td>
          <td>{tableData.save_timestamp}</td>
          <td>{tableData.auth_timestamp}</td>
          <td>{tableData.country_code}</td>
          <td>{tableData.type}</td>
          <td>{tableData.fcy_amount}</td>
          <td>{tableData.lcy_amount}</td>
          <td>{tableData.auth_id}</td>
        </tr>
      );
    });
  }

  if (tableName === "pos-acquirersLogSummary") {
    return dataRecords.map((tableData, index) => {
      return (
        <tr key={index}>
          <td>{tableData.channelName}</td>
          <td>{tableData.serNo}</td>
          <td>{tableData.psName}</td>
          <td>{tableData.i018MerchType}</td>
          <td>{tableData.i031Arn}</td>
          <td>{tableData.i037RetRefNum}</td>
          <td>{tableData.i038AuthId}</td>
          <td>{tableData.i039RespCd}</td>
          <td>{tableData.i041PosId}</td>
          <td>{tableData.i043AMerchName}</td>
          <td>{tableData.i043BMerchCity}</td>
          <td>{tableData.i043CMerchCnt}</td>
          <td>{tableData.i000MsgType}</td>
          <td>{tableData.pan}</td>
          <td>{tableData.i003ProcCode}</td>
          <td>{tableData.i004AmtTrxn}</td>
          <td>{tableData.i005AmtSettle}</td>
          <td>{tableData.i006AmtBill}</td>
          <td>{tableData.i007LoadDate}</td>
          <td>{tableData.i013TrxnDate}</td>
          <td>{tableData.i042MerchId}</td>
          <td>{tableData.i048TextData}</td>
          <td>{tableData.i049CurTrxn}</td>
          <td>{tableData.i050CurSettle}</td>
          <td>{tableData.i051CurBill}</td>
          <td>{tableData.centerAmount}</td>
          <td>{tableData.installmentType}</td>
          <td>{tableData.amount}</td>
          <td>{tableData.tipAmount}</td>
          <td>{tableData.billTipAmount}</td>
          <td>{tableData.comAmount}</td>
          <td>{tableData.origComAmount}</td>
          <td>{tableData.postDate}</td>
          <td>{tableData.postTimeStamp}</td>
          <td>{tableData.stGeneral}</td>
        </tr>
      );
    });
  }
  if (tableName === "masterCardPOS-AcquirerPostingSummary") {
    return dataRecords.map((record, indx) => {
      return (
        <tr
          key={indx}
          className={
            record.calcLcyAmount
              ? [
                record.lcyAmount === record.calcLcyAmount
                  ? "bgColor"
                  : "bg-danger",
              ]
              : [""]
          }
        >
          <td>{record.channelName}</td>
          <td>{record.serNo}</td>
          <td>{record.psName}</td>
          <td>{record.i018MerchType}</td>
          <td>{record.i031Arn}</td>
          <td>{record.i037RetRefNum}</td>
          <td>{record.i038AuthId}</td>
          <td>{record.i039RespCd}</td>
          <td>{record.i041PosId}</td>
          <td>{record.i043AMerchName}</td>
          <td>{record.i043BMerchCity}</td>
          <td>{record.i043CMerchCnt}</td>
          <td>{record.i000MsgType}</td>
          <td>{record.pan}</td>
          <td>{record.i003ProcCode}</td>
          <td>{record.i004AmtTrxn}</td>
          <td>{record.i005AmtSettle}</td>
          <td>{record.i006AmtBill}</td>
          <td>{record.i007LoadDate}</td>
          <td>{record.i013TrxnDate}</td>
          <td>{record.i042MerchId}</td>
          <td>{record.i048TextData}</td>
          <td>{record.i049CurTrxn}</td>
          <td>{record.i050CurSettle}</td>
          <td>{record.i051CurBill}</td>
          <td>{record.centerAmount}</td>
          <td>{record.installmentType}</td>
          <td>{record.amount}</td>
          <td>{record.tipAmount}</td>
          <td>{record.billTipAmount}</td>
          <td>{record.comAmount}</td>
          <td>{record.origComAmount}</td>
          <td>{record.postDate}</td>
          <td>{record.postTimeStamp}</td>
          <td>{record.stGeneral}</td>
        </tr>
      );
    });
  }
  if (tableName === "cup-acquirer-filedetails") {
    return dataRecords.map((record, indx) => {
      return (
        <tr key={indx}>
          <td>{record.summaryId}</td>
          <td>{record.acquiringInstitutionIdentificationCode}</td>
          <td>{record.forwardingInstitutionIdentificationCode}</td>
          <td>{record.systemTraceAuditNumber}</td>
          <td>{record.transmissionDateAndTime}</td>
          <td>{record.primaryAccountNumber}</td>
          <td>{record.transactionAmount}</td>
          <td>{record.messageType}</td>
          <td>{record.processingCode}</td>
          <td>{record.merchantType}</td>
          <td>{record.cardAcceptorTerminalIdentification}</td>
          <td>{record.cardAcceptorIdentificationCode}</td>
          <td>{record.cardAcceptorNameLocation}</td>
          <td>{record.retrievalReferenceNumber}</td>
          <td>{record.pointOfServiceConditionCode}</td>
          <td>{record.authorizationIdentification}</td>
          <td>{record.receivingInstitutionIdentificationCode}</td>
          <td>{record.systemTraceAuditNumberOriginal}</td>
          <td>{record.responseCode}</td>
          <td>{record.transactionCurrencyCode}</td>
          <td>{record.pointOfServiceEntryMode}</td>
          <td>{record.settlementCurrencyCode}</td>
          <td>{record.settlementAmount}</td>
          <td>{record.settlementConversionRate}</td>
          <td>{record.settlementDate}</td>
          <td>{record.exchangeDate}</td>
          <td>{record.feeReceivable}</td>
          <td>{record.feePayable}</td>
          <td>{record.interchangeServiceFee}</td>
          <td>{record.serviceFeeCurrency}</td>
          <td>{record.serviceFeeExchangeRate}</td>
          <td>{record.transactionFee}</td>
          <td>{record.rfBillingCurrency}</td>
          <td>{record.rfToSettlementCurrency}</td>
          <td>{record.reserved}</td>
          <td>{record.affiliateCode}</td>
          <td>{record.affiliateName}</td>
          <td>{record.maskedPan}</td>
        </tr>
      );
    });
  }
  return (
    //TODO summary table with double map and no type needs urgent fix in next push!
    dataRecords &&
    dataRecords.map((item, index) => {
      return item.records.map((record, indx) => {
        return (
          <tr
            key={indx}
            className={
              record.calcLcyAmount
                ? [
                  record.lcyAmount === record.calcLcyAmount
                    ? "bgColor"
                    : "bg-danger",
                ]
                : [""]
            }
          >
            <td>{record.branch}</td>
            <td>{record.homeBranch}</td>
            <td>{record.constant1}</td>
            <td>
              {record.lcyAmount ? record.lcyAmount.toLocaleString() : "N/A"}
            </td>
            <td>{record.account}</td>
            <td>{record.description}</td>
            <td>{record.crDr}</td>
            <td>{record.accountBranch}</td>
            <td>{record.txnCode}</td>
            <td>{record.txnDate}</td>
            <td>{record.narration}</td>
            <td>{record.mis}</td>
            {/* <td>{record.settlementAmount?record.settlementAmount.toLocaleString():0}</td> */}
          </tr>
        );
      });
    })
  );
};

export default connect(null, { handleException })(PostingTable);
