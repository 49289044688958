
import {
    GET_BILLING_RECORDS,
    GET_BILLING_RECORDS_SUCCESS,
    GET_BILLING_RECORDS_FAILURE,
    DOWNLOAD_BILLING_RECORD,
    DOWNLOAD_BILLING_RECORD_SUCCESS,
    DOWNLOAD_BILLING_RECORD_FAILURE,
} from '../actions/types';


const initialState = {
    billingRecords: { content: [] },
    billingRecordsLoading: false,
    billingRecordsError: "",
    downLoadRecordsLoading: false,
    downloadRecordsError: "",
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BILLING_RECORDS: {
            return { ...state, billingRecordsLoading: action.payload, billingRecordsError: "" }
        }
        case GET_BILLING_RECORDS_SUCCESS: {
            return { ...state, billingRecordsLoading: false, billingRecordsError: "", billingRecords: action.payload }
        }
        case GET_BILLING_RECORDS_FAILURE: {
            return { ...state, billingRecordsLoading: false, billingRecordsError: action.payload, billingRecords: { content: [] } }
        }
        case DOWNLOAD_BILLING_RECORD: {
            return { ...state, downLoadRecordsLoading: action.payload, billingRecordsError: "" }
        }
        case DOWNLOAD_BILLING_RECORD_SUCCESS: {
            return { ...state, downLoadRecordsLoading: false, downloadRecordsError: "" }
        }
        case DOWNLOAD_BILLING_RECORD_FAILURE: {
            return { ...state, downLoadRecordsLoading: false, downloadRecordsError: action.payload }
        }
        default:
            return state;
    }
}