// Modules
import * as JWT from "jwt-decode";
import * as qs from "query-string";
import CryptoJS from "crypto-js";
import store from "../../store";
import { INVALID_TOKEN } from "../../actions/types";

const getAndDecryptToken = async (queryStringParam, tokenTestFn) => {
	// gets the query string param from the location url
	let decoded;
	let decryptedToken;
	if (queryStringParam) {
		let encryptedToken = qs.parse(queryStringParam).var; // use query-string package to extract the query params to an object
		localStorage.setItem("se8lementLang", qs.parse(queryStringParam).lang);
		// This condtion catches invalid token sent from user-mgt backend and stops the decryption. Logging out the user. in the end.
		if (!encryptedToken) return;
		if (encryptedToken.length > 300) {
			// Decrypt the encrypted token
			decryptedToken = CryptoJS.AES
				.decrypt(encryptedToken.split(" ").join("+"), "introspecAppToken")
				.toString(CryptoJS.enc.Utf8);
			decoded = JWT(decryptedToken);

			const isValidToken = await tokenTestFn(decryptedToken);
			if (isValidToken === 401)
				return store.dispatch({
					type    : INVALID_TOKEN,
					payload : "Invalid token supplied",
				});
		}

		localStorage.setItem("token", decryptedToken);
		localStorage.setItem("decodedToken", JSON.stringify(decoded));
		localStorage.setItem("se8lementLang", qs.parse(queryStringParam).lang);

		// below are necessary to push the routes into history from the login page.
		const historyUrl = `${window.location.href.split("#")[0]}#/dashboard`;
		window.history.pushState({}, document.title, historyUrl);
		window.location.reload();
	}

	return null; // just return null if nothing to process in the queryStringParam
};

export default getAndDecryptToken;
