import { 
    FETCH_DB_COLLECTIONS,
    FETCH_DB_COLLECTIONS_ERROR,
    CLEAR_COLLECTION_RECORD,
    CLEAR_COLLECTION_RECORD_ERROR,
    CLEAR_ALL_COLLECTIONS,
    CLEAR_ALL_COLLECTIONS_ERROR,
    CLEAR_COLLECTIONS_RESPONSE
} from '../actions/types';

export default function(state={collections:[]},action){
    switch(action.type){
        case FETCH_DB_COLLECTIONS:
            return {...state, collections:action.payload}
        case FETCH_DB_COLLECTIONS_ERROR:
            return {...state, error:action.payload}
        case CLEAR_COLLECTION_RECORD:
            return {...state, response:action.payload}
        case CLEAR_COLLECTION_RECORD_ERROR:
            return {...state, errorResponse:action.payload}    
        case CLEAR_ALL_COLLECTIONS:
            return {...state, response:action.payload}
        case CLEAR_ALL_COLLECTIONS_ERROR:
            return {...state, errorResponse:action.payload}    
        case  CLEAR_COLLECTIONS_RESPONSE:
            return {...state, response:'',errorResponse:''}   
        default:
            return state;        

    }
}