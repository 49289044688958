import PropTypes from "prop-types";
import React from 'react';
import Pagination2 from 'react-js-pagination';

const Pagination = ({ page, range, onChange, data, loading }) => {
    if (!data || loading || !data.numberOfElements) return null;
    return (
        <Pagination2
            activePage={parseInt(page)}
            itemsCountPerPage={data.size}
            totalItemsCount={data.totalElements}
            pageRangeDisplayed={range}
            onChange={onChange}
        />
    )
}

Pagination.propTypes = {
    data: PropTypes.shape({
        size: PropTypes.number,
        totalElements: PropTypes.number
    }),
    onChange: PropTypes.func,
    page: PropTypes.number,
    range: PropTypes.number
};

Pagination.defaultProps = {
    page: 1,
    range: 20
}

export default Pagination
