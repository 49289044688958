import PropTypes from "prop-types";
import React from "react";
import { Card, CardHeader, CardBody, Table, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { DropDown } from "../components";
import BtnDropdown from "../../../components/BtnDropdown";
import { useLocation } from "react-router-dom";

const PostingEntries = ({ data, error, loading, url, deleteFunc, scheme }) => {
  if (error || loading) return null;
  let location = useLocation();
  let postingEntries =
    (data && data.postingMap && data.postingMap) || (data && data.meta);
  let settlementDate = data && data.settlementDate;
  let settlementPostingLogId = data && data.settlementPostingLogId;
  let postingEntriesMap = [];
  Object.entries(postingEntries ? postingEntries : []).forEach(
    ([key, value]) => {
      let itemObj = {};
      itemObj.key = key;
      itemObj.value = value;
      postingEntriesMap = postingEntriesMap.concat(itemObj);
      return postingEntriesMap;
    }
  );
  if (!data) return null;
  return (
    <Card className={"h-100"}>
      <CardHeader
        className={"d-flex justify-content-between align-item-center w-100"}
      >
        <strong>
          <FormattedMessage
            id="Posting Entries"
            defaultMessage="Posting Entries"
          />
        </strong>
        {settlementPostingLogId && (
          <BtnDropdown
            settlementPostingLogId={settlementPostingLogId}
            settlementDate={settlementDate}
            scheme={scheme}
            Location={Location}
          />
        )}
      </CardHeader>
      <CardBody>
        {PostingEntries && PostingEntries.length === 0 && (
          <p className="text-danger text-center">No entries</p>
        )}
        {postingEntries && Object.keys(postingEntries).length > 0 && (
          <Table bordered striped hover size="sm">
            <thead>
              <tr>
                <th colSpan={3} className="p-2">
                  <FormattedMessage
                    id="Affiliate Posting Entries"
                    defaultMessage="Affiliate Posting Entries"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {postingEntriesMap.map((entries, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <FormattedMessage
                        id={entries.key}
                        defaultMessage={entries.key}
                      />
                    </td>
                    <td
                      className={
                        "d-flex justify-content-between align-items-center no-bottom"
                      }
                    >
                      <Link
                        className="VisaSettlementLinks"
                        to={{
                          pathname: `${url}/settlement/${
                            url === "/cup" ? data.id : entries.value
                          }/query/details`,
                          search: `?page=1&settlementDate=${settlementDate}`,
                          state: {
                            from: `${location.pathname}${location.search}`,
                          },
                        }}
                        onClick={() =>
                          localStorage.setItem(
                            "goBackUrl",
                            `${location.pathname}${location.search}`
                          )
                        }
                      >
                        <FormattedMessage
                          id="View Posting Entries"
                          defaultMessage="View Posting Entries"
                        />
                      </Link>
                    </td>
                    <td>
                      <DropDown>
                        <DropdownItem onClick={() => deleteFunc(entries)}>
                          Delete{" "}
                        </DropdownItem>
                      </DropDown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
};

PostingEntries.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func,
  }),
  url: PropTypes.string,
  deleteFunc: PropTypes.func,
};

PostingEntries.defaultProps = {
  data: [],
  url: "",
};

export default PostingEntries;
