import React from "react";
// import {FormattedMessage,FormattedHTMLMessage} from 'react-intl'; kept to remind me that there is FormattedMessage
import { FormattedMessage } from "react-intl";

const PostingTableHeader = ({ headerType, headerPayload }) => {
  // header to use for the action (posting) header view
  if (!headerType) {
    return (
      <tr>
        <th>
          <FormattedMessage id="branch" defaultMessage="Branch" />
        </th>
        <th>
          <FormattedMessage id="home-branch" defaultMessage="Home Branch" />
        </th>
        <th>
          <FormattedMessage id="constant-1" defaultMessage="constant 1" />
        </th>
        <th>
          <FormattedMessage id="lyc-amount" defaultMessage="LYC Amount" />
        </th>
        <th>
          <FormattedMessage
            id="gl-account-number"
            defaultMessage="GL/ Account Number"
          />
        </th>
        <th>
          <FormattedMessage
            id="gl-account-description"
            defaultMessage="GL/ Account Description"
          />
        </th>
        <th>
          <FormattedMessage id="dc" defaultMessage="D/C" />
        </th>
        <th>
          <FormattedMessage
            id="gl-account-branch"
            defaultMessage="GL Account Branch"
          />
        </th>
        <th>
          <FormattedMessage
            id="transaction-code"
            defaultMessage="Transaction Code (Constant)"
          />
        </th>
        <th>
          <FormattedMessage id="date" defaultMessage="Date (YYYYmmdd)" />
        </th>
        <th>
          <FormattedMessage id="narration" defaultMessage="Narration" />
        </th>
        <th>
          <FormattedMessage id="mis" defaultMessage="MIS (Cost Center Code)" />
        </th>
      </tr>
    );
  }

  if (headerType === "objects") {
    return (
      <tr>
        {headerPayload
          .filter(
            (header) =>
              header.name !== "id" &&
              header.name !== "exception" &&
              header.name !== "resolved"
          )
          .map((header) => {
            return (
              <td key={header.name}>
                <strong>
                  <FormattedMessage
                    id={header.title}
                    defaultMessage={header.title}
                  />
                </strong>
              </td>
            );
          })}
      </tr>
    );
  }

  if (headerType === "array") {
    if (!headerPayload) {
      return null;
    }

    return (
      <tr>
        {headerPayload.map((header, index) => {
          return (
            <td key={index}>
              <strong>
                <FormattedMessage id={header} defaultMessage={header} />
              </strong>
            </td>
          );
        })}
      </tr>
    );
  }
  if (headerType === "pos-acquirer") {
    return (
      <tr>
        <th>
          <FormattedMessage id="channelName" defaultMessage="channelName" />
        </th>
        <th>
          <FormattedMessage id="serNo" defaultMessage="serNo" />
        </th>
        <th>
          <FormattedMessage id="psName" defaultMessage="psName" />
        </th>
        <th>
          <FormattedMessage id="io18MerchType" defaultMessage="io18MerchType" />
        </th>
        <th>
          <FormattedMessage id="i031Arn" defaultMessage="i031Arn" />
        </th>
        <th>
          <FormattedMessage id="i037RetRefNum" defaultMessage="i037RetRefNum" />
        </th>
        <th>
          <FormattedMessage id="i038AuthId" defaultMessage="i038AuthId" />
        </th>
        <th>
          <FormattedMessage id="i039RespCd" defaultMessage="i039RespCd" />
        </th>
        <th>
          <FormattedMessage id="i041PosId" defaultMessage="i041PosId" />
        </th>
        <th>
          <FormattedMessage
            id="i043AMerchName"
            defaultMessage="i043AMerchName"
          />
        </th>
        <th>
          <FormattedMessage
            id="i043BMerchCity"
            defaultMessage="i043BMerchCity"
          />
        </th>
        <th>
          <FormattedMessage id="i043CMerchCnt" defaultMessage="i043CMerchCnt" />
        </th>
        <th>
          <FormattedMessage id="i000MsgType" defaultMessage="i000MsgType" />
        </th>
        <th>
          <FormattedMessage id="pan" defaultMessage="pan" />
        </th>
        <th>
          <FormattedMessage id="i003ProcCode" defaultMessage="i003ProcCode" />
        </th>
        <th>
          <FormattedMessage id="i004AmtTrxn" defaultMessage="i004AmtTrxn" />
        </th>
        <th>
          <FormattedMessage id="i005AmtSettle" defaultMessage="i005AmtSettle" />
        </th>
        <th>
          <FormattedMessage id="i006AmtBill" defaultMessage="i006AmtBill" />
        </th>
        <th>
          <FormattedMessage id="i007LoadDate" defaultMessage="i007LoadDate" />
        </th>
        <th>
          <FormattedMessage id="i013TrxnDate" defaultMessage="i013TrxnDate" />
        </th>
        <th>
          <FormattedMessage id="i042MerchId" defaultMessage="i042MerchId" />
        </th>
        <th>
          <FormattedMessage id="i048TextData" defaultMessage="i048TextData" />
        </th>
        <th>
          <FormattedMessage id="i049CurTrxn" defaultMessage="i049CurTrxn" />
        </th>
        <th>
          <FormattedMessage id="i050CurSettle" defaultMessage="i050CurSettle" />
        </th>
        <th>
          <FormattedMessage id="i051CurBill" defaultMessage="i051CurBill" />
        </th>
        <th>
          <FormattedMessage id="centerAmount" defaultMessage="centerAmount" />
        </th>
        <th>
          <FormattedMessage
            id="installmentType"
            defaultMessage="installmentType"
          />
        </th>
        <th>
          <FormattedMessage id="amount" defaultMessage="amount" />
        </th>
        <th>
          <FormattedMessage id="tipAmount" defaultMessage="tipAmount" />
        </th>
        <th>
          <FormattedMessage id="billTipAmount" defaultMessage="billTipAmount" />
        </th>
        <th>
          <FormattedMessage id="comAmount" defaultMessage="comAmount" />
        </th>
        <th>
          <FormattedMessage id="origComAmount" defaultMessage="origComAmount" />
        </th>
        <th>
          <FormattedMessage id="postDate" defaultMessage="postDate" />
        </th>
        <th>
          <FormattedMessage id="postTimeStamp" defaultMessage="postTimeStamp" />
        </th>
        <th>
          <FormattedMessage id="stGeneral" defaultMessage="stGeneral" />
        </th>
      </tr>
    );
  }
};

export default PostingTableHeader;
