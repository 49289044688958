import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";

import { dispatchSelectedUserAffiliateCode } from "../../actions/filesets";
import {
  fetchLoggedInUserAffiliates,
  fetchUserAffiliates,
  fetchUsers,
  getLoggedInUser,
} from "../../actions/utils";
import activedge_logo from "../../assets/img/brand/settlement-logo.png";
import axios from "axios";

// Redux
// components
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const DefaultHeader = ({
  attributes,
  children,
  // action creators
  getLoggedInUser,
  fetchUserAffiliates,
  dispatchSelectedUserAffiliateCode,
  fetchUsers,
  // state props
  isUnAuthorized,
  userDetail,
  userAffiliates,
  loggedInUserAffiliates,
  fetchLoggedInUserAffiliates,
}) => {
  // instantiate history
  const history = useHistory();

  const [account, setAccount] = useState("");
  const [selectedAffiliate, setSelectedAffiliate] = useState("");

  useEffect(() => {
    (async () => {
      setSelectedAffiliate(localStorage.getItem("selectedAffilliate"));
      axios.defaults.headers.common["affiliateCode"] =
        localStorage.getItem("selectedAffilliate");
      const data = await getLoggedInUser();
      fetchLoggedInUserAffiliates(data.login);
      if (!localStorage.getItem("currentSelectedUserEmail")) {
        localStorage.setItem("currentSelectedUserEmail", data.login);
      }
      // I commented this line because on the UserManagement page, after refresh it always set the logged in user as the selected user

      // Sets Logged In User to Local Storage
      localStorage.setItem("loggedInUserEmail", data.login);
      fetchUserAffiliates(data.login);
      fetchUsers();
    })();
  }, []);

  const [allUserAffiliates, setAllUserAffiliates] = useState([]);
  useEffect(() => {
    let selectedAffilliateFromLocalStorage =
      localStorage.getItem("selectedAffilliate");
    let arrayOfAffiliates =
      loggedInUserAffiliates && loggedInUserAffiliates.map((data) => data.code);
    let defaultAffiliate = arrayOfAffiliates && arrayOfAffiliates[0];
    setAllUserAffiliates(loggedInUserAffiliates);
    localStorage.setItem(
      "userAffilliates",
      JSON.stringify(loggedInUserAffiliates)
    );
    if (
      !selectedAffilliateFromLocalStorage ||
      selectedAffilliateFromLocalStorage === undefined
    ) {
      handleSelectAffiliate({ code: defaultAffiliate });
      axios.defaults.headers.common["affiliateCode"] = defaultAffiliate;
    }
    if (selectedAffiliate === undefined) {
      handleSelectAffiliate({ code: defaultAffiliate });
    }
  }, [loggedInUserAffiliates]);

  useEffect(() => {
    if (localStorage.decodedToken) {
      setAccount(JSON.parse(localStorage.getItem("decodedToken")).sub);
    }
  }, [userDetail]);

  const logOut = () => {
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  };

  const handleSelectAffiliate = (affiliate) => {
    setSelectedAffiliate(affiliate.code);
    axios.defaults.headers.common["affiliateCode"] = affiliate.code;
    localStorage.setItem("selectedAffilliate", affiliate.code);
    dispatchSelectedUserAffiliateCode(affiliate.code);
  };

  const goToSettings = () => {
    history.push(`/settings`);
  };

  // const { children, ...attributes } = props;

  if (isUnAuthorized) {
    logOut();
  }

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand
        full={{
          src: activedge_logo,
          width: 100,
          height: 40,
          alt: "ActivEdge Logo",
        }} // 70 width original
        minimized={{
          src: activedge_logo,
          width: 40,
          height: 40,
          alt: "ActivEdge Logo",
        }}
      />
      <AppSidebarToggler className="d-md-down-none" display="lg" />

      <Nav className="ml-auto" navbar>
        {/* <NavItem className='d-md-down-none px-4'>
					<NavLink to='' className='nav-link'>
						<i className='icon-bell' />
						<Badge pill color='danger'>
							0
						</Badge>
					</NavLink>
				</NavItem> */}

        <UncontrolledDropdown direction="down">
          <DropdownToggle nav>
            {selectedAffiliate && (
              <span to="">
                {" "}
                <i
                  className={
                    "flag-icon flag-icon-" + selectedAffiliate.toLowerCase() &&
                    selectedAffiliate.toLowerCase()
                  }
                  title="al"
                  id="al"
                />{" "}
                <strong>
                  {selectedAffiliate !== "undefined" && selectedAffiliate}
                </strong>{" "}
                <i className="fa fa-caret-down" aria-hidden="true" />
              </span>
            )}
            {!selectedAffiliate && "..."}
          </DropdownToggle>
          <DropdownMenu right className="affiliate_list">
            {loggedInUserAffiliates &&
              loggedInUserAffiliates.map((item, indx) => (
                <DropdownItem
                  key={indx}
                  onClick={() => handleSelectAffiliate(item)}
                >
                  {item.name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <img
              src={"assets/img/avatars/7.jpg"}
              className="img-avatar"
              alt="admin@bootstrapmaster.com"
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header tag="div" className="text-center">
              <strong>Account</strong>
            </DropdownItem>
            <DropdownItem>
              <i className="fa fa-user-o" /> {account}
            </DropdownItem>
            <DropdownItem onClick={goToSettings}>
              <i className="fa fa-cog" /> Settings
            </DropdownItem>
            <DropdownItem onClick={logOut}>
              <i className="fa fa-lock" /> Logout
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
      {/* <AppAsideToggler className="d-md-down-none" /> */}
      {/*<AppAsideToggler className="d-lg-none" mobile />*/}
    </React.Fragment>
  );
};

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    userAffiliates: state.utils.userAffiliates,
    loggedInUserAffiliates: state.utils.loggedInUserAffiliates,
    userDetail: state.utils.userDetail,
    isUnAuthorized: state.utils.isUnAuthorized,
  };
};

export default connect(mapStateToProps, {
  getLoggedInUser,
  fetchUserAffiliates,
  dispatchSelectedUserAffiliateCode,
  fetchUsers,
  fetchLoggedInUserAffiliates,
})(DefaultHeader);
