import PropTypes from "prop-types";
import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loading = ({ data, error, loading }) => {
    if ((error || !data || data.length) || !loading) return null;
    return (
        <Loader
            type="ThreeDots"
            color="#337AB7"
            height={100}
            width={100}
            style={{ textAlign: "center" }}
        />
    )
}

Loading.propTypes = {
    data: PropTypes.any,
    error: PropTypes.string,
    loading: PropTypes.bool
}

export default Loading
