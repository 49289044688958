// export const generateSideBarByUserPermission = (SettlementSidebar, userPermissions) => {
// 	let userSidebarItems = [];
// 	const userSidebar = {};
// 	// Generate dynamic sidebar
// 	SettlementSidebar.map((item) => {
// 		if (item.name === "Dashboard") {
// 			userSidebarItems = userSidebarItems.concat(item);
// 			return userSidebarItems;
// 		}

// 		if (userPermissions.includes(item.viewPermission)) {
// 			userSidebarItems = userSidebarItems.concat(item);
// 			return userSidebarItems;
// 		}
// 	});

// 	// Generate dynamic sidebar children
// 	userSidebarItems.map((item) => {
// 		let newSidebarChildrenItems = [];
// 		item.children &&
// 			item.children.map((childItem) => {
// 				if (childItem.viewPermission && typeof childItem.viewPermission === "string") {
// 					if (userPermissions.includes(childItem.viewPermission)) {
// 						newSidebarChildrenItems = newSidebarChildrenItems.concat(childItem);
// 						return newSidebarChildrenItems;
// 					}
// 				}

// 				if (childItem.viewPermission && childItem.viewPermission.length > 1) {
// 					if (userPermissions.some((element) => childItem.viewPermission.indexOf(element) > -1)) {
// 						newSidebarChildrenItems = newSidebarChildrenItems.concat(childItem);
// 						return newSidebarChildrenItems;
// 					}
// 				}
// 			});
// 		item.children = newSidebarChildrenItems;
// 	});
// 	userSidebar.items = userSidebarItems;
// 	return userSidebar;
// };

export const generateSideBarByUserPermission = (
  SettlementSidebar,
  userPermissions
) => {
  const userSidebar = {
    items: [],
  };

  // Generate dynamic sidebar
  SettlementSidebar.forEach((item) => {
    if (
      item.name === "Dashboard" ||
      userPermissions.includes(item.viewPermission)
    ) {
      const newItem = { ...item };

      // Generate dynamic sidebar children
      if (item.children) {
        const newChildren = [];

        item.children.forEach((childItem) => {
          if (
            childItem.viewPermission &&
            ((typeof childItem.viewPermission === "string" &&
              userPermissions.includes(childItem.viewPermission)) ||
              (Array.isArray(childItem.viewPermission) &&
                userPermissions.some((perm) =>
                  childItem.viewPermission.includes(perm)
                )))
          ) {
            newChildren.push({ ...childItem });
          }
        });

        newItem.children = newChildren;
      }

      userSidebar.items.push(newItem);
    }
  });

  return userSidebar;
};
