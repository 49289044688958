import React, { Component } from "react"
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Jumbotron, Row } from 'reactstrap';


class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log("THE ERROR", error, "THE ERROR INFO FROM COMPONENT", errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div className="animated fadeIn">
				<Row className="mt-5 mb-5 justify-content-center">
					<Col md="12">
						<h2 className="text-danger text-center">ACTION COULD NOT BE COMPLETED</h2>
					</Col>
					<Col xs="12" md="6" sm="12">
						<Card>
							<CardHeader>
							</CardHeader>
							<CardBody>
								<Jumbotron>
									<p className="my-2">
										Oops!
										While trying to perform your last operation, something unexpected happened during exchange with the server.
										Kindly contact AET Admin for more details.
					  				</p>
									<hr />
									<div className="d-flex justify-content-between">
										<Button className="pull-right btn-primary" onClick={() => this.props.history.goBack()} variant="primary">Go Back</Button>
										<Button className="pull-right btn-primary" onClick={() => this.props.history.push("/dashboard")} variant="primary">Goto Dashboard</Button>
									</div>
								</Jumbotron>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>

		}

		return this.props.children;
	}
}

export default ErrorBoundary;
