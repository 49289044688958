import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

const Page401 = () => {
	const history = useHistory();

	return (
		<div className='app flex-row align-items-center'>
			<Container>
				<Row className='justify-content-center'>
					<Col md='6'>
						<div className='clearfix'>
							<h1 className='float-left display-3 mr-4'>401</h1>
							<h4 className='pt-3'>UNAUTHORIZED ACCESS!!!.</h4>
							<p className='text-muted float-left'>
								You do not have the permission to perform this operation.
							</p>
						</div>
						<div className={"d-flex justify-content-between align-items-center"}>
							<Button color='primary' className={"w-50 mx-1"} onClick={() => history.push("/dashboard")}>
								Dashboard
							</Button>
							<Button color='warning' className={"w-50 mx-1"} onClick={() => history.push("/login")}>
								Log Out
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Page401;
