// Styles
import './App.css';
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

// Bootstrap Js
import 'bootstrap/dist/js/bootstrap.js';
//react notitifications
// import ReactNotification from 'react-notifications-component';
// import 'react-notifications-component/dist/theme.css';

// React
import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { setLocale } from './actions/locale';
import { IntlProvider } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_fr from 'react-intl/locale-data/fr';
import locale_pt from 'react-intl/locale-data/pt';
import locale_es from 'react-intl/locale-data/es';

import { addLocaleData } from 'react-intl';
import messages_de from './translations/de.json';
import messages_en from './translations/en.json';
import messages_fr from './translations/fr.json';
import messages_pt from './translations/pt.json';
import messages_es from './translations/es.json';

// Redux
import { connect } from 'react-redux';
import { getUserMgtUrl, getUserMgtUrlBackEnd } from './actions/utils';
import { getAutoPostingStatus } from './actions/filesets';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page401, Page404, Page500, Register } from './views/Pages';

// components
import GenericPostingRecords from './views/Schemes/GenericPostingRecords/GenericPostingRecords';

// util
import getAndDecryptToken from './Utils/Auth/getToken';
import getTokenValidity from './Utils/Auth/getTokenValidity';
import Landing from './views/Pages/Landing/Landing';
import { PostingDetails } from './views/Schemes/components';

const messages = {
  de: messages_de,
  en: messages_en,
  fr: messages_fr,
  pt: messages_pt,
  es: messages_es,
};

addLocaleData([
  ...locale_en,
  ...locale_de,
  ...locale_fr,
  ...locale_pt,
  ...locale_es,
]);
getAndDecryptToken(window.location.hash.split('?')[1], getTokenValidity);

// TODO back btn from user management to settlement is not working well
const App = (props) => {
  const {
    getAutoPostingStatus,
    getUserMgtUrlBackEnd,
    getUserMgtUrl,
    setLocale,
    lang,
  } = props;
  useEffect(() => {
    getUserMgtUrl();
    getUserMgtUrlBackEnd();
    getAutoPostingStatus();
  }, []);

  if (localStorage.se8lementLang) setLocale(localStorage.se8lementLang);

  return (
    <>
      {/* <ReactNotification className="notification_system" /> */}
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Switch>
          <Route
            exact
            path='/admin/:settlementDate/query-posting-records'
            name='Generic Posting Records'
            render={(props) => (
              <GenericPostingRecords
                viewPermission={'settlement.can_view_interaffiliate_reports'}
                {...props}
              />
            )}
          />
          <Route exact path='/login' name='Login Page' component={Landing} />
          {/* <Route exact path="/login" name="Login Page" component={Login} /> */}
          <Route
            exact
            path='/register'
            name='Register Page'
            component={Register}
          />
          <Route exact path='/401' name='Page 401' component={Page401} />
          <Route exact path='/404' name='Page 404' component={Page404} />
          <Route exact path='/500' name='Page 500' component={Page500} />
          <Route path='/' name='Home' component={DefaultLayout} />
        </Switch>
      </IntlProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return { lang: state.locale.lang };
};

// TODO use compose to handle HOC react-redux patterns
const AppWithRouter = withRouter(App);
export default connect(mapStateToProps, {
  setLocale,
  getUserMgtUrl,
  getUserMgtUrlBackEnd,
  getAutoPostingStatus,
})(AppWithRouter);
