import axios from "axios";
import store from "../store";
import { AUTHORIZATION_CHECK } from "../actions/types"; // import the required action type from types.js

export const interceptor = () => {
  // Set the AUTH token for any request
  axios.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      const AUTH_TOKEN = localStorage.getItem("decodedToken");

      if (AUTH_TOKEN) {
        const TOKEN_EXP_TIME = JSON.parse(AUTH_TOKEN).exp * 1000; // extract the expiration date of the decoded token and multiply it by 1000
        const IS_TOKEN_EXPIRED = TOKEN_EXP_TIME <= Date.now(); // get todays date
        if (IS_TOKEN_EXPIRED)
          store.dispatch({
            type: AUTHORIZATION_CHECK,
            payload: IS_TOKEN_EXPIRED,
          }); // check if token has expired before any axios api request
      }

      if (token !== null) {
        config.headers.Authorization = token ? `${token}` : "";
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};
