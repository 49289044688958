import PropTypes from "prop-types";
import React from 'react'
import { FormattedMessage } from "react-intl";
const Error = ({ loading, error, data }) => {
    if (loading || data.length || !error) return null;
    return (
        <article className='text-danger d-flex justify-content-center align-items-center py-4'>
            <FormattedMessage
                id={error ? error : "Error Occured"}
                defaultMessage={error ? error : "Error Ocurred"}
            />
        </article>
    )
}

Error.propTypes = {
    data: PropTypes.shape({
        length: PropTypes.number
    }),
    error: PropTypes.string,
    loading: PropTypes.bool
}

export default Error;
