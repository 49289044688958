import "react-datepicker/dist/react-datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

import { GenericTable, Error, Loading, Pagination } from "./";

import "./Scafold.css";
const Scafold = ({
  disableTable,
  name,
  startDate,
  showDate,
  setDate,
  otherNames,
  day,
  dropdownOptions1,
  onSelectItem1,
  selectedItem1,
  dropdownOptions2,
  onSelectItem2,
  selectedItem2,
  dropdownOptions3,
  onSelectItem3,
  selectedItem3,
  submit,
  data,
  loading,
  error,
  errorMessage,
  tableHeader,
  bodyKeys,
  withSubmitButton,
  withDownloadButton,
  download,
  buttonName1,
  buttonName2,
  page,
  changePage,
  tableWidth,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <Card>
      <CardHeader
        className={"d-flex w-100 justify-content-between align-items-center"}
      >
        <strong>
          <React.Fragment>
            <FormattedMessage id={name} defaultMessage={name} /> {day}{" "}
            <FormattedMessage id={otherNames} defaultMessage={otherNames} />
          </React.Fragment>
        </strong>

        <div
          style={{ width: "70%" }}
          className={"d-flex justify-content-end align-items-center"}
        >
          <React.Fragment>
            <span className={"mx-1"}>
              {showDate && (
                <SingleDatePicker
                  showDefaultInputIcon={true}
                  date={startDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => setDate(date)} // PropTypes.func.isRequired
                  focused={focused} // PropTypes.bool
                  onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                  id="your_unique_id" // PropTypes.string.isRequired,
                  isOutsideRange={() => false}
                  numberOfMonths={1}
                />
              )}
            </span>
            {selectedItem1 && (
              <span className={"width-16"}>
                <Select
                  className={"mx-1"}
                  onChange={onSelectItem1}
                  value={selectedItem1}
                  options={dropdownOptions1}
                />
              </span>
            )}
            {selectedItem2 && (
              <span className={"width-16"}>
                <Select
                  className={"mx-1"}
                  onChange={onSelectItem2}
                  value={selectedItem2}
                  options={dropdownOptions2}
                />
              </span>
            )}
            {selectedItem3 && (
              <span className={"width-16"}>
                <Select
                  className={"mx-1"}
                  onChange={onSelectItem3}
                  value={selectedItem3}
                  options={dropdownOptions3}
                />
              </span>
            )}
            {withSubmitButton && (
              <Button
                style={{
                  padding: "9px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className={"btn-sm mx-1"}
                onClick={submit}
                color="primary"
              >
                <FormattedMessage
                  id={buttonName1}
                  defaultMessage={buttonName1}
                />
              </Button>
            )}
            {withDownloadButton && (
              <Button
                className={"btn-sm ml-1"}
                style={{ padding: "8px" }}
                color="success"
                onClick={download}
              >
                <i className="fa fa-download" />{" "}
                <FormattedMessage
                  id={buttonName2}
                  defaultMessage={buttonName2}
                />
              </Button>
            )}
          </React.Fragment>
        </div>
      </CardHeader>

      {!disableTable && (
        <CardBody>
          <Loading
            data={data ? data.content : []}
            error={error}
            loading={loading}
          />
          <GenericTable
            tableWidth={tableWidth}
            errorMessage={selectedItem1 ? `code ${selectedItem1.value}`: "" || errorMessage}
            header={tableHeader}
            bodyKeys={bodyKeys}
            data={data ? data.content : []}
            error={error}
            loading={loading}
          />
          <Error
            data={data ? data.content : []}
            error={error}
            loading={loading}
          />
          <Pagination
            data={data}
            loading={loading}
            page={page}
            onChange={changePage}
          />
        </CardBody>
      )}
    </Card>
  );
};

Scafold.propTypes = {
  bodyKeys: PropTypes.array,
  dropdownOptions1: PropTypes.array,
  dropdownOptions2: PropTypes.array,
  dropdownOptions3: PropTypes.array,
  data: PropTypes.any,
  day: PropTypes.string,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  otherNames: PropTypes.string,
  onSelectItem1: PropTypes.func,
  selectedItem1: PropTypes.shape({
    value: PropTypes.any,
  }),
  onSelectItem2: PropTypes.func,
  selectedItem2: PropTypes.shape({
    value: PropTypes.any,
  }),
  onSelectItem3: PropTypes.func,
  selectedItem3: PropTypes.shape({
    value: PropTypes.any,
  }),
  submit: PropTypes.func,
  tableHeader: PropTypes.any,
  withSubmitButton: PropTypes.bool,
  withDownloadButton: PropTypes.bool,
  showDate: PropTypes.bool,
  disableTable: PropTypes.bool,
  startDate: PropTypes.object,
  setDate: PropTypes.func,
  download: PropTypes.func,
  buttonName1: PropTypes.string,
  buttonName2: PropTypes.string,
  width1: PropTypes.string,
  tableWidth: PropTypes.string,
};

Scafold.defaultProps = {
  withSubmitButton: false,
  otherNames: "Source Item",
  tableHeader: [],
  bodyKeys: [],
  data: null,
  showDate: false,
  startDate: moment(),
  download: moment(),
  setDate: () => {},
  buttonName1: "Submit",
  buttonName2: "Download",
  tableWidth: "400%",
  name: "   ",
  withDownloadButton: false,
  disableTable: false,
};

export default Scafold;
