import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
const DropDowns = ({ children }) => {
    const [visible, setVisible] = useState(false);
    return (
        <Dropdown isOpen={visible} toggle={() => setVisible(!visible)}>
            <DropdownToggle className='toggleBtn'>
                <i className='fa fa-ellipsis-v' aria-hidden='true' />
            </DropdownToggle>
            <DropdownMenu>
                {children}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropDowns
