import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

export const GenericTable = ({
  data,
  error,
  loading,
  header,
  bodyKeys,
  errorMessage,
  tableWidth,
  urlKey,
  urlStart,
  urlEnd,
}) => {
  if (error || loading || !data) return null;

  return (
    <React.Fragment>
      {!data.length ? (
        <article className="text-danger d-flex justify-content-center align-items-center py-4">
          <FormattedMessage
            id={`No entries for this ${errorMessage}. Please select a different page ${errorMessage} and try again!`}
            defaultMessage={`No entries for this ${errorMessage}. Please select a different page ${errorMessage} and try again!`}
          />
        </article>
      ) : (
        <Table
          hover
          bordered
          striped
          responsive
          size="sm"
          style={{ minWidth: tableWidth }}
        >
          <thead className="thead-light">
            <tr>
              {header.map((value, i) => (
                <th style={{ whiteSpace: "nowrap" }} key={i}>
                  <FormattedMessage id={value} defaultMessage={value} />{" "}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {bodyKeys.map((value, key) => {
                  if (value === urlKey) {
                    return (
                      <td key={key}>
                        <Link
                          to={`${urlStart ? urlStart : ""}${item.id}${
                            urlEnd ? urlEnd : ""
                          }`}
                        >
                          {item[value]}
                        </Link>
                      </td>
                    );
                  }
                  return <td key={key}>{item[value]}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
};

GenericTable.propTypes = {
  bodyKeys: PropTypes.shape({
    map: PropTypes.func,
  }),
  data: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  error: PropTypes.string,
  urlKey: PropTypes.string,
  urlStart: PropTypes.string,
  urlEnd: PropTypes.string,
  errorMessage: PropTypes.string,
  header: PropTypes.shape({
    map: PropTypes.func,
  }),
  loading: PropTypes.bool,
  tableWidth: PropTypes.string,
};

GenericTable.defaultProps = {
  errorMessage: "date",
  tableWidth: "400%",
};
