import {
  INTERSWITCH_SUMMARY_LOADING,
  INTERSWITCH_SUMMARY_SUCCESS,
  INTERSWITCH_SUMMARY_ERROR,
  POSTING_SUMMARY_LOADING,
  POSTING_SUMMARY_SUCCESS,
  POSTING_SUMMARY_ERROR,
  INTERSWITCH_DETAIL_LOADING,
  INTERSWITCH_DETAIL_SUCCESS,
  INTERSWITCH_DETAIL_ERROR,
  POSTING_ENTRIES_LOADING,
  POSTING_ENTRIES_SUCCESS,
  POSTING_ENTRIES_ERROR,
  INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_LOADING,
  INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_SUCCESS,
  INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_ERROR,
  INTERSWITCH_DAILY_COMPUTATION_LOADING,
  INTERSWITCH_DAILY_COMPUTATION_SUCCESS,
  INTERSWITCH_DAILY_COMPUTATION_ERROR,
  INTERSWITCH_CHANNEL_LOADING,
  INTERSWITCH_CHANNEL_SUCCESS,
  INTERSWITCH_CHANNEL_ERROR,
  INTERSWITCH_PRODUCTS_LOADING,
  INTERSWITCH_PRODUCTS_SUCCESS,
  INTERSWITCH_PRODUCTS_ERROR,
  INTERSWITCH_DETAIL_BY_ID_LOADING,
  INTERSWITCH_DETAIL_BY_ID_SUCCESS,
  INTERSWITCH_DETAIL_BY_ID_ERROR,
  INTERSWITCH_FILE_SPLITTER_ERROR,
  INTERSWITCH_FILE_SPLITTER_LOADING,
  INTERSWITCH_FILE_SPLITTER_SUCCESS,
  ADD_FILE_SPLITTER_CONFIG_LOADING,
  ADD_FILE_SPLITTER_CONFIG_SUCCESS,
  ADD_FILE_SPLITTER_CONFIG_FAILURE,
} from "../actions/types";

const initialState = {
  isLoading: false,
  interswitchSummaryResult: null,
  interswitchSummaryerror: null,
  postingSummaryResult: null,
  postingSummaryError: null,
  interswitchDetailLoading: false,
  interswitchDetailResult: null,
  interswitchDetailError: null,
  postingEntriesLoading: false,
  postingEntriesResult: null,
  postingEntriesError: null,
  interswitchDailyComprehensiveComputationLoading: false,
  interswitchDailyComprehensiveComputationResult: null,
  interswitchDailyComprehensiveComputationError: null,
  interswitchComputationLoading: false,
  interswitchComputationResult: null,
  interswitchComputationError: null,
  interswitchChannelLoading: false,
  interswitchChannelResult: null,
  interswitchChannelError: null,
  interswitchProductsLoading: false,
  interswitchProductsResult: null,
  interswitchProductsError: null,
  interswitchTransaction: null,
  interswitchTransactionError: null,
  interswitchFileSplitterLoading: false,
  interswitchFileSplitterSuccess: null,
  interswitchFileSplitterError: null,
  addFileSplitterConfigLoading: false,
  addFileSplitterConfigFailure: null,
  interswitchConfig: [],
};

const interswitchReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERSWITCH_SUMMARY_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case INTERSWITCH_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interswitchSummaryResult: action.payload,
        interswitchSummaryerror: null,
      };

    case INTERSWITCH_SUMMARY_ERROR:
      return {
        ...state,
        isLoading: false,
        interswitchSummaryerror: action.payload,
      };
    //   posting summary
    case POSTING_SUMMARY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case POSTING_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postingSummaryResult: action.payload,
      };
    case POSTING_SUMMARY_ERROR:
      return {
        ...state,
        isLoading: false,
        postingSummaryError: action.payload,
      };
    //   interswitch details
    case INTERSWITCH_DETAIL_LOADING:
      return {
        ...state,
        interswitchDetailLoading: true,
      };
    case INTERSWITCH_DETAIL_SUCCESS:
      return {
        ...state,
        interswitchDetailLoading: false,
        interswitchDetailResult: action.payload,
      };
    case INTERSWITCH_DETAIL_ERROR:
      return {
        ...state,
        interswitchDetailLoading: false,
        interswitchDetailError: action.payload,
      };

    case INTERSWITCH_DETAIL_BY_ID_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case INTERSWITCH_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interswitchTransaction: action.payload,
      };
    case INTERSWITCH_DETAIL_BY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        interswitchTransactionError: action.payload,
      };

    // Posting Entries
    case POSTING_ENTRIES_LOADING:
      return {
        ...state,
        postingEntriesLoading: true,
        postingEntriesResult: null,
      };

    case POSTING_ENTRIES_SUCCESS:
      return {
        ...state,
        postingEntriesLoading: false,
        postingEntriesResult: action.payload,
      };

    case POSTING_ENTRIES_ERROR:
      return {
        ...state,
        postingEntriesLoading: false,
        postingEntriesError: action.payload,
      };

    // Interswitch Summary Daily Comprehensive Computation

    case INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_LOADING:
      return {
        ...state,
        interswitchDailyComprehensiveComputationLoading: true,
        interswitchDailyComprehensiveComputationResult: null,
      };

    case INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_SUCCESS:
      return {
        ...state,
        interswitchDailyComprehensiveComputationLoading: false,
        interswitchDailyComprehensiveComputationResult: action.payload,
      };

    case INTERSWITCH_DAILY_COMPREHENSIVE_COMPUTATION_ERROR:
      return {
        ...state,
        interswitchDailyComprehensiveComputationLoading: false,
        interswitchDailyComprehensiveComputationError: action.payload,
      };

    // Interswitch  Daily  Computation

    case INTERSWITCH_DAILY_COMPUTATION_LOADING:
      return {
        ...state,
        interswitchDailyComputationLoading: true,
        interswitchDailyComputationResult: null,
      };

    case INTERSWITCH_DAILY_COMPUTATION_SUCCESS:
      return {
        ...state,
        interswitchDailyComputationLoading: false,
        interswitchDailyComputationResult: action.payload,
      };

    case INTERSWITCH_DAILY_COMPUTATION_ERROR:
      return {
        ...state,
        interswitchDailyComputationLoading: false,
        interswitchDailyComputationError: action.payload,
      };

    // Interswitch  Channel

    case INTERSWITCH_CHANNEL_LOADING:
      return {
        ...state,
        interswitchChannelLoading: true,
        interswitchChannelResult: null,
      };

    case INTERSWITCH_CHANNEL_SUCCESS:
      return {
        ...state,
        interswitchChannelLoading: false,
        interswitchChannelResult: action.payload,
      };

    case INTERSWITCH_CHANNEL_ERROR:
      return {
        ...state,
        interswitchChannelLoading: false,
        interswitchChannelError: action.payload,
      };

    // INTERSWITCH PRODUCTS
    case INTERSWITCH_PRODUCTS_LOADING:
      return {
        ...state,
        interswitchProductsLoading: true,
        interswitchProductsResult: null,
      };

    case INTERSWITCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        interswitchProductsLoading: false,
        interswitchProductsResult: action.payload,
      };

    case INTERSWITCH_PRODUCTS_ERROR:
      return {
        ...state,
        interswitchProductsLoading: false,
        interswitchProductsError: action.payload,
      };

    case INTERSWITCH_FILE_SPLITTER_LOADING:
      return {
        ...state,
        interswitchFileSplitterLoading: true,
        interswitchFileSplitterSuccess: null,
      };

    case INTERSWITCH_FILE_SPLITTER_SUCCESS:
      return {
        ...state,
        interswitchFileSplitterLoading: false,
        interswitchFileSplitterSuccess: action.payload,
      };

    case INTERSWITCH_FILE_SPLITTER_ERROR:
      return {
        ...state,
        interswitchFileSplitterLoading: false,
        interswitchFileSplitterError: action.payload,
      };

    //Add FIle Splitter Config

    case ADD_FILE_SPLITTER_CONFIG_LOADING:
      return {
        ...state,
        addFileSplitterConfigLoading: true,
        addFileSplitterConfigSuccess: null,
      };

    case ADD_FILE_SPLITTER_CONFIG_SUCCESS:
      return {
        ...state,
        addFileSplitterConfigLoading: false,
        interswitchConfig: [...state.interswitchConfig, action.payload],
      };

    case ADD_FILE_SPLITTER_CONFIG_FAILURE:
      return {
        ...state,
        addFileSplitterConfigFailure: action.payload,
      };

    default:
      return state;
  }
};

export default interswitchReducer;
