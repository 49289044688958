import axios from "axios";
import { encryptFilename } from "../Utils/utilityFunctions";
import {
  BASE_URL,
  INTER_AFFILIATE_DOWNLOAD_FILENAME,
  MASTERCARD_FILENAME,
  GIM_FILENAME,
  MASTERPASS_FILENAME,
  ECOMMERCE_FILENAME,
  XPRESSCASH_FILENAME,
  CUP_FILENAME,
  MVISA_FILENAME,
  NSA_FILENAME,
  GET_FILE_SETS,
  GET_FILE_SETS_ERROR,
  CREATE_FILE_SET,
  CREATE_FILE_SET_ERROR,
  UPDATE_FILE_SET,
  UPDATE_FILE_SET_ERROR,
  DELETE_FILE_SET,
  DELETE_FILE_SET_ERROR,
  GET_FILE_SETS_PAGINATED,
  GET_FILE_SETS_PAGINATED_ERROR,
  GET_FILE_SET,
  GET_FILE_SET_ERROR,
  GET_FILE_SET_DEFINITIONS,
  GET_FILE_SET_DEFINITIONS_ERROR,
  ADD_FILE_DEFINITIONS_TO_FILESET,
  ADD_FILE_DEFINITIONS_TO_FILESET_ERROR,
  DELETE_FILE_SET_FILE_DEFINITION,
  DELETE_FILE_SET_FILE_DEFINITION_ERROR,
  GET_FILE_SET_BATCHES_PAGINATED,
  GET_FILE_SET_BATCHES_PAGINATED_ERROR,
  GET_FILE_SET_DEFINITION_BATCHES_PAGINATED,
  GET_FILE_SET_DEFINITION_BATCHES_PAGINATED_ERROR,
  GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED,
  GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR,
  GET_FILESET_BATCH_DETAIL,
  GET_FILESET_BATCH_DETAIL_ERROR,
  GET_FILESET_BATCH_DEFINITION_DETAIL,
  GET_FILESET_BATCH_DEFINITION_DETAIL_ERROR,
  GET_FILESET_BATCHES_SUMMARY,
  GET_FILESET_BATCHES_SUMMARY_ERROR,
  GET_FILE_SET_STATUS,
  GET_FILE_SET_STATUS_ERROR,
  GET_GIM_POSTING_SUMMARIES,
  GET_GIM_POSTING_SUMMARIES_ERROR,
  GET_GIM_SUMMARY_DETAIL,
  GET_GIM_SUMMARY_DETAIL_ERROR,
  GET_GIM_POSTING_DETAIL,
  GET_GIM_POSTING_DETAIL_ERROR,
  GET_GIM_DETAIL_FILES,
  GET_GIM_DETAIL_FILES_ERROR,
  GET_GIM_FILE_CONTENT,
  GET_GIM_FILE_CONTENT_ERROR,
  GET_GIM_FILE_SUMMARY,
  GET_GIM_FILE_SUMMARY_ERROR,
  GET_T112S,
  GET_T112S_ERROR,
  GET_T464S,
  GET_T464S_ERROR,
  GET_ADVISEMENT,
  GET_ADVISEMENT_ERROR,
  GET_T140S,
  GET_T140S_ERROR,
  GET_T464_TRANSACTIONS,
  GET_T464_TRANSACTIONS_ERROR,
  GET_T112_TRANSACTIONS,
  GET_T112_TRANSACTIONS_ERROR,
  GET_T112_SUMMARY,
  GET_T112_SUMMARY_ERROR,
  GET_T112_SUMMARY_LOADING,
  GET_T464_SUMMARY,
  GET_T464_SUMMARY_ERROR,
  GET_T461S,
  GET_T461S_ERROR,
  GET_T461_TRANSACTIONS,
  GET_T461_TRANSACTIONS_ERROR,
  GET_T140S_TRANSACTIONS,
  GET_T140S_TRANSACTIONS_LOADING,
  GET_INTERAFILIATE_ISSUERS,
  GET_INTERAFILIATE_ISSUERS_ERROR,
  GET_INTERAFILIATE_ACQUIRER,
  GET_INTERAFILIATE_ACQUIRER_ERROR,
  GET_ISSUER_TRANSACTIONS,
  GET_ISSUER_TRANSACTIONS_ERROR,
  GET_ACQUIRER_TRANSACTIONS,
  GET_ACQUIRER_TRANSACTIONS_ERROR,
  GET_VISA_ACQUIRER,
  GET_VISA_ACQUIRER_ERROR,
  GET_VISA_ACQUIRER_TRANSACTIONS,
  GET_VISA_ACQUIRER_TRANSACTIONS_ERROR,
  GET_VISA_OCT_TRANSACTIONS,
  GET_VISA_OCT_TRANSACTIONS_ERROR,
  GET_VISA_ISSUER,
  GET_VISA_ISSUER_ERROR,
  GET_VISA_OCT,
  GET_VISA_OCT_ERROR,
  GET_VISA_ISSUER_TRANSACTIONS,
  GET_VISA_ISSUER_TRANSACTIONS_ERROR,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_ERROR,
  GET_INTERAFFILIATE_ISSUER_SUMMARY,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SUMMARY,
  GET_INTERAFFILIATE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS,
  GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS_ERROR,
  GET_VISA_ACQUIRER_SUMMARY,
  GET_VISA_ACQUIRER_SUMMARY_ERROR,
  GET_VISA_ISSUER_SUMMARY,
  GET_VISA_ISSUER_SUMMARY_ERROR,
  GET_VISA_ISSUER_SUMMARY_STATS,
  GET_VISA_ISSUER_SUMMARY_STATS_ERROR,
  GET_T112_SUMMARY_STATS,
  GET_T112_SUMMARY_STATS_ERROR,
  GET_T464_SUMMARY_STATS,
  GET_t464_SUMMARY_STATS_ERROR,
  GET_VISA_ACQUIRER_SUMMARY_STATS,
  GET_VISA_ACQUIRER_SUMMARY_STATS_ERROR,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS,
  GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS_ERROR,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS,
  GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS_ERROR,
  GET_VISA_SUMMARY,
  GET_VISA_SUMMARY_ERROR,
  GET_VISA_POSTING_SUMMARY,
  GET_VISA_POSTING_SUMMARY_ERROR,
  GET_VISA_POSTING_SUMMARY_LOADING,
  GET_INTERAFFILIATE_POSTING_SUMMARY,
  GET_INTERAFFILIATE_POSTING_SUMMARY_ERROR,
  GET_ADVISEMENT_DETAIL,
  GET_ADVISEMENT_DETAIL_ERROR,
  GET_ALL_VSS,
  GET_ALL_VSS_ERROR,
  GET_VSS_110,
  GET_VSS_110_ERROR,
  GET_VSS_110_SUMMARY,
  GET_VSS_110_SUMMARY_ERROR,
  GET_VSS_115,
  GET_VSS_115_ERROR,
  GET_VSS_115_SUMMARY,
  GET_VSS_115_SUMMARY_ERROR,
  GET_VSS_116,
  GET_VSS_116_SUMMARY,
  GET_VSS_116_SUMMARY_ERROR,
  GET_VSS_116_ERROR,
  GET_VSS_120,
  GET_VSS_120_ERROR,
  GET_VSS_120_SUMMARY,
  GET_VSS_120_SUMMARY_ERROR,
  GET_VSS_300,
  GET_VSS_300_ERROR,
  GET_VSS_300_SUMMARY,
  GET_VSS_300_SUMMARY_ERROR,
  GET_VSS_900,
  GET_VSS_900_ERROR,
  GET_VSS_900_SUMMARY,
  GET_VSS_900_SUMMARY_ERROR,
  GET_INTERSWITCH_COMPUTATIONS,
  GET_INTERSWITCH_COMPUTATIONS_ERROR,
  GET_DAILY_INTERSWITCH_SUMMARY,
  GET_DAILY_INTERSWITCH_SUMMARY_ERROR,
  GET_VISA_POSTING_OVERVIEW,
  GET_VISA_POSTING_OVERVIEW_ERROR,
  GET_INTERAFFILIATE_POSTING_OVERVIEW,
  GET_INTERAFFILIATE_POSTING_OVERVIEW_ERROR,
  GET_VISA_POSTING_OVERVIEW_LOADING,
  SEND_SELECTED_AFFILIATE_CODE,
  GET_VISA_ISSUER_TRANSACTIONS_LOADING,
  DOWNLOADING_STATUS,
  VISA_ISSUER_TRANSACTIONS_UPDATE,
  VISA_ACQUIRER_TRANSACTIONS_UPDATE,
  GET_VISA_ACQUIRER_TRANSACTIONS_LOADING,
  VISA_ACQUIRER_TRANSACTIONS_UPDATE_ERROR,
  GET_VISA_OCT_TRANSACTIONS_LOADING,
  GET_POSTILION_SUMMARY,
  GET_POSTILION_SUMMARY_ERROR,
  GET_FLEXCUBE_SUMMARY,
  GET_FLEXCUBE_SUMMARY_ERROR,
  GET_POS_ACQUIRERS_SUMMARY,
  GET_POS_ACQUIRERS_SUMMARY_ERROR,
  GET_POSTILION_SUMMARY_ITEM,
  GET_POSTILION_SUMMARY_ITEM_ERROR,
  GET_FLEXCUBE_SUMMARY_ITEM,
  GET_FLEXCUBE_SUMMARY_ITEM_ERROR,
  GET_POSACQUIRERS_SUMMARY_ITEM,
  GET_POSACQUIRERS_SUMMARY_ITEM_ERROR,
  GET_POSTILION_SUMMARY_SEARCH,
  GET_FLEXCUBE_SUMMARY_SEARCH,
  GET_POSTILION_SUMMARY_SEARCH_ERROR,
  GET_FLEXCUBE_SUMMARY_SEARCH_ERROR,
  VISA_ISSUER_POSTING_REPORT_SUMMARY,
  VISA_ISSUER_POSTING_REPORT_SUMMARY_ERROR,
  VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES,
  VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
  ALL_POSTING_REPORT_SUMMARY,
  ALL_POSTING_REPORT_SUMMARY_ERROR,
  VISA_ACQUIRER_POSTING_REPORT_SUMMARY_ERROR,
  VISA_ACQUIRER_POSTING_REPORT_SUMMARY,
  VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES,
  VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
  DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM,
  DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM_ERROR,
  DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM,
  DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM_ERROR,
  DELETE_VISA_POSTING_SUMMARY_ITEM,
  DELETE_VISA_POSTING_SUMMARY_ITEM_ERROR,
  CONFIRM_DELETE,
  CANCEL_DELETE,
  GET_T464_TRANSACTIONS_LOADING,
  GET_T112_TRANSACTIONS_LOADING,
  GET_T464_SUMMARY_LOADING,
  GET_T461_TRANSACTIONS_LOADING,
  GET_ADVISEMENT_SUMMARY_ERROR,
  GET_ADVISEMENT_SUMMARY,
  GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM,
  GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM_ERROR,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL,
  GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL_ERROR,
  MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS,
  GET_INTERAFFILIATE_SOURCE_SUMMARY,
  GET_INTERAFFILIATE_SOURCE_SUMMARY_ERROR,
  GET_INTERAFFILIATE_SOURCE_ITEM,
  GET_INTERAFFILIATE_SOURCE_ITEM_ERROR,
  GET_INTERAFFILIATE_GROSS_SETTLEMENT,
  GET_INTERAFFILIATE_GROSS_SETTLEMENT_ERROR,
  GET_INTERAFFILIATE_REGISTER_SETTLEMENT,
  GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR,
  GET_INTERAFFILIATE_SUMM_SETTLEMENT,
  GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR,
  GET_SETTLEMENT_BREAKDOWN,
  GET_SETTLEMENT_BREAKDOWN_ERROR,
  GET_SETTLEMENT_REPORT_ERROR,
  GET_SETTLEMENT_REPORT,
  GET_POSTING_REPORT_ERROR,
  GET_POSTING_REPORT,
  GET_AFFILIATE_ISSUER_SOURCE_ITEMS,
  GET_AFFILIATE_ISSUER_SOURCE_ITEMS_ERROR,
  GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS,
  GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS_ERROR,
  GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY,
  GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY_ERROR,
  GET_SUM_SETTLEMENT_FEES,
  GET_SUM_SETTLEMENT_FEES_ERROR,
  GET_ISSUER_AUDIT,
  GET_ISSUER_AUDIT_ERROR,
  GET_ACQUIRER_AUDIT,
  GET_ACQUIRER_AUDIT_ERROR,
  GET_VISA_POSTING_DETAILS_ERROR,
  GET_VISA_POSTING_DETAILS,
  GET_INTERAFFILIATE_POSTING,
  GET_INTERAFFILIATE_POSTING_ERROR,
  MASTERCARD_POSTING,
  MASTERCARD_POSTING_ERROR,
  MASTERCARD_POSTING_DETAILS,
  MASTERCARD_POSTING_DETAILS_ERROR,
  GET_GENERIC_POSTING_RECORDS,
  GET_GENERIC_POSTING_RECORDS_ERROR,
  DELETE_POS_CONTENT_ERROR,
  DELETE_POS_CONTENT,
  DELETE_POSTILION_CONTENT_ERROR,
  DELETE_POSTILION_CONTENT,
  OPEN_ALERT,
  INTER_AFFILIATE_FILENAME,
  VISA_FILENAME,
  FETCH_POSTING_ENTRY_META,
  FETCH_POSTING_ENTRY_META_ERROR,
  FETCH_AUDIT_LOGS,
  FETCH_AUDIT_LOGS_ERROR,
  DELETE_FLEXCUBE_CONTENT,
  DELETE_FLEXCUBE_CONTENT_ERROR,
  INTER_AFFILIATE_QUERY_DETAILS,
  INTER_AFFILIATE_QUERY_DETAILS_ERROR,
  GET_AUTO_POSTING_STATUS,
  GET_AUTO_POSTING_STATUS_ERROR,
  UPDATE_AUTO_POSTING_STATUS,
  UPDATE_AUTO_POSTING_STATUS_ERROR,
  DELETE_INTERAFFILIATE_CHANNEL_LOG,
  DELETE_INTERAFFILIATE_CHANNEL_LOG_ERROR,
  DIS_MISS_ERROR,
  GET_QUERY_DETAILS,
  GET_QUERY_DETAILS_ERROR,
  GET_ALL_XPRESSCASH_SUMMARY_FAILURE,
  GET_ALL_XPRESSCASH_SUMMARY_SUCCESS,
  GET_ALL_XPRESSCASH_SUMMARY_START,
  GET_ALL_XPRESSCASH_DETAILS_START,
  GET_ALL_XPRESSCASH_DETAILS_SUCCESS,
  GET_ALL_XPRESSCASH_DETAILS_FAILURE,
  GET_ALL_XPRESSCASH_ACQUIRER_START,
  GET_ALL_XPRESSCASH_ACQUIRER_SUCCESS,
  GET_ALL_XPRESSCASH_ACQUIRER_FAILURE,
  GET_ALL_XPRESSCASH_ISSUER_START,
  GET_ALL_XPRESSCASH_ISSUER_SUCCESS,
  GET_ALL_XPRESSCASH_ISSUER_FAILURE,
  GET_XPRESSCASH_SETTLEMENT_REPORT_START,
  GET_XPRESSCASH_SETTLEMENT_REPORT_SUCCESS,
  GET_XPRESSCASH_SETTLEMENT_REPORT_FAILURE,
  GET_XPRESSCASH_POSTING_REPORT_START,
  GET_XPRESSCASH_POSTING_REPORT_SUCCESS,
  GET_XPRESSCASH_POSTING_REPORT_FAILURE,
  GET_XPRESSCASH_FEES_REPORT_START,
  GET_XPRESSCASH_FEES_REPORT_SUCCESS,
  GET_XPRESSCASH_FEES_REPORT_FAILURE,
  GET_XPRESSCASH_FEES_SUMM_START,
  GET_XPRESSCASH_FEES_SUMM_SUCCESS,
  GET_XPRESSCASH_FEES_SUMM_FAILURE,
  GET_XPRESSCASH_AQUIRER_REPORT_START,
  GET_XPRESSCASH_AQUIRER_REPORT_SUCCESS,
  GET_XPRESSCASH_AQUIRER_REPORT_FAILURE,
  GET_XPRESSCASH_ISSUER_REPORT_START,
  GET_XPRESSCASH_ISSUER_REPORT_SUCCESS,
  GET_XPRESSCASH_ISSUER_REPORT_FAILURE,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_START,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_XPRESSCASH_SINGLE_SUMMARY_FAILURE,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_START,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_SUCCESS,
  GET_ALL_XPRESSCASH_QUERY_DETAILS_FAILURE,
  GET_ALL_MVISA_SUMMARY_START,
  GET_ALL_MVISA_SUMMARY_SUCCESS,
  GET_ALL_MVISA_SUMMARY_FAILURE,
  GET_ALL_MVISA_DETAILS_START,
  GET_ALL_MVISA_DETAILS_SUCCESS,
  GET_ALL_MVISA_DETAILS_FAILURE,
  GET_ALL_MVISA_QUERY_DETAILS_START,
  GET_ALL_MVISA_QUERY_DETAILS_SUCCESS,
  GET_ALL_MVISA_QUERY_DETAILS_FAILURE,
  // CHINA PAY
  GET_CHINAPAY_ACQUIRER_FILE_SUMMARY,
  GET_CHINAPAY_ACQUIRER_FILE_SUMMARY_ERROR,
  GET_CHINAPAY_ACQUIRER_FILE_DETAILS,
  GET_CHINAPAY_ACQUIRER_FILE_DETAILS_ERROR,
  GET_CHINAPAY_SUMMARY,
  GET_CHINAPAY_SUMMARY_ERROR,
  GET_CHINAPAY_SUMMARY_DETAILS,
  GET_CHINAPAY_SUMMARY_DETAILS_ERROR,
  GET_CHINAPAY_POSTING_SUMMARY,
  GET_CHINAPAY_POSTING_SUMMARY_ERROR,
  GET_CHINAPAY_POSTING_DETAILS,
  GET_CHINAPAY_POSTING_DETAILS_ERROR,
  GET_CHINAPAY_POSTING_ENTRIES,
  GET_CHINAPAY_POSTING_ENTRIES_ERROR,
  CHINAPAY_LOADING,
  // MASTERPASS
  GET_MASTERPASS_SUMMARIES,
  GET_MASTERPASS_SUMMARIES_ERROR,
  GET_MASTERPASS_DETAILS,
  GET_MASTERPASS_DETAILS_ERROR,
  GET_MASTERPASS_ACQUIRERS,
  GET_MASTERPASS_ACQUIRERS_ERROR,
  GET_MASTERPASS_ISSUERS,
  GET_MASTERPASS_ISSUERS_ERROR,
  GET_MASTERPASS_SUMMARY,
  GET_MASTERPASS_SUMMARY_ERROR,
  GET_MASTERPASS_SETTLEMENT_REPORT,
  GET_MASTERPASS_SETTLEMENT_REPORT_ERROR,
  GET_MASTERPASS_POSTING_REPORT,
  GET_MASTERPASS_POSTING_REPORT_ERROR,
  GET_MASTERPASS_SETTLEMENT_FEES_REPORT,
  GET_MASTERPASS_SETTLEMENT_FEES_REPORT_ERROR,
  GET_MASTERPASS_SUMM_SETTLEMENT_REPORT,
  GET_MASTERPASS_SUMM_SETTLEMENT_REPORT_ERROR,
  GET_MASTERPASS_ACQUIRER_REPORT,
  GET_MASTERPASS_ACQUIRER_REPORT_ERROR,
  GET_MASTERPASS_ISSUER_REPORT,
  GET_MASTERPASS_ISSUER_REPORT_ERROR,
  MASTERPASS_LOADING,
  GET_MASTERPASS_QUERY_DETAILS,
  GET_MASTERPASS_QUERY_DETAILS_ERROR,
  GET_ALL_MVISA_ACQUIRER_START,
  GET_ALL_MVISA_ACQUIRER_SUCCESS,
  GET_ALL_MVISA_ACQUIRER_FAILURE,
  GET_ALL_MVISA_ISSUER_START,
  GET_ALL_MVISA_ISSUER_SUCCESS,
  GET_ALL_MVISA_ISSUER_FAILURE,
  GET_ALL_MVISA_SINGLE_SUMMARY_START,
  GET_ALL_MVISA_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_MVISA_SINGLE_SUMMARY_FAILURE,
  GET_MVISA_SUM_REPORT_START,
  GET_MVISA_SUM_REPORT_SUCCESS,
  GET_MVISA_SUM_REPORT_FAILURE,
  GET_XPRESSCASH_MVISA_REPORT_START,
  GET_XPRESSCASH_MVISA_REPORT_SUCCESS,
  GET_XPRESSCASH_MVISA_REPORT_FAILURE,
  GET_MVISA_POSTING_REPORT_START,
  GET_MVISA_POSTING_REPORT_SUCCESS,
  GET_MVISA_POSTING_REPORT_FAILURE,
  GET_MVISA_FEES_REPORT_START,
  GET_MVISA_FEES_REPORT_SUCCESS,
  GET_MVISA_FEES_REPORT_FAILURE,
  GET_MVISA_ACQUIRER_REPORT_START,
  GET_MVISA_ACQUIRER_REPORT_SUCCESS,
  GET_MVISA_ACQUIRER_REPORT_FAILURE,
  GET_MVISA_ISSUER_REPORT_START,
  GET_MVISA_ISSUER_REPORT_SUCCESS,
  GET_MVISA_ISSUER_REPORT_FAILURE,
  DELETE_POSTING_LOG_ID_AND_RECORDS,
  DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR,
  DELETE_CHANNEL_LOG,
  DELETE_CHANNEL_LOG_ERROR,
  FETCH_ALL_ECOMMERCE_SCHEME,
  FETCH_ALL_ECOMMERCE_SCHEME_ERROR,
  ECOMMERCE_DETAILS,
  ECOMMERCE_DETAILS_ERROR,
  ECOMMERCE_ISSUER_DETAILS,
  ECOMMERCE_ISSUER_DETAILS_ERROR,
  ECOMMERCE_ACQUIRER_DETAILS,
  ECOMMERCE_ACQUIRER_DETAILS_ERROR,
  GET_ECOMMERCE_SETTLEMENT_REPORT_START,
  GET_ECOMMERCE_SETTLEMENT_REPORT_SUCCESS,
  GET_ECOMMERCE_SETTLEMENT_REPORT_FAILURE,
  GET_ECOMMERCE_POSTING_REPORT_START,
  GET_ECOMMERCE_POSTING_REPORT_SUCCESS,
  GET_ECOMMERCE_POSTING_REPORT_FAILURE,
  GET_ECOMMERCE_FEES_REPORT_START,
  GET_ECOMMERCE_FEES_REPORT_SUCCESS,
  GET_ECOMMERCE_FEES_REPORT_FAILURE,
  GET_ECOMMERCE_SUMM_REPORT_START,
  GET_ECOMMERCE_SUMM_REPORT_SUCCESS,
  GET_ECOMMERCE_SUMM_REPORT_FAILURE,
  GET_ECOMMERCE_AQUIRER_REPORT_START,
  GET_ECOMMERCE_AQUIRER_REPORT_SUCCESS,
  GET_ECOMMERCE_AQUIRER_REPORT_FAILURE,
  GET_ECOMMERCE_ISSUER_REPORT_START,
  GET_ECOMMERCE_ISSUER_REPORT_SUCCESS,
  GET_ECOMMERCE_ISSUER_REPORT_FAILURE,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_START,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_SUCCESS,
  GET_ALL_ECOMMERCE_SINGLE_SUMMARY_FAILURE,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_START,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_SUCCESS,
  GET_ALL_ECOMMERCE_QUERY_DETAILS_FAILURE,
  CLEAR_VISA_ALERT_MESSAGES,
  GET_NOSTRO_REPORTS,
  GET_NOSTRO_REPORTS_ERROR,
  GET_NOSTRO_REPORTS_LOADING,
  TRIGGER_POSTING_ERROR,
  TRIGGER_POSTING,
  RESET_TRIGGER_RESPONSE,
  GET_MASTERCARD_RESOLVED_FILES,
  GET_MASTERCARD_RESOLVED_FILES_FAILURE,
  CLEAR_RESOLVED_FILES_MESSAGE,
  POSTING_DIFFERENCE_REPORT,
  POSTING_DIFFERENCE_REPORT_ERROR,
  POSTING_DIFFERENCE_REPORT_LOADING,
} from "./types";

import { itExists } from "../Utils/utilityFunctions";
import moment from "moment";
import { showLoading, hideLoading } from "react-redux-loading-bar"; // shows and hide the github like loader whe async action is in progress
// inter affiliate name constant for download
import { interceptor } from "./interceptor";

interceptor();

export const sumbitDateAction = () => (dispatch) => {
  dispatch({
    type: GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR,
    data: false,
  });
};

export const sumbitSummDateAction = () => (dispatch) => {
  dispatch({
    type: GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR,
    data: false,
  });
};

export const cancelDeleteFunc = () => (dispatch) => {
  dispatch({
    type: CANCEL_DELETE,
    payload: { data: false },
  });
};

export const confirmDelete = (item, itemName) => (dispatch) => {
  // console.log("item", item, itemName);
  dispatch({
    type: CONFIRM_DELETE,
    payload: { data: item, itemName: itemName },
  });
};

export const confirmDeleteIssuer = (item, itemName) => (dispatch) => {
  dispatch({
    type: CONFIRM_DELETE,
    payload: { data: item, itemName: itemName },
  });
};

export const confirmDeleteAcquirer = (item, itemName) => (dispatch) => {
  dispatch({
    type: CONFIRM_DELETE,
    payload: { data: item, itemName: itemName },
  });
};

export const getAutoPostingStatus = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/app-settings`,
  })
    .then((response) => {
      localStorage.setItem(
        "manualPostingStatus",
        JSON.stringify(response.data)
      ); // because you cannot store obj in localStorage directly
      dispatch({
        type: GET_AUTO_POSTING_STATUS,
        payload: response,
      });
    })
    .catch((err) => {
      const ErrorObj = {
        message: "Error Unable to load manual posting settings!",
      };
      localStorage.setItem(
        "manualPostingStatusError",
        JSON.stringify(ErrorObj)
      ); // because you cannot store obj in localStorage directly the response object is not easily predictable so a stringified object holding the custom error message is necessary.
      dispatch({
        type: GET_AUTO_POSTING_STATUS_ERROR,
        payload: err.response,
      });
    });
};

export const approvePostingFn = (status, logID) => (dispatch) => {
  axios
    .post(`${BASE_URL}/affiliates/posting-log/${logID}?approved=${status}`)
    .then((response) => {
      dispatch({
        type: UPDATE_AUTO_POSTING_STATUS,
        payload: response,
      });
    })
    .catch((err) =>
      dispatch({
        type: UPDATE_AUTO_POSTING_STATUS_ERROR,
        payload: err.response,
      })
    );
};

export const fetchPostingEntryMetaFn = (postingLogID) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/admin/posting-logs?posting-log-id=${postingLogID}`
    );
    dispatch({
      type: FETCH_POSTING_ENTRY_META,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: FETCH_POSTING_ENTRY_META_ERROR,
      payload: error.response,
    });
  }
};

export const deleteVisaPostingSummaryItem = (file) => (dispatch) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(file.filename)}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_VISA_POSTING_SUMMARY_ITEM,
        payload: { data: file.id },
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_VISA_POSTING_SUMMARY_ITEM_ERROR,
        payload: error,
      });
    });
};

export const deleteAcquirerPostingSummaryItem = (file) => (dispatch) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(file.filename)}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM,
        payload: { data: file.filename },
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_VISA_ACQUIRER_POSTING_SUMMARY_ITEM_ERROR,
        payload: error.response
          ? error.response.data.message
          : "An error occured",
      });
    });
};

export const deleteIssuerPostingSummaryItem = (file) => (dispath) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(file.filename)}`
    )
    .then((response) => {
      dispath({
        type: DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM,
        payload: { data: file.filename },
      });
    })
    .catch((error) => {
      dispath({
        type: DELETE_VISA_ISSUER_POSTING_SUMMARY_ITEM_ERROR,
        payload: error.response
          ? error.response.data.message
          : "An error occured",
      });
    });
};
export const clearVisaAlertMessages = () => (dispatch) => {
  dispatch({
    type: CLEAR_VISA_ALERT_MESSAGES,
  });
};
export const fetchVisaIssuerPostingSummaryValidated =
  (batchDate, headerConfig) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader.
    axios({
      method: "get",
      url: `${BASE_URL}/visa/issuers/${batchDate}/upload`,
      headers: { affiliateCode: headerConfig },
    })
      .then((response) => {
        dispatch({
          type: VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: VISA_ISSUER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchVisaAcquirerPostingSummaryValidated =
  (batchDate, headerConfig) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader.
    axios({
      method: "get",
      headers: { affiliateCode: headerConfig },
      url: `${BASE_URL}/visa/acquirers/${batchDate}/upload`,
    })
      .then((response) => {
        dispatch({
          type: VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: VISA_ACQUIRER_POSTING_SUMMARY_VALIDATED_ENTRIES_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchAllPostingReportSummary =
  (batchDate, headerConfig) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader.
    axios({
      method: "GET",
      headers: { affiliateCode: headerConfig },
      url: `${BASE_URL}/posting-summary/visa?batchDate=${batchDate}`,
    })
      .then((response) => {
        //2019-11-09
        dispatch({
          type: ALL_POSTING_REPORT_SUMMARY,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: ALL_POSTING_REPORT_SUMMARY_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchVisaAcquirerPostingReportSummary =
  (batchDate) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader.
    axios
      .get(`${BASE_URL}/visa/acquirers/${batchDate}/posting-report/`)
      .then((response) => {
        //2019-11-09
        dispatch({
          type: VISA_ACQUIRER_POSTING_REPORT_SUMMARY,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: VISA_ACQUIRER_POSTING_REPORT_SUMMARY_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchVisaIssuerPostingReportSummary =
  (batchDate) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader.
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/issuers/${batchDate}/posting-report/`,
    })
      .then((response) => {
        //2019-11-09
        dispatch({
          type: VISA_ISSUER_POSTING_REPORT_SUMMARY,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: VISA_ISSUER_POSTING_REPORT_SUMMARY_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchpostilionSummary =
  (page, startDate, endDate, fileName, code) => async (dispatch) => {
    const url = `${BASE_URL}/postilion/summary?page=${page}&startDate=${startDate}&endDate=${endDate}${
      code ? `&affiliateCode=${code}` : ""
    }${fileName ? `&filename=${fileName}` : ""}`;
    try {
      const { data } = await axios({ method: "GET", url });
      dispatch({
        type: GET_POSTILION_SUMMARY,
        payload: data,
      });
    } catch ({ response: payload }) {
      dispatch({
        type: GET_POSTILION_SUMMARY_ERROR,
        payload,
      });
    }
  };

export const fetchFlexcubeSummary =
  (page, startDate, endDate, fileName, code) => async (dispatch) => {
    const url = `${BASE_URL}/flex-cube/summary?page=${page}&startDate=${startDate}&endDate=${endDate}${
      code ? `&affiliateCode=${code}` : ""
    }${fileName ? `&filename=${fileName}` : ""}`;
    try {
      const { data } = await axios({ method: "GET", url });
      dispatch({
        type: GET_FLEXCUBE_SUMMARY,
        payload: data,
      });
    } catch ({ response: payload }) {
      dispatch({
        type: GET_FLEXCUBE_SUMMARY_ERROR,
        payload,
      });
    }
  };

export const fetchPOSacquirersSummary =
  (page, startDate, endDate) => async (dispatch) => {
    const url = `${BASE_URL}/pos-acquirers/summary?startDate=${startDate}&endDate=${endDate}&page=${page}`;
    try {
      const { data } = await axios({ method: "GET", url });
      dispatch({
        type: GET_POS_ACQUIRERS_SUMMARY,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_POS_ACQUIRERS_SUMMARY_ERROR,
        payload: err.response ? err.response.data.message : "an error occured",
      });
    }
  };

export const fetchPostilionSummaryItem = (itemDate, page) => (dispatch) => {
  dispatch(showLoading()); // shows github like loader.
  axios({
    method: "GET",
    url: `${BASE_URL}/postilion/by-date?date=${itemDate}&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_POSTILION_SUMMARY_ITEM,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_POSTILION_SUMMARY_ITEM_ERROR,
        payload: err,
      });
    });
  dispatch(hideLoading()); // hides the above loader
};

export const fetchFlexcubeSummaryItem = (itemDate, page) => (dispatch) => {
  dispatch(showLoading()); // shows github like loader feature.
  axios({
    //2019-11-10
    method: "GET",
    url: `${BASE_URL}/flex-cube/by-date?date=${itemDate}&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_FLEXCUBE_SUMMARY_ITEM,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FLEXCUBE_SUMMARY_ITEM_ERROR,
        payload: err,
      });
    });
  dispatch(hideLoading()); // hides the above loader
};

export const disMissError = () => (dispatch) => {
  dispatch({
    type: DIS_MISS_ERROR,
    payload: false,
  });
};

export const deletePostilionContent = (filename) => (dispatch) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(filename)}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_POSTILION_CONTENT,
        payload: `${filename}`,
      });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_POSTILION_CONTENT_ERROR,
        payload: err,
      });
    });
};

export const deleteFlexCubeContent = (filename) => (dispatch) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(filename)}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_FLEXCUBE_CONTENT,
        payload: `${filename}`,
      });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_FLEXCUBE_CONTENT_ERROR,
        payload: err,
      });
    });
};

export const deletePOSAcquirerContent = (filename) => (dispatch) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(filename)}`
    )
    .then((response) => {
      dispatch({
        type: DELETE_POS_CONTENT,
        payload: `${filename}`,
      });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_POS_CONTENT_ERROR,
        payload: err,
      });
    });
};
export const fetchPOSacquirerSummaryItem =
  (itemDate, page = 0) =>
  (dispatch) => {
    dispatch(showLoading()); // shows github like loader feature.
    axios({
      //2019-11-10
      method: "GET",
      url: `${BASE_URL}/pos-acquirers/by-date?date=${itemDate}&page=${page}&size=20`,
    })
      .then((response) => {
        dispatch({
          type: GET_POSACQUIRERS_SUMMARY_ITEM,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_POSACQUIRERS_SUMMARY_ITEM_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchPostilionSummarySearch =
  (itemDate, searchBy, searchValue) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader feature.
    axios({
      method: "GET",
      url: `${BASE_URL}/postilion/search?date=${itemDate}&${searchBy}=${searchValue}`,
    })
      .then((response) => {
        dispatch({
          type: GET_POSTILION_SUMMARY_SEARCH,
          payload: { content: response.data.content },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_POSTILION_SUMMARY_SEARCH_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const fetchFlexcubeSummarySearch =
  (itemDate, searchBy, searchValue, page) => (dispatch) => {
    dispatch(showLoading()); // shows github like loader feature.
    axios({
      method: "GET",
      url: `${BASE_URL}/flex-cube/search?date=${itemDate}&${searchBy}=${searchValue}&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_FLEXCUBE_SUMMARY_SEARCH,
          payload: { content: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FLEXCUBE_SUMMARY_SEARCH_ERROR,
          payload: err,
        });
      });
    dispatch(hideLoading()); // hides the above loader
  };

export const dispatchSelectedUserAffiliateCode =
  (affiliateCode) => (dispatch) => {
    dispatch({
      type: SEND_SELECTED_AFFILIATE_CODE,
      payload: { data: affiliateCode },
    });
  };

export const fetchInterAffiliatePostingOverview = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/posting-summary/inter-affiliate?batchDate=${date}`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_POSTING_OVERVIEW,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_POSTING_OVERVIEW_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaPostingOverview = (date) => (dispatch) => {
  dispatch({
    type: GET_VISA_POSTING_OVERVIEW_LOADING,
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/posting-summary/visa?batchDate=${date}`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_POSTING_OVERVIEW,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VISA_POSTING_OVERVIEW_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?all&all`,
  })
    .then((response) => {
      dispatch({
        type: GET_ALL_VSS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_VSS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss110Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss110&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_110,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_110_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss115Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss115&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_115,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_115_ERROR,
        payload: err.response,
      });
    });
};
export const fetchAllVss116Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss116&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_116,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_116_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss120Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss120&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_120,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_120_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss300Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss300&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_300,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_300_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllVss900Files = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss?vss900s&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_900,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_900_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss110Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss110&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_110_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_110_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss115Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss115&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_115_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_115_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss116Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss116&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_116_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_116_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss120Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss120&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_120_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_120_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss300Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss300&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_300_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_300_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVss900Summary = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/vss/${date}?vss900s&page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VSS_900_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VSS_900_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};
export const fetchInterswitchComputationSummary = (id, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-switch/daily-computations/${id}/details?page=${page}&size=20`,
  })
    .then((response) => {
      debugger;
      dispatch({
        type: GET_DAILY_INTERSWITCH_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_DAILY_INTERSWITCH_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};
export const fetchAllInterswitchComputations =
  (startDate, endDate, page) => (dispatch) => {
    if (startDate && endDate) {
      axios({
        method: "GET",
        url: `${BASE_URL}/inter-switch/daily-computations?date&startDate=${startDate}&endDate=${endDate}`,
      })
        .then((response) => {
          dispatch({
            type: GET_INTERSWITCH_COMPUTATIONS,
            payload: response,
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_INTERSWITCH_COMPUTATIONS_ERROR,
            payload: err.response,
          });
        });
    } else {
      axios({
        method: "GET",
        url: `${BASE_URL}/inter-switch/daily-computations?page=${page}&size=20`,
      })
        .then((response) => {
          dispatch({
            type: GET_INTERSWITCH_COMPUTATIONS,
            payload: response,
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_INTERSWITCH_COMPUTATIONS_ERROR,
            payload: err.response,
          });
        });
    }
  };

export const fetchAllAdvisements =
  (page, startDate, endDate) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/advisement/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}`,
      });
      dispatch({
        type: GET_ADVISEMENT,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADVISEMENT_ERROR,
        payload: error.response,
      });
    }
  };

export const fetchAllInterAffiliateQueryDetails =
  (id, resolution, issuer, acquirer, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/summaries/${id}/items?issuer-code=${issuer}&acquirer-code=${acquirer}&resolution-status=${resolution}&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: INTER_AFFILIATE_QUERY_DETAILS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: INTER_AFFILIATE_QUERY_DETAILS_ERROR,
          payload: err.response,
        });
      });
  };
export const downloadInterAffiliateQueryDetail =
  (id, issuer, acquirer, resolution) => () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/summaries/${id}/items?csv&issuer-code=${issuer}&acquirer-code=${acquirer}&resolution-status=${resolution}`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `InterAffiliate_Query_details_${id}.csv`);
      document.body.appendChild(link);
      link.click();
      return;
    });
  };
export const fetchAllInterAffiliateSourceSummary = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-aff-source/summaries`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_SOURCE_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_SOURCE_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllInterAffiliateSourceIssuerItem =
  (batchDate, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-aff-source/items/issuer?batchDate=${batchDate}&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_SOURCE_ITEM,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_SOURCE_ITEM_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchAllInterAffiliateSourceAcquirerItem =
  (batchDate, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-aff-source/items/acquirer?batchDate=${batchDate}&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_SOURCE_ITEM,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_SOURCE_ITEM_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchInterAffiliateGrossSettlement =
  (date, region, issuerCode, acceptParam) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/reports/gross-settlement?settlementDate=${date}&region-code=${region}&issuer-code=${issuerCode}`,
      headers: { Accept: `${acceptParam}` },
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_GROSS_SETTLEMENT,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_GROSS_SETTLEMENT_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadInterAffReportGross =
  (date, region, issuerCode, type) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region" || issuerCode === "Issuer")
        throw new Error(
          "Error! Please select a region and issuer code to download"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/gross-settlement?settlementDate=${date}&region-code=${region}&issuer-code=${issuerCode}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + ` InterAffiliate_Gross_Report_For_${region}_Region.${type}`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchInterAffiliateRegisterSettlement =
  (settlementDate, region) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/reports/register-settlement?settlementDate=${settlementDate}&region-code=${region}`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_REGISTER_SETTLEMENT,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_REGISTER_SETTLEMENT_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadInterAffReportRegister =
  (date, region, type) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region")
        throw new Error(
          "Error download failed! Please select a region and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/register-settlement?settlementDate=${date}&region-code=${region}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + ` InterAffiliate_Settlement_Report_For_${region}_Region.${type}`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchInterAffiliateSummSettlement =
  (settlementDate, regionCode, issuerCode) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/json" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/summ-settlement?settlementDate=${settlementDate}&region-code=${regionCode}&issuer-code=${issuerCode}`,
      });
      dispatch({
        type: GET_INTERAFFILIATE_SUMM_SETTLEMENT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_INTERAFFILIATE_SUMM_SETTLEMENT_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadInterAffiliateSummSettlement =
  (date, region, issuerCode) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region" || issuerCode === "Issuer")
        throw new Error(
          "Error download failed! Please select a region and Issuer Code and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/summ-settlement?settlementDate=${date}&issuer-code=${issuerCode}&region-code=${region}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + ` InterAffiliate_SummSettlement_Report_For_${region}_Region.txt`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchSettlementBreakdown =
  (settlementDate, Region) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/reports/settlement-summary?settlementDate=${settlementDate}&region-code=${Region}`,
    })
      .then((response) => {
        dispatch({
          type: GET_SETTLEMENT_BREAKDOWN,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTLEMENT_BREAKDOWN_ERROR,
          payload: err.response,
        });
      });
  };
export const downloadSettlementBreakdown = (date, region) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/v2/reports/settlement-summary?csv&settlementDate=${date}&region-code=${region}`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", date + ` Settlement_breakdown.csv`);
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const fetchSettlementReport =
  (settlementDate, Region, Page) => (dispatch) => {
    axios({
      headers: { Accept: "application/json" },
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/reports/settlement?settlementDate=${settlementDate}&region-code=${Region}&page=${Page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_SETTLEMENT_REPORT,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTLEMENT_REPORT_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadSettlementReport =
  (date, downloadDate, region) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region")
        throw new Error("Error! Please select a region to download");
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/settlement?settlementDate=${date}&region-code=${region}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${INTER_AFFILIATE_DOWNLOAD_FILENAME}${downloadDate}${region}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchPostingReport =
  (settlementDate, Region, affiliateCode, Page) => (dispatch) => {
    axios({
      headers: { Accept: "application/json" },
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/reports/postings?settlementDate=${settlementDate}&region-code=${Region}&affiliateCode=${affiliateCode}&page=${Page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_POSTING_REPORT,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_POSTING_REPORT_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchMastercardPosting =
  (page, startDate, endDate) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/mastercard/postings/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}`,
      });
      dispatch({
        type: MASTERCARD_POSTING,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: MASTERCARD_POSTING_ERROR,
        payload: error.response,
      });
    }
  };

export const fetchMastercardPostingDetails =
  (startDate, endDate) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/admin/affiliate-posting-logs?scheme=MASTERCARD&startDate=${startDate}&endDate=${endDate}`,
      });

      dispatch({
        type: MASTERCARD_POSTING_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: MASTERCARD_POSTING_DETAILS_ERROR,
        payload: error.response,
      });
    }
  };
export const fetchAllQueryDetails =
  (id, page, merchandise_credit) => (dispatch) => {
    let url;
    if (merchandise_credit) {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}&merchandise-credit=${merchandise_credit}`;
    } else {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`;
    }
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        dispatch({
          type: GET_QUERY_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_QUERY_DETAILS_ERROR,
          payload: err.response,
        });
      });
  };
export const downloadMastercardGIMQueryDetail =
  (id, merchandise_credit, date, affCode, scheme) => () => {
    let url;
    if (merchandise_credit) {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&merchandise-credit=${merchandise_credit}`;
    } else {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}`;
    }
    axios({
      method: "GET",
      url,
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${
          scheme === "gim" ? GIM_FILENAME : MASTERCARD_FILENAME
        }${affCode}${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
      return;
    });
  };

export const fetchInterAffiliatePostingDetails =
  (itemID) => async (dispatch) => {
    try {
      const response = await axios({
        headers: { Accept: "application/json" },
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/summaries/${itemID}/details`,
      });
      dispatch({
        type: GET_INTERAFFILIATE_POSTING,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_INTERAFFILIATE_POSTING_ERROR,
        payload: error.response,
      });
    }
  };

export const fetchAuditLogsFn =
  (page, startDate, endDate) => async (dispatch) => {
    try {
      const response = await axios({
        headers: { Accept: "application/json" },
        method: "GET",
        url: `${BASE_URL}/admin/view-http-traces?page=${page}&startDate=${startDate}&endDate=${endDate}`,
      });
      dispatch({
        type: FETCH_AUDIT_LOGS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FETCH_AUDIT_LOGS_ERROR,
        payload: error.response.data,
      });
    }
  };

export const downloadAuditLogsFn = (startDate, endDate) => async (dispatch) => {
  dispatch({
    type: DOWNLOADING_STATUS,
    payload: { data: true },
  });
  try {
    if (itExists(startDate, endDate) === false)
      throw new Error(
        "Download failed! Select a date range before downloading."
      );
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/octet-stream",
        Accept: "application/octet-stream",
      },
      url: `${BASE_URL}/admin/download-http-traces?startDate=${startDate}&endDate=${endDate}`,
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `Audit_log_records from_${startDate}_to_${endDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
  } catch (error) {
    let data = true;
    if (error.message) data = error.message;
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
    dispatch({
      type: OPEN_ALERT,
      payload: { data },
    });
  }
};

export const fetchInterPostingLog =
  (LogId, page, merchandise_credit) => async (dispatch) => {
    let url;
    if (merchandise_credit) {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${LogId}&merchandise-credit=true&page=${page}`;
    } else {
      url = `${BASE_URL}/admin/query-posting-records?posting-log-id=${LogId}&page=${page}`;
    }
    try {
      const response = await axios({
        headers: { Accept: "application/json" },
        method: "GET",
        url,
      });
      dispatch({
        type: GET_GENERIC_POSTING_RECORDS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_GENERIC_POSTING_RECORDS_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadInterPostingLog =
  (LogId, settlementDate, affiliateCode) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (LogId === "null")
        throw new Error(
          "Download failed! No logId found. Make sure there are posting entries to download."
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/admin/query-posting-records?posting-log-id=${LogId}`,
        responseType: "blob", // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const downloadPostingReport =
  (dateOnDownloadedFileName, date, affCode, region) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });

    try {
      if (affCode === "Affiliate" || region === "Region")
        throw new Error(
          "Error download failed! Select an affiliate and region code and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/postings?settlementDate=${date}&affiliateCode=${affCode}&region-code=${region}`,
        responseType: "blob", // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${INTER_AFFILIATE_FILENAME}${affCode}${dateOnDownloadedFileName}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchAffiliatePostingReport =
  (settlementDate, regionCode, pageQuery) => async (dispatch) => {
    try {
      const response = await axios({
        headers: { Accept: "application/json" },
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/reports/posting?settlementDate=${settlementDate}&region-code=${regionCode}&page=${pageQuery}`,
      });
      dispatch({
        type: GET_POSTING_REPORT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_POSTING_REPORT_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadAffiliatePostingReport =
  (settlementDate, region) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      headers: { Accept: "application/octet-stream" },
      url: `${BASE_URL}/inter-affiliate/v2/reports/posting?settlementDate=${settlementDate}&region-code=${region}`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        settlementDate +
          ` InterAffiliate_Affiliate_Posting_Report_For_${region}_Region.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const fetchInterAffiliateIssuerSourceItems =
  (settlementDate, regionCode, pageQuery) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/items/issuer?settlementDate=${settlementDate}&region-code=${regionCode}&page=${pageQuery}`,
    })
      .then((response) => {
        dispatch({
          type: GET_AFFILIATE_ISSUER_SOURCE_ITEMS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_AFFILIATE_ISSUER_SOURCE_ITEMS_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchInterAffiliateAcquirerSourceItems =
  (settlementDate, regionCode, pageQuery) => async (dispatch) => {
    try {
      const response = await axios({
        // get the response from async axios request
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/items/issuer?settlementDate=${settlementDate}&region-code=${regionCode}&page=${pageQuery}`,
      });
      dispatch({
        // dispatch response
        type: GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AFFILIATE_ACQUIRER_SOURCE_ITEMS_ERROR,
        payload: error.response,
      });
    }
  };

export const fetchInterAffiliateIssuerAudit =
  (settlementDate, regionCode, issuerCode, acquirerCode, pageQuery) =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/reports/issuer?settlementDate=${settlementDate}&region-code=${regionCode}&issuer-code=${issuerCode}&acquirer-code=${acquirerCode}&page=${pageQuery}`,
      });
      dispatch({
        type: GET_ISSUER_AUDIT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ISSUER_AUDIT_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadAffiliateIssuerAudit =
  (settlementDate, region, issuerCode, acquirerCode) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region")
        throw new Error(
          "Error download failed! Please select a region and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/issuer?settlementDate=${settlementDate}&region-code=${region}&issuer-code=${issuerCode}&acquirer-code=${acquirerCode}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        settlementDate + ` InterAffiliate_Issuer_Audit_For_${region}_Region.txt`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchInterAffiliateAcquirerAudit =
  (settlementDate, regionCode, issuerCode, acquirerCode, pageQuery) =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/reports/acquirer?settlementDate=${settlementDate}&region-code=${regionCode}&issuer-code=${issuerCode}&acquirer-code=${acquirerCode}&page=${pageQuery}`,
      });
      dispatch({
        type: GET_ACQUIRER_AUDIT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ACQUIRER_AUDIT_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadAffiliateAcquirerAudit =
  (settlementDate, region, issuerCode, acquirerCode) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region")
        throw new Error(
          "Error download failed! Please select a region and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/acquirer?settlementDate=${settlementDate}&region-code=${region}&issuer-code=${issuerCode}&acquirer-code=${acquirerCode}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        settlementDate +
          ` InterAffiliate_Acquirer_Audit_For_${region}_Region.txt`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchInterAffiliateAffPostingSummary =
  (queryParams) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/reports/postings-summary${queryParams}`,
      });
      dispatch({
        type: GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_INTERAFFILIATE_AFFILIATE_POSTING_SUMMARY_ERROR,
        payload: error,
      });
    }
  };

export const fetchSumSettlementFees =
  (settlementDate, regionCode) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/inter-affiliate/v2/reports/settlement-fees?settlementDate=${settlementDate}&region-code=${regionCode}`,
      });
      dispatch({
        type: GET_SUM_SETTLEMENT_FEES,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SUM_SETTLEMENT_FEES_ERROR,
        payload: error.response,
      });
    }
  };

export const downloadAffiliateSettlementFees =
  (settlementDate, region) => async (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    try {
      if (region === "Region")
        throw new Error(
          "Error download failed! Please select a region and try again!"
        );
      const response = await axios({
        method: "GET",
        headers: { Accept: "application/octet-stream" },
        url: `${BASE_URL}/inter-affiliate/v2/reports/settlement-fees?settlementDate=${settlementDate}&region-code=${region}`,
        responseType: "blob", // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        settlementDate +
          ` InterAffiliate_Settlement_Fees_For_${region}_Region.txt`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    } catch (error) {
      let data = true;
      if (error.message) data = error.message;
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      dispatch({
        type: OPEN_ALERT,
        payload: { data },
      });
    }
  };

export const fetchAdvisementDetail = (batchDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/advisement/${batchDate}`,
  })
    .then((response) => {
      dispatch({
        type: GET_ADVISEMENT_DETAIL,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ADVISEMENT_DETAIL_ERROR,
        payload: err.response,
      });
    });
};

export const advisementSummary = (batchDate) => (dispatch) => {
  axios({
    //2019-01-01
    method: "GET",
    url: `${BASE_URL}/mastercard/${batchDate}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_ADVISEMENT_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ADVISEMENT_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const downloadAdvisementPostingSummaryCsv = (date) => (dispatch) => {
  dispatch({
    type: DOWNLOADING_STATUS,
    payload: { data: true },
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/${date}/summary?csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "Advisement_Posting_Summary " + date + ".csv"
    );
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
  });
};

export const downloadInterAffiliateSummaryTransactions =
  (date) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/summaries/${date}/items?csv&csv`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "InterAffiliate_Summary_Transactions " + date + ".csv"
      );
      document.body.appendChild(link);
      link.click();
      return;
    });
  };

export const fetchInterAffiliateSummaryTransactions =
  (date, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/summaries/${date}/items?page=${page}&size=20`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_SUMMARY_TRANSACTIONS_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadInterAffiliatePostingSummary = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/${date}/summary?csv&csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "InterAffiliate_Posting_Summary" + date + ".csv"
    );
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const fetchInterAffiliatePostingSummary = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_POSTING_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_POSTING_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchInterAffilliateSummary = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/summaries?page=${page}&size=10`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaSummary = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
  })
    .then(({ data: payload }) => {
      dispatch({
        type: GET_VISA_SUMMARY,
        payload,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VISA_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const downloadVisaPostingSummary =
  (date, headerConfig) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      headers: { affiliateCode: headerConfig },
      url: `${BASE_URL}/visa/${date}/summary?csv&csv`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Visa_Posting_Summary " + date + ".csv");
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const downloadAutomaticVisaPostingSummaryFile =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/${date}/summary?csv2`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
      // return;
    });
  };

export const downloadVisaPostingSummaryFile =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/${date}/summary?csv`,
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const fetchVisaPostingSummary = (date, headerConfig) => (dispatch) => {
  dispatch(showLoading());
  dispatch({
    type: GET_VISA_POSTING_SUMMARY_LOADING,
  });
  axios({
    method: "GET",
    headers: { affiliateCode: headerConfig },
    url: `${BASE_URL}/visa/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_POSTING_SUMMARY,
        payload: response,
      });
      dispatch(hideLoading());
    })
    .catch((err) => {
      dispatch(hideLoading());
      dispatch({
        type: GET_VISA_POSTING_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaAcquirerSummary = (date, headerConfig) => (dispatch) => {
  dispatch(showLoading());
  dispatch({
    type: GET_VISA_POSTING_SUMMARY_LOADING,
  });
  axios({
    method: "GET",
    headers: { affiliateCode: headerConfig },
    url: `${BASE_URL}/visa/acquirers/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_ACQUIRER_SUMMARY,
        payload: response,
      });
      dispatch(hideLoading());
    })
    .catch((err) => {
      dispatch(hideLoading());
      dispatch({
        type: GET_VISA_ACQUIRER_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaAcquirerSummaryStats =
  (date, headerConfig) => (dispatch) => {
    axios({
      method: "GET",
      headers: { affiliateCode: headerConfig },
      url: `${BASE_URL}/visa/acquirers/${date}/utils`,
    })
      .then((response) => {
        dispatch({
          type: GET_VISA_ACQUIRER_SUMMARY_STATS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_VISA_ACQUIRER_SUMMARY_STATS_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchVisaIssuerSummaryStats = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/issuers/${date}/utils`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_ISSUER_SUMMARY_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VISA_ISSUER_SUMMARY_STATS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaIssuerSummary = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/issuers/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_ISSUER_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VISA_ISSUER_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const deleteInterAffiliateChangeLogItem = (file) => (dispath) => {
  axios
    .delete(
      `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(file.filename)}`
    )
    .then((response) => {
      dispath({
        type: DELETE_INTERAFFILIATE_CHANNEL_LOG,
        payload: { data: file.id },
      });
    })
    .catch((error) => {
      dispath({
        type: DELETE_INTERAFFILIATE_CHANNEL_LOG_ERROR,
        payload: error,
      });
    });
};

export const fetchAllInterAffiliatePostOfficeSummary =
  (startDate, endDate, pageNumber, pageSize) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/inter-affiliate/v2/summaries?startDate=${startDate}&endDate=${endDate}&page=${pageNumber}&size=${pageSize}`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_POSTOFFICE_SUMMARY,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_ERROR,
          payload: err.response,
        });
      });
  };

export const interAffiliatePostOfficeSummary =
  (batchDate, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/post-office/items?batchDate=${batchDate}&&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INTERAFFILIATE_POSTOFFICE_SUMMARY_DETAIL_ERROR,
          payload: err.response,
        });
      });
  };

export const interaffiliatePostOfficeSummaryCsv = (date) => (dispatch) => {
  dispatch({
    type: DOWNLOADING_STATUS,
    payload: { data: true },
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/post-office/items?csv&batchDate=${date}&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + " InterAffiliate_PostOffice_Summary.csv"
    );
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
  });
};

export const fetchAllInterAffiliateIssuer = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/issuers?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFILIATE_ISSUERS,
        payload: response,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_INTERAFILIATE_ISSUERS_ERROR,
        payload: err.response,
      })
    );
};

export const fetchAllInterAffiliateAcquirer = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/acquirers?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFILIATE_ACQUIRER,
        payload: response,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_INTERAFILIATE_ACQUIRER_ERROR,
        payload: err.response,
      })
    );
};

export const fetchVisaAcquirer = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/acquirers/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=20`,
  })
    .then(({ data: payload }) => {
      dispatch({
        type: GET_VISA_ACQUIRER,
        payload,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_VISA_ACQUIRER_ERROR,
        payload: err.response,
      })
    );
};

export const fetchVisaIssuer = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/issuers/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=20`,
  })
    .then(({ data: payload }) => {
      dispatch({
        type: GET_VISA_ISSUER,
        payload,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_VISA_ISSUER_ERROR,
        payload: err.response,
      })
    );
};

export const fetchVisaOct = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/oct?startDate=${startDate}&endDate=${endDate}&page=${page}&size=20`,
  })
    .then(({ data: payload }) => {
      dispatch({
        type: GET_VISA_OCT,
        payload,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_VISA_OCT_ERROR,
        payload: err.response,
      });
    });
};

export const updateVisaIssuerTransactions =
  (resolutionData, date) => (dispatch) => {
    dispatch(showLoading());
    dispatch({
      type: GET_VISA_ISSUER_TRANSACTIONS_LOADING,
      payload: { data: true },
    });
    axios
      .put(`${BASE_URL}/visa/issuers/${date}/items`, resolutionData)
      .then((response) => {
        dispatch({
          type: VISA_ISSUER_TRANSACTIONS_UPDATE,
          payload: response,
        });
        dispatch({
          type: GET_VISA_ISSUER_TRANSACTIONS_LOADING,
          payload: { data: false },
        });
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch({
          type: GET_VISA_ISSUER_TRANSACTIONS_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchVisaIssuerTransactions =
  (date, searchBy, page) => (dispatch) => {
    dispatch(showLoading());
    dispatch({
      type: GET_VISA_ISSUER_TRANSACTIONS_LOADING,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/issuers/${date}/items?page=${page}&size=20&type=${searchBy.toUpperCase()}`,
    })
      .then((response) => {
        dispatch({
          type: GET_VISA_ISSUER_TRANSACTIONS,
          payload: response.data,
        });
        dispatch({
          type: GET_VISA_ISSUER_TRANSACTIONS_LOADING,
          payload: { data: false },
        });
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch({
          type: GET_VISA_ISSUER_TRANSACTIONS_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchVisaAcquirerTransactions =
  (date, searchBy, page) => (dispatch) => {
    dispatch(showLoading());
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/acquirers/${date}/items?page=${page}&size=20&type=${searchBy.toUpperCase()}`,
    })
      .then((response) => {
        dispatch({
          type: GET_VISA_ACQUIRER_TRANSACTIONS,
          payload: response,
        });
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch({
          type: GET_VISA_ACQUIRER_TRANSACTIONS_ERROR,
          payload: err.response,
        });
      });
  };

export const updateVisaAcquirerTransactions =
  (resolutionData, date) => (dispatch) => {
    dispatch(showLoading());
    dispatch({
      type: GET_VISA_ACQUIRER_TRANSACTIONS_LOADING,
      payload: { data: true },
    });
    axios
      .put(`${BASE_URL}/visa/issuers/${date}/items`, resolutionData)
      .then((response) => {
        dispatch({
          type: VISA_ACQUIRER_TRANSACTIONS_UPDATE,
          payload: response,
        });
        dispatch({
          type: GET_VISA_ACQUIRER_TRANSACTIONS_LOADING,
          payload: { data: false },
        });
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch({
          type: VISA_ACQUIRER_TRANSACTIONS_UPDATE_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchVisaOctTransactions = (id, page) => (dispatch) => {
  dispatch(showLoading());
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/oct/${id}/items?page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_VISA_OCT_TRANSACTIONS,
        payload: response.data,
      });
      dispatch(hideLoading());
    })
    .catch((err) => {
      console.log(err);
      dispatch(hideLoading());
      dispatch({
        type: GET_VISA_OCT_TRANSACTIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchVisaPostingDetails = (fileId) => async (dispatch) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_URL}/visa/${fileId}/details`,
    });
    dispatch({
      type: GET_VISA_POSTING_DETAILS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_VISA_POSTING_DETAILS_ERROR,
      payload: error.response,
    });
  }
};

export const fetchAllGIMPostingSummaries =
  (page, startDate, endDate) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/gim/postings?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
    })
      .then((response) => {
        dispatch({
          type: GET_GIM_POSTING_SUMMARIES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_GIM_POSTING_SUMMARIES_ERROR,
          payload: err.response,
        });
      });
  };
export const fetchGIMSummaryDetail = (id) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/gim/postings/${id}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_GIM_SUMMARY_DETAIL,
        payload: response.data,
      });
      dispatch({
        type: GET_GIM_SUMMARY_DETAIL_ERROR,
        payload: null,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_GIM_SUMMARY_DETAIL_ERROR,
        payload: err.response,
      });
      dispatch({
        type: GET_GIM_SUMMARY_DETAIL,
        payload: null,
      });
    });
};
export const fetchGIMPostingDetail =
  (startDate, endDate) => async (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/admin/affiliate-posting-logs?scheme=GIM&startDate=${startDate}&endDate=${endDate}`,
    })
      .then((response) => {
        dispatch({
          type: GET_GIM_POSTING_DETAIL,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_GIM_POSTING_DETAIL_ERROR,
          payload: err.response,
        });
      });
  };
export const fetchAllGIMDetailFiles =
  (page, startDate, endDate) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/gim/detail?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
    })
      .then((response) => {
        dispatch({
          type: GET_GIM_DETAIL_FILES,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_GIM_DETAIL_FILES_ERROR,
          payload: err.response,
        });
      });
  };
export const fetchGIMFileSummary = (id) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/gim/detail/${id}`,
  })
    .then((response) => {
      dispatch({
        type: GET_GIM_FILE_SUMMARY,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_GIM_FILE_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};
export const fetchGIMFileContent = (page, id) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/gim/detail/${id}/items?page=${page}&size=20`,
  })
    .then((response) => {
      dispatch({
        type: GET_GIM_FILE_CONTENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_GIM_FILE_CONTENT_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllT140s = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/t140s/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
  })
    .then((response) => {
      dispatch({
        type: GET_T140S,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T140S_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllT461s = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/t461s/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
  })
    .then((response) => {
      dispatch({
        type: GET_T461S,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T461S_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllT464s = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t464s/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
  })
    .then((response) => {
      dispatch({
        type: GET_T464S,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T464S_ERROR,
        payload: err.response,
      });
    });
};

export const fetchAllT112s = (page, startDate, endDate) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}&size=10`,
  })
    .then((response) => {
      dispatch({
        type: GET_T112S,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T112S_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT464Transactions = (fileId, page, type) => (dispatch) => {
  dispatch({
    type: GET_T464_TRANSACTIONS_LOADING,
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t464s/${fileId}?page=${page}&size=20&type=${type}`,
  })
    .then((response) => {
      dispatch({
        type: GET_T464_TRANSACTIONS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T464_TRANSACTIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT464Metric = async (fileId, affiliate) => {
  return axios.get(`${BASE_URL}/mastercard/t464s/${fileId}/metrics`, {
    headers: { affiliateCode: affiliate },
  });
};

export const fetchAcquirerTransactions = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/acquirers/${date}/items?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_ACQUIRER_TRANSACTIONS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ACQUIRER_TRANSACTIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchIssuerTransactions = (date, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/issuers/${date}/items?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_ISSUER_TRANSACTIONS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ISSUER_TRANSACTIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT461Transactions = (fileId, page) => async (dispatch) => {
  dispatch({
    type: GET_T461_TRANSACTIONS_LOADING,
  });
  try {
    const { data } = await axios({
      method: "GET",
      url: `${BASE_URL}/t461s/${fileId}?page=${page}&size=20`,
    });
    dispatch({
      type: GET_T461_TRANSACTIONS,
      payload: data,
    });
  } catch ({ response: payload }) {
    dispatch({
      type: GET_T461_TRANSACTIONS_ERROR,
      payload,
    });
  }
};

export const fetchT461Metric = async (fileId, affiliate) => {
  return axios.get(`${BASE_URL}/t461s/${fileId}/metrics`, {
    headers: { affiliateCode: affiliate },
  });
};

export const fetchT112Transactions = (fileId, page) => (dispatch) => {
  dispatch({
    type: GET_T112_TRANSACTIONS_LOADING,
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/${fileId}?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_T112_TRANSACTIONS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T112_TRANSACTIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT112Metric = async (fileId, affiliate) => {
  return axios.get(`${BASE_URL}/mastercard/t112s/${fileId}/metrics`, {
    headers: { affiliateCode: affiliate },
  });
};

export const fetchT140Metric = async (fileId, affiliate) => {
  return axios.get(`${BASE_URL}/t140s/${fileId}/metrics`, {
    headers: { affiliateCode: affiliate },
  });
};

export const fetchT140Transactions = (fileId, page) => (dispatch) => {
  dispatch({
    type: GET_T140S_TRANSACTIONS_LOADING,
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/t140s/${fileId}?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_T140S_TRANSACTIONS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T140S_TRANSACTIONS,
        payload: err.response,
      });
    });
};

export const fetchMasterPOSacquirerSummaryItem = (itemDate) => (dispatch) => {
  dispatch(showLoading());
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/pos-acquirer/${itemDate}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM,
        payload: response.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_MASTERCARD_POSACQUIRERS_SUMMARY_ITEM_ERROR,
          payload: err.response.data.message,
        });
      }
    });
  dispatch(hideLoading()); // hides the above loader
};
export const downloadMastercardPosAcquirerPostingCsv = (date) => (dispatch) => {
  dispatch({
    type: MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS,
    payload: { data: true },
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/pos-acquirer/${date}/summary?csv&csv=`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", date + " POS-Acquirer_Posting_Summary.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: MASTERCARD_POSACQUIRERS_CSV_DOWNLOADING_STATUS,
      payload: { data: false },
    });
  });
};

export const fetchInterAffiliateAcquirerSummary = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/acquirers/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_ACQUIRER_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_ACQUIRER_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchInterAffiliateAcquirerSummaryStats = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/acquirers/${date}/utils`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_ACQUIRER_SUMMARY_STATS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchInterAffiliateIssuerSummaryStats = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/issurers/${date}/utils`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_ISSUER_SUMMARY_STATS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchInterAffiliateIssuerSummary = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/issuers/${date}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_INTERAFFILIATE_ISSUER_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INTERAFFILIATE_ISSUER_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT464Summary = (fileId, page) => async (dispatch) => {
  try {
    const { data: payload } = await axios.get(
      `${BASE_URL}/mastercard/t464s/${fileId}/summary?page=${page}`
    );
    dispatch({
      type: GET_T464_SUMMARY,
      payload,
    });
  } catch ({ response: payload }) {
    dispatch({
      type: GET_T464_SUMMARY_ERROR,
      payload,
    });
  }
};

export const fetchT464SummaryStats = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t464s/${fileId}/utils`,
  })
    .then((response) => {
      dispatch({
        type: GET_T464_SUMMARY_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_t464_SUMMARY_STATS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT112SummaryStats = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/${fileId}/utils`,
  })
    .then((response) => {
      dispatch({
        type: GET_T112_SUMMARY_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T112_SUMMARY_STATS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchT112Summary = (fileId) => (dispatch) => {
  dispatch({
    type: GET_T112_SUMMARY_LOADING,
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/${fileId}/summary`,
  })
    .then((response) => {
      dispatch({
        type: GET_T112_SUMMARY,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_T112_SUMMARY_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSet = (fileSetId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/file-sets/${fileSetId}`,
  })
    .then((response) => {
      dispatch({
        type: GET_FILE_SET,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FILE_SET_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSetStatus =
  (fileSetId, affiliateCode, startDate, endDate) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/affiliate-details-list?affiliateCode=${affiliateCode}&startDate=${startDate}&endDate=${endDate}`,
    })
      .then((response) => {
        dispatch({
          type: GET_FILE_SET_STATUS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILE_SET_STATUS_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchFileSetDefinitions = (fileSetId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/file-sets/${fileSetId}/definitions`,
  })
    .then((response) => {
      dispatch({
        type: GET_FILE_SET_DEFINITIONS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FILE_SET_DEFINITIONS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSets = () => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/file-sets`,
  })
    .then((response) => {
      dispatch({
        type: GET_FILE_SETS,
        payload: response,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FILE_SETS_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSetsPaginated = (page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/file-sets?size=20&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_FILE_SETS_PAGINATED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FILE_SETS_PAGINATED_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSetBatchDetail = (fileSetId, batchId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}`,
  })
    .then((response) => {
      dispatch({
        type: GET_FILESET_BATCH_DETAIL,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_FILESET_BATCH_DETAIL_ERROR,
        payload: err.response,
      });
    });
};

export const fetchFileSetBatchSummary =
  (fileSetId, batchId, affiliateId) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/summary`,
    })
      .then((response) => {
        dispatch({
          type: GET_FILESET_BATCHES_SUMMARY,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILESET_BATCHES_SUMMARY_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadT461Transactions = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/t461s/${fileId}?csv&csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "T461 " + fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadT112Transactions = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/${fileId}?csv&csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "T112 " + fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadT140Transactions = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/t140s/${fileId}?csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "T140 " + fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadT464Transactions = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t464s/${fileId}?csv&csv`,
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "T464 " + fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadT112PostingFile = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t112s/${fileId}/summary?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadVisaIssuerCsv = (date) => (dispatch) => {
  dispatch({
    type: DOWNLOADING_STATUS,
    payload: { data: true },
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/issuers/${date}/items?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", date + " Issuer_Transactions.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
  });
};

export const downloadAutomaticVisaIssuerCsvSummary =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/issuers/${date}/upload?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const downloadVisaIssuerCsvSummary =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/issuers/${date}/summary?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const downloadVisaAcquirerCsv = (date) => (dispatch) => {
  dispatch({
    type: DOWNLOADING_STATUS,
    payload: { data: true },
  });
  axios({
    method: "GET",
    url: `${BASE_URL}/visa/acquirers/${date}/items?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", date + " Acquirer_Transactions.csv");
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: false },
    });
  });
};

export const downloadAutomaticVisaAcquirerCsvSummary =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/acquirers/${date}/upload?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const downloadVisaAcquirerCsvSummary =
  (date, settlementDate, affiliateCode) => (dispatch) => {
    dispatch({
      type: DOWNLOADING_STATUS,
      payload: { data: true },
    });
    axios({
      method: "GET",
      url: `${BASE_URL}/visa/acquirers/${date}/summary?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${VISA_FILENAME}${affiliateCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: DOWNLOADING_STATUS,
        payload: { data: false },
      });
    });
  };

export const downloadInterAffiliateIssuerCsv = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/issuers/${date}/items?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + " InterAffiliate_Issuers_Transactions.csv"
    );
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadInterAffiliateAcquirerCsv = (date) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/inter-affiliate/acquirers/${date}/items?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + " InterAffiliate_Acquirer_Transactions.csv"
    );
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadT464PostingFile = (fileId) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/mastercard/t464s/${fileId}/summary?csv&csv`,
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileId + ".csv");
    document.body.appendChild(link);
    link.click();
    return;
  });
};

export const downloadFileSetBatchesSummaryCsv =
  (fileSetId, batchId, name) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/summary?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".csv");
      document.body.appendChild(link);
      link.click();
      return;
    });
  };

export const fetchFileSetsDefinitionBatchesPaginated =
  (fileSetId, batchId, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/definition-batches?size=20&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_FILE_SET_DEFINITION_BATCHES_PAGINATED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILE_SET_DEFINITION_BATCHES_PAGINATED_ERROR,
          payload: err.response,
        });
      });
  };

export const downloadFileSetBatchesDefinitionTransactionCsv =
  (fileSetId, batchId, definitionId, name) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/definition-batches/${definitionId}/transactions?csv`,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".csv");
      document.body.appendChild(link);
      link.click();
      return;
    });
  };

export const fetchFileSetsDefinitionBatchesTransactionsPaginated =
  (fileSetId, batchId, definitionId, page, affiliateId) => (dispatch) => {
    if (affiliateId) {
      axios({
        method: "GET",
        url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/definition-batches/${definitionId}/transactions?affiliateId=${affiliateId}&size=20&page=${page}`,
      })
        .then((response) => {
          dispatch({
            type: GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR,
            payload: err.response,
          });
        });
    } else {
      axios({
        method: "GET",
        url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/definition-batches/${definitionId}/transactions?size=20&page=${page}`,
      })
        .then((response) => {
          dispatch({
            type: GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED,
            payload: response.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_FILE_SET_DEFINITION_BATCHES_TRANSACTIONS_PAGINATED_ERROR,
            payload: err.response,
          });
        });
    }
  };

export const fetchFileSetsDefinitionBatchesDetail =
  (fileSetId, batchId, definitionId) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches/${batchId}/definition-batches/${definitionId}`,
    })
      .then((response) => {
        dispatch({
          type: GET_FILESET_BATCH_DEFINITION_DETAIL,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILESET_BATCH_DEFINITION_DETAIL_ERROR,
          payload: err.response,
        });
      });
  };

export const fetchFileSetsBatchesPaginated =
  (fileSetId, page) => (dispatch) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/file-sets/${fileSetId}/batches?size=20&page=${page}`,
    })
      .then((response) => {
        dispatch({
          type: GET_FILE_SET_BATCHES_PAGINATED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_FILE_SET_BATCHES_PAGINATED_ERROR,
          payload: err.response,
        });
      });
  };

export const updateFileSet = (fileSetId, value) => (dispatch) => {
  axios
    .put(`${BASE_URL}/file-sets/${fileSetId}`, value)
    .then((response) => {
      dispatch({
        type: UPDATE_FILE_SET,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_FILE_SET_ERROR,
        payload: err.response,
      });
    });
};

export const deleteFileSet = (fileSetId) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/file-sets/${encryptFilename(fileSetId)}`, {})
    .then((response) => {
      dispatch({
        type: DELETE_FILE_SET,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: DELETE_FILE_SET_ERROR,
        payload: err.response,
      });
    });
};

export const createFileSet = (values) => (dispatch) => {
  axios
    .post(`${BASE_URL}/file-sets`, values)
    .then((response) => {
      dispatch({
        type: CREATE_FILE_SET,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_FILE_SET_ERROR,
        payload: err.response,
      });
    });
};

export const addFileDefinitionsToFileSet =
  (fileSetId, values) => (dispatch) => {
    axios
      .post(`${BASE_URL}/file-sets/${fileSetId}/definitions`, values)
      .then((response) => {
        dispatch({
          type: ADD_FILE_DEFINITIONS_TO_FILESET,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ADD_FILE_DEFINITIONS_TO_FILESET_ERROR,
          payload: err.response,
        });
      });
  };

export const deleteFileSetDefinition =
  (fileSetId, definitionId) => (dispatch) => {
    axios
      .delete(
        `${BASE_URL}/file-sets/${encryptFilename(
          fileSetId
        )}/definitions/${encryptFilename(definitionId)}`,
        {}
      )
      .then((response) => {
        if (response.status === 200) {
          response.data = "File Definition Deleted Successfully!";
          response.definitionId = definitionId;
          dispatch({
            type: DELETE_FILE_SET_FILE_DEFINITION,
            payload: response.definitionId,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_FILE_SET_FILE_DEFINITION_ERROR,
          payload: err.response,
        });
      });
  };
export const fetchAllXpressCashSummaries =
  (page, startDate, endDate) => async (dispatch) => {
    dispatch({
      type: GET_ALL_XPRESSCASH_SUMMARY_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}`
      );
      dispatch({
        type: GET_ALL_XPRESSCASH_SUMMARY_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_XPRESSCASH_SUMMARY_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const fetchAllXpressCashSummary = (date, code) => async (dispatch) => {
  dispatch({
    type: GET_ALL_XPRESSCASH_SINGLE_SUMMARY_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/xpress-cash/summary/${date}?region-code=${code}`
    );
    dispatch({
      type: GET_ALL_XPRESSCASH_SINGLE_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_XPRESSCASH_SINGLE_SUMMARY_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchAllXpressCashQueryDetails =
  (id, page) => async (dispatch) => {
    dispatch({
      type: GET_ALL_XPRESSCASH_QUERY_DETAILS_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`
      );
      dispatch({
        type: GET_ALL_XPRESSCASH_QUERY_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_XPRESSCASH_QUERY_DETAILS_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const fetchAllXpressCashDetails = (id, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_XPRESSCASH_DETAILS_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/xpress-cash/details/${id}?page=${page}`
    );
    dispatch({
      type: GET_ALL_XPRESSCASH_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_XPRESSCASH_DETAILS_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchXpressCashAquirer = (id, code, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_XPRESSCASH_ACQUIRER_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/xpress-cash/acquirers/${id}?acquirer-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_ALL_XPRESSCASH_ACQUIRER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_XPRESSCASH_ACQUIRER_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};
export const fetchXpressCashIssuer = (id, code, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_XPRESSCASH_ISSUER_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/xpress-cash/issuers/${id}?issuer-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_ALL_XPRESSCASH_ISSUER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_XPRESSCASH_ISSUER_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchExpressCashSettlementReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_SETTLEMENT_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_SETTLEMENT_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_SETTLEMENT_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadExpressCashSettlementReport =
  (date, region) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_EXPRESSCASH_DJRFCYNSXP_" +
      region;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const downloadExpressCashPostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_EXPRESSCASH_DJRFCY_E" +
      affiliate +
      "_XP_" +
      code;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
            affiliateCode: `E${affiliate}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchExpressCashPostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_POSTING_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        { headers: { affiliateCode: `E${affiliate}` } }
      );
      dispatch({
        type: GET_XPRESSCASH_POSTING_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_POSTING_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const fetchExpressCashFeesReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_FEES_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/settlement-fees?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_FEES_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_FEES_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadExpressCashFeesReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/settlement-fees?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_EXPRESSCASH_summ_settlements-fees_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

export const fetchExpressCashSummReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_FEES_SUMM_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/summ-settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_FEES_SUMM_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_FEES_SUMM_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadExpressCashSummReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/summ-settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_EXPRESSCASH_summ_settlements_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchExpressCashAquirerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_AQUIRER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/acquirer?settlementDate=${date}&acquirer-code=E${acquirer}&issuer-code=E${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_AQUIRER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_AQUIRER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadExpressCashAquirerReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/acquirer?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_EXPRESSCASH_audit_acquirers_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchExpressCashIssuerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_ISSUER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/issuer?settlementDate=${date}&acquirer-code=E${acquirer}&issuer-code=E${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_ISSUER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_ISSUER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadExpressCashIssuerReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/xpress-cash/reports/issuer?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_EXPRESSCASH_audit_issuers_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchChinaPayAcquirerFileSummary =
  (page, startDate, endDate) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/cup/acquirer?startDate=${startDate}&endDate=${endDate}&page=${page}`,
      });
      dispatch({
        type: GET_CHINAPAY_ACQUIRER_FILE_SUMMARY,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHINAPAY_ACQUIRER_FILE_SUMMARY_ERROR,
        payload: error.response.data,
      });
    }
  };

export const fetchChinaPayAcquirerFileDetails =
  (id, page) => async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASE_URL}/cup/acquirer/${id}?page=${page}`,
      });
      dispatch({
        type: GET_CHINAPAY_ACQUIRER_FILE_DETAILS,
        payload: response.data,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: GET_CHINAPAY_ACQUIRER_FILE_DETAILS_ERROR,
        payload: error.response.data,
      });
    }
  };

export const fetchChinaPayFileSummary =
  (page, startDate, endDate) => async (dispatch) => {
    dispatch({ type: CHINAPAY_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/cup/summary?startDate=${startDate}&endDate=${endDate}&page=${page}`
      );
      dispatch({
        type: GET_CHINAPAY_SUMMARY,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_CHINAPAY_SUMMARY_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const fetchChinaPayFileSummaryFileDetail =
  (id, type, page) => async (dispatch) => {
    dispatch({ type: CHINAPAY_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/cup/summary/${id}?type=${type}&page=${page}`
      );
      dispatch({
        type: GET_CHINAPAY_SUMMARY_DETAILS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_CHINAPAY_SUMMARY_DETAILS_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const fetchChinaPayPostingSummary =
  (page, startDate, endDate) => async (dispatch) => {
    dispatch({ type: CHINAPAY_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/cup/postings/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}`
      );
      dispatch({
        type: GET_CHINAPAY_POSTING_SUMMARY,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_CHINAPAY_POSTING_SUMMARY_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const fetchChinaPayPostingDetails =
  (startDate, endDate) => async (dispatch) => {
    dispatch({ type: CHINAPAY_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/admin/affiliate-posting-logs?scheme=CUP&startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({
        type: GET_CHINAPAY_POSTING_DETAILS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_CHINAPAY_POSTING_DETAILS_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };

export const fetchPostingDifferenceReport = () => (dispatch) => {
  dispatch({ type: POSTING_DIFFERENCE_REPORT_LOADING });
  axios
    .get(`${BASE_URL}/admin/filter-posting-difference-records`)
    .then((resp) => {
      dispatch({
        type: POSTING_DIFFERENCE_REPORT,
        payload: resp.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: POSTING_DIFFERENCE_REPORT_ERROR,
        payload: err.respose ? err.response.data.message : "an error occured",
      });
    });
};

export const fetchChinaPayPostingEntries = (id, page) => async (dispatch) => {
  dispatch({ type: CHINAPAY_LOADING });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`
    );
    dispatch({
      type: GET_CHINAPAY_POSTING_ENTRIES,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_CHINAPAY_POSTING_ENTRIES_ERROR,
      payload: e.response ? e.response.data.message : "an error occured",
    });
  }
};
export const downloadChinaPayPostingEntries = (id) => () => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}`,
    responseType: "blob",
    headers: {
      Accept: "application/octet-stream",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `ChinaUnionPay_Posting_entries_${id}.csv`);
    document.body.appendChild(link);
    link.click();
    return;
  });
};
// export const downloadChinaPayFileSummary = (id, type) => () => {
//   axios({
//     method: "GET",
//     url: `${BASE_URL}/cup/summary/${id}?type=${type}`,
//     responseType: "blob",
//     headers: {
//       Accept: "application/octet-stream",
//     },
//   }).then((response) => {
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", `ChinaUnionPay_FileSummary_${id}.csv`);
//     document.body.appendChild(link);
//     link.click();
//     return;
//   });
// };
export const fetchAllMasterpassSummaries =
  (page, startDate, endDate) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/filtered?startDate=${startDate}&endDate=${endDate}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_SUMMARIES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_SUMMARIES_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const fetchAllMasterpassSummary = (date, code) => async (dispatch) => {
  dispatch({ type: MASTERPASS_LOADING });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/summary/${date}?region-code=${code}`
    );
    dispatch({
      type: GET_MASTERPASS_SUMMARY,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MASTERPASS_SUMMARY_ERROR,
      payload: e.response ? e.response.data.message : "an error occured",
    });
  }
};

export const fetchAllMasterpassDetails = (id, page) => async (dispatch) => {
  dispatch({ type: MASTERPASS_LOADING });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/details/${id}?page=${page}`
    );
    dispatch({
      type: GET_MASTERPASS_DETAILS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MASTERPASS_DETAILS_ERROR,
      payload: e.response ? e.response.data.message : "an error occured",
    });
  }
};

export const fetchMasterpassAquirer = (id, code, page) => async (dispatch) => {
  dispatch({ type: MASTERPASS_LOADING });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/acquirers/${id}?acquirer-code=E${code}&page=${page}`
    );
    dispatch({
      type: GET_MASTERPASS_ACQUIRERS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MASTERPASS_ACQUIRERS_ERROR,
      payload: e.response ? e.response.data.message : "an error occured",
    });
  }
};
export const fetchMasterpassIssuer = (id, code, page) => async (dispatch) => {
  dispatch({ type: MASTERPASS_LOADING });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/issuers/${id}?issuer-code=E${code}&page=${page}`
    );
    dispatch({
      type: GET_MASTERPASS_ISSUERS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MASTERPASS_ISSUERS_ERROR,
      payload: e.response ? e.response.data.message : "an error occured",
    });
  }
};
export const fetchAllMasterpassQueryDetails = (id, page) => (dispatch) => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`,
  })
    .then((response) => {
      dispatch({
        type: GET_MASTERPASS_QUERY_DETAILS,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MASTERPASS_QUERY_DETAILS_ERROR,
        payload: err.response ? err.response.data.message : "an error occured",
      });
    });
};
export const downloadMasterpassQueryDetail = (id) => () => {
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}`,
    responseType: "blob",
    headers: {
      Accept: "application/octet-stream",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Masterpass_Query_details_${id}.csv`);
    document.body.appendChild(link);
    link.click();
    return;
  });
};
export const fetchMasterpassSettlementReport =
  (date, code, page) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_SETTLEMENT_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_SETTLEMENT_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const downloadMasterPassSettlementReport =
  (date, region) => async () => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_MASTERPASS_DJRFCYNSXP_" +
      region;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const downloadMasterpassPostingReport =
  (date, code, page, affiliate) => async () => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_MASTERPASS_DJRFCY_E" +
      affiliate +
      "_XP_" +
      code;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
            affiliateCode: `E${affiliate}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchMasterpassPostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        { headers: { affiliateCode: `E${affiliate}` } }
      );
      dispatch({
        type: GET_MASTERPASS_POSTING_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_POSTING_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };
export const fetchMasterpassFeesReport =
  (date, code, page) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/settlement-fees?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_SETTLEMENT_FEES_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_SETTLEMENT_FEES_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };

export const downloadMasterpassFeesReport = (date, region) => async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/reports/settlement-fees?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MASTERPASS_summ_settlements-fees_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};

export const fetchMasterpassSummReport =
  (date, code, page) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/summ-settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_SUMM_SETTLEMENT_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_SUMM_SETTLEMENT_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };

export const downloadMasterpassSummReport = (date, region) => async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/reports/summ-settlement?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MASTERPASS_summ_settlements_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};
export const fetchMasterpassAquirerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/acquirer?settlementDate=${date}&acquirer-code=E${acquirer}&issuer-code=E${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_ACQUIRER_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_ACQUIRER_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };

export const downloadMasterpassAquirerReport = (date, region) => async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/reports/acquirer?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MASTERPASS_audit_acquirers_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};
export const fetchMasterpassIssuerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({ type: MASTERPASS_LOADING });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/master-pass/reports/issuer?settlementDate=${date}&acquirer-code=E${acquirer}&issuer-code=E${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_MASTERPASS_ISSUER_REPORT,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERPASS_ISSUER_REPORT_ERROR,
        payload: e.response ? e.response.data.message : "an error occured",
      });
    }
  };

export const downloadMasterpassIssuerReport = (date, region) => async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/master-pass/reports/issuer?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MASTERPASS_audit_issuers_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};
// MASTERPASS END

//MVISA START
export const fetchAllMVISASummaries =
  (page, startDate, endDate, code) => async (dispatch) => {
    dispatch({
      type: GET_ALL_MVISA_SUMMARY_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/summary?startDate=${startDate}&endDate=${endDate}&page=${page}`
      );
      dispatch({
        type: GET_ALL_MVISA_SUMMARY_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_MVISA_SUMMARY_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const fetchAllMVISAQueryDetails = (id, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MVISA_QUERY_DETAILS_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`
    );
    dispatch({
      type: GET_ALL_MVISA_QUERY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_MVISA_QUERY_DETAILS_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};
export const fetchAllMVISADetails = (id, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MVISA_DETAILS_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/details/${id}?page=${page}`
    );
    dispatch({
      type: GET_ALL_MVISA_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_MVISA_DETAILS_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchMVISAAquirer = (id, code, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MVISA_ACQUIRER_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/acquirers/${id}?acquirer-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_ALL_MVISA_ACQUIRER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_MVISA_ACQUIRER_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchMVISAIssuer = (id, code, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MVISA_ISSUER_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/issuers/${id}?issuer-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_ALL_MVISA_ISSUER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_MVISA_ISSUER_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchAllMVISASummary = (date, code) => async (dispatch) => {
  dispatch({
    type: GET_ALL_MVISA_SINGLE_SUMMARY_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/summary/${date}?region-code=${code}`
    );
    dispatch({
      type: GET_ALL_MVISA_SINGLE_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_MVISA_SINGLE_SUMMARY_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchMVISASummReport = (date, code, page) => async (dispatch) => {
  dispatch({
    type: GET_MVISA_SUM_REPORT_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/reports/summ-settlement?settlementDate=${date}&region-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_MVISA_SUM_REPORT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MVISA_SUM_REPORT_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};
export const downloadMVISASummReport = (date, region) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${BASE_URL}/mvisa/reports/summ-settlement?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MVISA_summ_settlements_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};

export const fetchMVISASettlementReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_XPRESSCASH_MVISA_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_XPRESSCASH_MVISA_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_XPRESSCASH_MVISA_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadMVISASettlementReport =
  (date, region) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_MVISA_DJRFCYNSXP_" +
      region;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

export const fetchMVISAPostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    dispatch({
      type: GET_MVISA_POSTING_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        { headers: { affiliateCode: `E${affiliate}` } }
      );
      dispatch({
        type: GET_MVISA_POSTING_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MVISA_POSTING_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadMVISAPostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_MVISA_DJRFCY_E" +
      affiliate +
      "_XP_" +
      code;
    try {
      const data = await axios.get(
        `${BASE_URL}/mvisa/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
            affiliateCode: `E${affiliate}`,
            "Access-Control-Request-Headers": "Content-Disposition",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

export const fetchMVISAFeesReport = (date, code, page) => async (dispatch) => {
  dispatch({
    type: GET_MVISA_FEES_REPORT_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/reports/settlement-fees?settlementDate=${date}&region-code=${code}&page=${page}`
    );
    dispatch({
      type: GET_MVISA_FEES_REPORT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_MVISA_FEES_REPORT_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const downloadMVISAFeesReport = (date, region) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/reports/settlement-fees?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      date + `_MVISA_summ_settlements-fees_${region}.txt`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};

export const fetchMVISAAquirerReport =
  (date, acquirerCode, issuerCode, regionCode, page) => async (dispatch) => {
    dispatch({
      type: GET_MVISA_ACQUIRER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/acquirer?settlementDate=${date}&acquirer-code=E${acquirerCode}&issuer-code=E${issuerCode}&region-code=${regionCode}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      dispatch({
        type: GET_MVISA_ACQUIRER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MVISA_ACQUIRER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadMVISAAquirerReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/acquirer?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_MVISA_audit_acquirers_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchMVISAIssuerReport =
  (date, acquirerCode, issuerCode, regionCode, page) => async (dispatch) => {
    dispatch({
      type: GET_MVISA_ISSUER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mvisa/reports/issuer?settlementDate=${date}&acquirer-code=E${acquirerCode}&issuer-code=E${issuerCode}&region-code=${regionCode}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      dispatch({
        type: GET_MVISA_ISSUER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MVISA_ISSUER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadMVISAIssuerReport = (date, region) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/mvisa/reports/issuer?settlementDate=${date}&region-code=${region}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", date + `_MVISA_audit_issuers_${region}.txt`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.log(e);
  }
};
//MVISA END
// Ecommerce Start

// Generic Query Details Download
export const downloadQueryDetail = (id, name, date, affCode) => () => {
  const NAMES = {
    ExpressCashQueryDetails: XPRESSCASH_FILENAME,
    MVISAQueryDetails: MVISA_FILENAME,
    EcommerceQueryDetails: ECOMMERCE_FILENAME,
    MasterpassQueryDetails: MASTERPASS_FILENAME,
    InterAffiliateQueryDetails: INTER_AFFILIATE_FILENAME,
    CupQueryDetails: CUP_FILENAME,
    VISAQueryDetails: VISA_FILENAME,
  };
  axios({
    method: "GET",
    url: `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}`,
    responseType: "blob",
    headers: {
      Accept: "application/octet-stream",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${NAMES[name]}${affCode}${date}.csv`);
    document.body.appendChild(link);
    link.click();
    return;
  });
};
export const deletePostingLogAndRecords =
  (postingEntry, scheme) => async (dispatch) => {
    try {
      await axios.delete(
        `${BASE_URL}/admin/posting-logs/delete?posting-log-id=${encryptFilename(
          postingEntry.value
        )}&affiliateCode=${postingEntry.key}&schemeType=${scheme}`
      );
      dispatch({
        type: DELETE_POSTING_LOG_ID_AND_RECORDS,
        payload: postingEntry,
      });
    } catch (e) {
      dispatch({
        type: DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const clearPostingLogs = () => (dispatch) => {
  dispatch({
    type: DELETE_POSTING_LOG_ID_AND_RECORDS,
    payload: null,
  });
  dispatch({
    type: DELETE_POSTING_LOG_ID_AND_RECORDS_ERROR,
    payload: "",
  });
};
export const deleteChanneLog = (fileIdentifier, tag) => async (dispatch) => {
  let url;
  if (tag === "advisment") {
    url = `${BASE_URL}/admin/advisement/delete?settlementDate=${fileIdentifier}`;
  } else if (tag === "gimSummary") {
    url = `${BASE_URL}/admin/gim-summary/delete?settlementDate=${fileIdentifier}`;
  } else {
    url = `${BASE_URL}/admin/logs/delete?filename=${encryptFilename(
      fileIdentifier
    )}`;
  }
  try {
    await axios.delete(url);
    dispatch({
      type: DELETE_CHANNEL_LOG,
      payload: fileIdentifier,
    });
  } catch (e) {
    dispatch({
      type: DELETE_CHANNEL_LOG_ERROR,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const clearChannelLogs = () => (dispatch) => {
  dispatch({
    type: DELETE_CHANNEL_LOG,
    payload: null,
  });
  dispatch({
    type: DELETE_CHANNEL_LOG_ERROR,
    payload: "",
  });
};

export const fetchAllEcommerceSchemes =
  (startDate, endDate, page) => async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/e-commerce/summary?startDate=${startDate}&endDate=${endDate}&page=${page}`,
      });
      dispatch({
        type: FETCH_ALL_ECOMMERCE_SCHEME,
        payload,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_ECOMMERCE_SCHEME_ERROR,
        payload: error.response,
      });
    }
  };

export const fetchEcommerceDetails = (id, page) => async (dispatch) => {
  try {
    const { data: payload } = await axios({
      method: "GET",
      url: `${BASE_URL}/e-commerce/details/${id}?page=${page}`,
    });
    dispatch({
      type: ECOMMERCE_DETAILS,
      payload,
    });
  } catch ({ response: payload }) {
    dispatch({
      type: ECOMMERCE_DETAILS_ERROR,
      payload,
    });
  }
};

export const fetchEcommerceIssuerDetails =
  (id, issuerCode, page) => async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/e-commerce/issuers/${id}?issuer-code=${issuerCode}&page=${page}`,
      });
      dispatch({
        type: ECOMMERCE_ISSUER_DETAILS,
        payload,
      });
    } catch ({ response: payload }) {
      dispatch({
        type: ECOMMERCE_ISSUER_DETAILS_ERROR,
        payload,
      });
    }
  };

export const fetchEcommerceAcquirerDetails =
  (id, acquirerCode, page) => async (dispatch) => {
    try {
      const { data: payload } = await axios({
        method: "GET",
        url: `${BASE_URL}/e-commerce/acquirers/${id}?acquirer-code=${acquirerCode}&page=${page}`,
      });
      dispatch({
        type: ECOMMERCE_ACQUIRER_DETAILS,
        payload,
      });
    } catch ({ response: payload }) {
      dispatch({
        type: ECOMMERCE_ACQUIRER_DETAILS_ERROR,
        payload,
      });
    }
  };

export const fetchEcommerceSettlementReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_SETTLEMENT_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_ECOMMERCE_SETTLEMENT_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_SETTLEMENT_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const downloadEcommerceSettlementReport =
  (date, region) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_ECOMMERCE_DJRFCYNSXP_" +
      region;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchEcommercePostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_POSTING_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        { headers: { affiliateCode: affiliate } }
      );
      dispatch({
        type: GET_ECOMMERCE_POSTING_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_POSTING_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const downloadEcommercePostingReport =
  (date, code, page, affiliate) => async (dispatch) => {
    let filename =
      moment(date).format("DD-MM-YYYY").replace(/-|_/g, "") +
      "_ECOMMERCE_DJRFCY_E" +
      affiliate +
      "_XP_" +
      code;
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/posting?settlementDate=${date}&region-code=${code}&page=${page}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
            affiliateCode: `E${affiliate}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

export const fetchEcommerceFeesReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_FEES_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/settlement-fees?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_ECOMMERCE_FEES_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_FEES_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const downloadEcommerceFeesReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/settlement-fees?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_ECOMMERCE_summ_settlements-fees_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchEcommerceSummReport =
  (date, code, page) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_SUMM_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/summ-settlement?settlementDate=${date}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_ECOMMERCE_SUMM_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_SUMM_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const downloadEcommerceSummReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/summ-settlement?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_ECOMMERCE_summ_settlements_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };

export const fetchEcommerceAquirerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_AQUIRER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/acquirer?settlementDate=${date}&acquirer-code=${acquirer}&issuer-code=${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_ECOMMERCE_AQUIRER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_AQUIRER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const downloadEcommerceAquirerReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/acquirer?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_ECOMMERCE_audit_acquirers_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchEcommerceIssuerReport =
  (date, acquirer, issuer, code, page) => async (dispatch) => {
    dispatch({
      type: GET_ECOMMERCE_ISSUER_REPORT_START,
    });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/issuer?settlementDate=${date}&acquirer-code=${acquirer}&issuer-code=${issuer}&region-code=${code}&page=${page}`
      );
      dispatch({
        type: GET_ECOMMERCE_ISSUER_REPORT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ECOMMERCE_ISSUER_REPORT_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };
export const downloadEcommerceIssuerReport =
  (date, region) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/e-commerce/reports/issuer?settlementDate=${date}&region-code=${region}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        date + `_ECOMMERCE_audit_issuers_${region}.txt`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const fetchAllEcommerceSummary = (date, code) => async (dispatch) => {
  dispatch({
    type: GET_ALL_ECOMMERCE_SINGLE_SUMMARY_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/e-commerce/summary/${date}?region-code=${code}`
    );
    dispatch({
      type: GET_ALL_ECOMMERCE_SINGLE_SUMMARY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_ECOMMERCE_SINGLE_SUMMARY_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};
export const fetchAllEcommerceQueryDetails = (id, page) => async (dispatch) => {
  dispatch({
    type: GET_ALL_ECOMMERCE_QUERY_DETAILS_START,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/admin/query-posting-records?posting-log-id=${id}&page=${page}`
    );
    dispatch({
      type: GET_ALL_ECOMMERCE_QUERY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_ECOMMERCE_QUERY_DETAILS_FAILURE,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};

export const fetchNostroReports = (scheme, date, page) => async (dispatch) => {
  dispatch({
    type: GET_NOSTRO_REPORTS_LOADING,
  });
  try {
    const { data } = await axios.get(
      `${BASE_URL}/admin/query-nostro-records?settlement-date=${date}&scheme=${scheme}&page=${page}`
    );
    dispatch({
      type: GET_NOSTRO_REPORTS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_NOSTRO_REPORTS_ERROR,
      payload: e.response ? e.response.data.message : "An error occured",
    });
  }
};
export const downloadNostroReports =
  (date, scheme, settlementDate, AffCode) => async () => {
    // const SCHEMES = {
    //   MASTERCARD: MASTERCARD_FILENAME,
    //   VISA: VISA_FILENAME,
    // };
    try {
      const { data } = await axios.get(
        `${BASE_URL}/admin/query-nostro-records?csv&settlement-date=${date}&scheme=${scheme}`,
        {
          headers: {
            "Content-Type": "text/csv",
            Accept: "text/csv",
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${NSA_FILENAME}${AffCode}${settlementDate}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  };
export const triggerPosting =
  (regioncode, settlementDate, schemeType) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/reset-processing-count?region-code=${regioncode}&settlement-date=${settlementDate}&schemeType=${schemeType}`
      );
      dispatch({
        type: TRIGGER_POSTING,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: TRIGGER_POSTING_ERROR,
        payload: "An error occured",
      });
    }
  };
export const resetTriggerPostingResponse = () => async (dispatch) => {
  dispatch({ type: RESET_TRIGGER_RESPONSE });
};
export const fetchMastercardResolvedFiles =
  (settlementDate) => async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/mastercard/resolved?settlementDate=${settlementDate}`
      );
      dispatch({
        type: GET_MASTERCARD_RESOLVED_FILES,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_MASTERCARD_RESOLVED_FILES_FAILURE,
        payload: e.response ? e.response.data.message : "An error occured",
      });
    }
  };

export const clearMastercardResolvedFilesMessage = () => (dispatch) => {
  dispatch({ type: CLEAR_RESOLVED_FILES_MESSAGE });
};
