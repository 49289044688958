import moment from "moment";
// import { store } from "react-notifications-component";
let CryptoJS = require("crypto-js");
export const todaysDateFunc = () => {
  let date1 = moment().format();
  if (date1.includes("T")) {
    let extract = date1.split("T");
    date1 = extract[0];
    return date1;
  }
};

export const setTableHeader = (headerPayload, tableName) => {
  // func to dynamically generate formated table header payload
  const regx = /_/gi;
  if (headerPayload.length > 0) {
    let tempHeader = headerPayload[0];
    tempHeader = Object.keys(tempHeader);
    tempHeader = tempHeader.map((header) => {
      return header.replace(regx, " "); // make a new formated array of headers replacing all underscores with space.
    });

    return tempHeader.filter((header) => {
      if (tableName === "advisementSummary") {
        return (
          header !== "id" && header !== "constant" && header !== "constant2"
        );
      }
      return (
        header !== "postilionSummary" &&
        header !== "id" &&
        header !== "flexCubeSummary" &&
        header !== "posAcquirerSummary"
      );
    }); // removes id and postilionSummary from header array if they are there
  }
};

export const setTableData = (tablePayload) => {
  // func to dynamically generate formated table header payload
  let tempTableData = tablePayload[0];
  tempTableData = Object.keys(tempTableData);
  return tempTableData.filter(
    (data, index) => data !== "id" && data !== "lcyEntry" && data !== "fcyEntry"
  );
};

export const deleteObjectItemByValue = (postingEntries, val) => {
  for (var key in postingEntries) {
    if (postingEntries[key] === val) {
      delete postingEntries[key];
      return postingEntries;
    }
  }
};

export const makeTagList = (arr) => {
  let tags = [];
  arr.map((item) => {
    tags = tags.concat([item.value]);
  });
  return tags;
};

export const itExists = (item, ...items) => {
  let allItems = [item, ...items];
  if (allItems.length === 0) return false;
  const result = allItems.every(
    (item) =>
      String(item) !== "undefined" &&
      String(item) !== "NaN" &&
      String(item) !== "null" &&
      item !== ""
  );
  return result;
};

export const makeReactSelectOptions = (arrayOfItems) => {
  let options = [];
  let option = {};
  if (!arrayOfItems) return options;
  if (arrayOfItems && arrayOfItems.length > 0) {
    arrayOfItems.map((item) => {
      option.label = item;
      option.value = item;
      options.push(option);
      return options;
    });
  }
  return options;
};

export const getRegionAffiliates = (region, regionCodes) => {
  let selectedRegionAffiliateCodes =
    regionCodes && regionCodes.find((item) => item.code === region);
  return selectedRegionAffiliateCodes;
};

export const generateAffiliatesByRegion = (
  allAffiliates,
  regionAffiliatesCodes
) => {
  let regionAffiliates = [];
  allAffiliates &&
    allAffiliates.map((affiliate) => {
      if (
        regionAffiliatesCodes &&
        regionAffiliatesCodes.affiliateCodes.indexOf(affiliate.value) > -1
      ) {
        regionAffiliates = regionAffiliates.concat(affiliate);
        return regionAffiliates;
      }
    });
  return regionAffiliates;
};
export const getLocalStorageItems = async (key) => {
  const result =
    (await localStorage.getItem(key)) !== undefined &&
    localStorage.getItem(key);
  return result;
};

export const encryptFilename = (filename) => {
  let hash = CryptoJS.AES.encrypt(
    filename,
    localStorage.getItem("token")
  ).toString();
  // console.log(hash, "not encoded");
  // console.log(encodeURIComponent(hash), "encoded");
  return encodeURIComponent(hash);
};
export const Notifications = ({ title, message, type }) => {
  // store.addNotification({
  //   title: title,
  //   message: message,
  //   type: type,
  //   insert: "top",
  //   container: "top-right",
  //   animationIn: ["animate__animated", "animate__fadeIn"],
  //   animationOut: ["animate__animated", "animate__fadeOut"],
  //   dismiss: {
  //     duration: 5000,
  //     onScreen: false,
  //     pauseOnHover: true,
  //   },
  // });
};

export const customUId = () => {
  return Math.random() * 10000000;
};

export const processor_enums = [
  { value: "", label: "All" },
  { value: "VISA", label: "VISA" },
  { value: "MASTERCARD", label: "MASTERCARD" },
  { value: "INTER_AFFILIATE", label: "INTER AFFILIATE" },
  { value: "INTER_AFFILIATE_USD", label: "INTER AFFILIATE USD" },
  { value: "INTER_AFFILIATE_XOF", label: "INTER AFFILIATE XOF" },
  { value: "INTER_AFFILIATE_XAF", label: "INTER AFFILIATE XAF" },
  { value: "INTER_AFFILIATE_ACCION", label: "INTER AFFILIATE ACCION" },
  { value: "INTERSWITCH", label: "INTERSWITCH" },
  { value: "UNKNOWN", label: "UNKNOWN" },
  { value: "XPRESS_CASH", label: "XPRESS CASH" },
  { value: "XPRESS_CASH_XOF", label: "XPRESS CASH XOF" },
  { value: "XPRESS_CASH_XAF", label: "XPRESS CASH XAF" },
  { value: "MASTERPASS", label: "MASTERPASS" },
  { value: "MASTERPASS_USD", label: "MASTERPASS USD" },
  { value: "MASTERPASS_XOF", label: "MASTERPASS XOF" },
  { value: "MASTERPASS_XAF", label: "MASTERPASS XAF" },
  { value: "CUP", label: "CUP" },
  { value: "MVISA", label: "MVISA" },
  { value: "MVISA_USD", label: "MVISA USD" },
  { value: "MVISA_XOF", label: "MVISA XOF" },
  { value: "MVISA_XAF", label: "MVISA XAF" },
  { value: "ECOMMERCE", label: "ECOMMERCE" },
  { value: "ECOMMERCE_USD", label: "ECOMMERCE USD" },
  { value: "ECOMMERCE_XOF", label: "ECOMMERCE XOF" },
  { value: "ECOMMERCE_XAF", label: "ECOMMERCE XAF" },
  { value: "GIM", label: "GIM" },
];

export const product_enums = [
  { value: "", label: "All" },
  { value: "ATM", label: "ATM" },
  { value: "PURCHASE", label: "PURCHASE" },
  { value: "POS", label: "POS" },
  { value: "MERCHANDISE", label: "MERCHANDISE" },
  { value: "OCT", label: "OCT" },
  { value: "MIGS", label: "MIGS" },
  { value: "ATM_POS", label: "ATM POS" },
  { value: "ORIGINAL", label: "ORIGINAL" },
  { value: "P2P", label: "P2P" },
  { value: "QR", label: "QR" },
  { value: "ART", label: "ART" },
  { value: "RECIPIENT", label: "RECIPIENT" },
  { value: "OTHER", label: "OTHER" },
  { value: "CHARGEBACK", label: "CHARGEBACK" },
];