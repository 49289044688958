import PropTypes from "prop-types";
import React from "react";
import Widget04 from "../../../Utils/Widgets04";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const InfoCards = ({
  url,
  icon,
  header,
  value,
  invert,
  color,
  text,
  className,
}) => {
  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <Widget04
        icon={icon}
        header={header}
        value={value}
        invert={invert}
        color={color}
        className={className}
      >
        <FormattedMessage id={text} defaultMessage={text} />
      </Widget04>
    </Link>
  );
};

InfoCards.propTypes = {
  color: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.string,
  invert: PropTypes.bool,
  url: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};
InfoCards.defaultProps = {
  color: "primary",
  header: "Settlement",
  icon: "icon-calculator",
  invert: true,
  value: "25",
  text: "Express Cash",
  url: "/",
  className: "",
};

export default InfoCards;
