import "./index.css";
import "./polyfill";

// Reat
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { addLocaleData } from "react-intl";
import locale_de from "react-intl/locale-data/de";
import locale_en from "react-intl/locale-data/en";
import locale_fr from "react-intl/locale-data/fr";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// components
import App from "./App";

addLocaleData([ ...locale_en, ...locale_de, ...locale_fr ]);

ReactDOM.render(
	<Provider store={store}>
		<HashRouter>
			<App />
		</HashRouter>
	</Provider>,
	document.getElementById("root"),
);
// disable ServiceWorker
// registerServiceWorker();
