import PropTypes from "prop-types";
import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { MdClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
const PostingDetails = ({ data, error, loading, url }) => {
  if (error || loading) return null;
  if (!data) return null;
  return (
    <Card className={"h-100"}>
      <CardHeader>
        <strong>
          <FormattedMessage
            id="Posting Details"
            defaultMessage="Posting Details"
          />
        </strong>
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Settlement Date</strong>
          </span>
          <span>{data.settlementDate}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Region Code</strong>
          </span>
          <span>{data.regionCode}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Process Date</strong>
          </span>
          <span>{data.processDate}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Last Modified</strong>
          </span>
          <span>{data.lastModified}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Posting Completed</strong>
          </span>
          <span>{`${
            data.postingCompleted === undefined ? "N/A" : data.postingCompleted
          }`}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Posting Completion Date</strong>
          </span>
          <span>
            {data.postingCompletionDate ? data.postingCompletionDate : "N/A"}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Loading Completion Date</strong>
          </span>
          <span>
            {data.loadingCompletionDate ? data.loadingCompletionDate : "N/A"}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Settlement Completion Date</strong>
          </span>
          <span>
            {data.settlementCompletionDate
              ? data.settlementCompletionDate
              : "N/A"}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Processing Count</strong>
          </span>
          <span>{data.count ? data.count : "N/A"}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center p-2">
          <span>
            <strong>Scheme Type</strong>
          </span>
          <span>{data.schemeType ? data.schemeType : "N/A"}</span>
        </div>
        {url === "/cup" && (
          <>
            <div className="d-flex justify-content-between align-items-center p-2">
              <span>
                <strong>Batch No</strong>
              </span>
              <span>{data.batchNo}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center p-2">
              <span>
                <strong>Submitted</strong>
              </span>
              <span>
                {!data.submitted && <MdClose color="red" />}
                {data.submitted && <FaCheck color="green" />}
              </span>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

PostingDetails.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func,
  }),
};

PostingDetails.defaultProps = {
  data: [],
};

export default PostingDetails;
